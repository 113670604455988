import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import { convertToSvgString, extractPolygonGeometry } from '../../web_api/methods/helpers';
import { polygonObjectInList, polygonObjectList } from '../../web_api/models';

type CommonAnnotatedImageProps = {
    data: polygonObjectList,
    img: string,
    className?: string,
    saveChanges: (polygons: polygonObjectList) => void;
    save: boolean
}

const CommonAnnotatedImage: React.FC<CommonAnnotatedImageProps> = ({
    data,
    img,
    className,
    saveChanges,
    save
}) => {

    const [anno, setAnno] = useState<any>();

    const [image, setImage] = useState({
        height: 0,
        width: 0
    })

    const [polygons, setPolygons] = useState<polygonObjectList>([]);

    const imgEl = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setPolygons(data);
    }, []);

    useEffect(() => {

        let annotorious: any = null;

        if (imgEl.current) {
            annotorious = new Annotorious({
                image: imgEl.current,
                drawOnSingleClick: true,
            });

            annotorious.setDrawingTool("polygon");

            annotorious.on('createAnnotation', (annotation: any) => {
                const polygonStringRaw = annotation.target.selector.value
                const polygonGeometry = extractPolygonGeometry(polygonStringRaw);
                let polygonObj: polygonObjectInList = { 
                    id: annotation.id,
                    body: annotation.body,
                    polygon: polygonGeometry
                };

                setPolygons(prevPolygons => [...prevPolygons, polygonObj]);
            });

            annotorious.on('updateAnnotation', (annotation: any, previous: any) => {
                setPolygons((prevPolygons) =>
                    prevPolygons.map((polygon) =>
                        polygon.id === previous.id
                            ? {
                                id: previous.id,
                                body: annotation.body,
                                polygon: extractPolygonGeometry(annotation.target.selector.value),
                            }
                            : polygon
                    )
                );
            });


            annotorious.on('deleteAnnotation', (annotation: any) => {
                setPolygons((prevPolygons) =>
                    prevPolygons.filter((polygon) => polygon.id !== annotation.id)
                );
            });
        };

        setAnno(annotorious);

        return () => {
            
            if (annotorious) {
                annotorious.destroy();
            }
        };
    }, []);


    useEffect(() => {

        if (anno && polygons.length > 0) {
            anno.clearAnnotations();
            polygons.forEach((polygon) => {
                const annotation = {
                    context: "http://www.w3.org/ns/anno.jsonld",
                    id: polygon.id,
                    target: {
                        selector: { type: "SvgSelector", value: convertToSvgString(polygon.polygon) }
                    },
                    body: polygon.body,
                    type: "Annotation"
                };
                
                anno.addAnnotation(annotation);
            });
        }
        
    }, [anno, polygons.length]);
    

    useEffect(() => {
        const resize = () => {
            setImage({
                height: imgEl.current?.clientWidth ?? 0,
                width: imgEl.current?.clientHeight ?? 0
            })
        }
        resize();
        window.addEventListener('resize', resize);
    }, [imgEl.current?.clientHeight, imgEl.current?.clientWidth])

    useEffect(() => {
        saveChanges(polygons);
    }, [save]);

    return (
        <img
            className={`${className}`}
            ref={imgEl}
            src={img}
        />
    );
}

export default CommonAnnotatedImage;
