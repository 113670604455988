import React, { ChangeEventHandler } from "react";

type CommonFormFieldProps = {
    containerClass?: string;
    labelClass?: string;
    inputClass?: string;
    feedbackClass?: string;
    id: string;
    label: string;
    type: string;
    placeholder: string;
    value: string | number;
    onChange: ChangeEventHandler<HTMLInputElement>;
    invalidFeedback?: string;
};

const CommonFormField: React.FC<CommonFormFieldProps> = ({
    containerClass,
    labelClass,
    inputClass,
    feedbackClass,
    id,
    label,
    placeholder,
    type,
    value,
    onChange,
    invalidFeedback
}) => {

    const isInvalid = inputClass?.includes("is-invalid");
    const feedbackVisibilityClass = isInvalid ? "" : "invisible";
    
    return (
        <div className={`fv-row d-flex flex-wrap ${containerClass}`}>
            <label className={`form-label fw-bolder text-dark fs-6 mb-2 ${labelClass}`} htmlFor={id}>
                {label}
            </label>
            <input
                className={`form-control bg-transparent border ${inputClass}`}
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            <div className={`text-danger text-start ms-3 mt-1 fs-9 ${feedbackClass} ${feedbackVisibilityClass}`}>
                {invalidFeedback}
            </div>
        </div>
    );
}

export default CommonFormField;