import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IsValidState {
  is_valid: string
}
const initialState: IsValidState = {
  is_valid: localStorage.getItem('is_valid')!?.toString()!=null?localStorage.getItem('is-valid')!?.toString():'false',
  
};

export const is_valid = createSlice({
  name: 'is_valid',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<IsValidState>) => {
      state.is_valid = action.payload.is_valid
      localStorage.setItem("is_valid", action.payload.is_valid)

    },
  },
});

export const { setValue } = is_valid.actions;

export const isValidReducer = is_valid.reducer;
