import './App.css';
import Login from './pages/Login';
import Register from './pages/Account/Register/Register';
import PrivateRoutes from './routing/PrivateRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';

function App() {
  const token = useSelector((state: RootState) => state.user.access_token);

  return (
    <Routes>
      {token !== null ?
        (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/home'} />} />
          </>
        )
        :
        (
          <>
            <Route path='/*' index element={<Login />}></Route>
            <Route path={process.env.REACT_APP_HOMEPAGE + '/account/register'} element={<Register />} />
          </>
        )
      }

    </Routes>


  );
}

export default App;
