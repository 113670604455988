import { useEffect, useRef, useState } from 'react'
import logo from '../assets/images/logo_c2i.svg'
import camera from '../assets/images/png-clipart-gray-and-black-cctv-camera-illustration-surveillance-video-camera-icon-realistic-silver-webcam-angle-electronics.png'
import { Link } from 'react-router-dom'
import * as ReactBootstrap from 'react-bootstrap';
import TwoFactorAuthLoginModal from './2FA/TwoFactorAuthLoginModal'
import useModals from '../hooks/Common/useModals'
import useLogin from '../hooks/Account/useLogin'
import { ToastContainer } from 'react-toastify';

export default function Login() {

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    const { toggleModal, show } = useModals([false]);
    const {
        handleLoginSubmit,
        storeUserCredentials,
        hasError,
        isLoggingIn,
        twoFactorAuth
    } = useLogin(credentials.email, credentials.password);

    useEffect(() => {
        if (twoFactorAuth) {
            toggleModal(0);
        };
    }, [twoFactorAuth])

    return (
        <>
            <ToastContainer />
            <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
                <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
                    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                        <div className='w-lg-500px p-10'>
                            <div className='text-center mb-15'>
                                <h1 className='text-dark fw-bolder'>
                                    C2I
                                </h1>
                                <h2 className='text-gray-500 fw-semibold fs-6'>
                                    Efficiency
                                    through
                                    artificial intelligence</h2>
                            </div>
                            <div className='text-center'>
                                <form onKeyDown={(e) => {
                                    if (e.key === "Enter" && !isLoggingIn) {
                                        handleLoginSubmit();
                                    };
                                }}>
                                    <div className='fv-row mb-8 d-flex flex-wrap'>
                                        <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>Email</label>
                                        <input
                                            className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')}
                                            value={credentials.email}
                                            onChange={(e) => { setCredentials({ ...credentials, email: e.target.value }) }}
                                            type={'text'}
                                            id='email'
                                            placeholder='Email'
                                        />
                                    </div>
                                    <div className='fv-row mb-3 d-flex flex-wrap '>
                                        <label className='form-label fw-bolder text-dark fs-6 mb-2' htmlFor='pass'>Password</label>
                                        <input
                                            className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')}
                                            value={credentials.password}
                                            onChange={(e) => { setCredentials({ ...credentials, password: e.target.value }) }}
                                            type={'password'}
                                            id='pass'
                                            placeholder='Password'
                                        />
                                    </div>
                                    <div className='d-flex fw-semibold mb-8 cursor-pointer flex-end'>
                                        <a className='link-primary '>Forgot password ?</a>
                                    </div>
                                    <div className='d-grid mb-10'>
                                        <button disabled={isLoggingIn} className='btn btn-primary' type={'button'} onClick={handleLoginSubmit}>
                                            Login
                                            {isLoggingIn &&
                                                <ReactBootstrap.Spinner animation='border' size='sm' className='ms-2' />}
                                        </button>
                                    </div>
                                    <div className='text-gray-500 text-center fw-semibold fs-6'>Not a Member yet? &nbsp;
                                        <Link className='link-primary cursor-pointer' to={process.env.REACT_APP_HOMEPAGE + '/account/register'}>Sign up</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
                    style={{
                        backgroundImage: `url("https://t4.ftcdn.net/jpg/04/67/96/13/360_F_467961350_LlpfNFYVGUwkofWQzB4uptbSxl12rWps.jpg")`
                    }}>
                    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                        <div className='w-lg-500px p-10'>
                            <div className='text-center mb-15'>
                                <div className='mb-10'>
                                    <img src={logo} />
                                    <img src={camera} className='w-100  mt-10' />
                                </div>
                                <div className='text-center mb-15'>
                                    <h1 className='text-white fw-bolder fs-2qx mb-5'>
                                        C2I
                                    </h1>
                                    <h2 className='text-white fs-base'>The C2I video surveillance software is designed to work in various environments and to be easily adapted to different scenarios.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TwoFactorAuthLoginModal
                show={show[0]}
                toggleModal={() => toggleModal(0)}
                storeUserCredentials={storeUserCredentials}
                mail={credentials.email}
            />
        </>
    )
}
