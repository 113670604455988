import { setCachedLocationList } from "../../redux/cachedLocationsReducer";
import LocationsTable from "./LocationsTable";

import getLocations from "../../web_api/methods/settings/locations/getLocations";
import useGet from "../../hooks/Common/useGet";
import LoadingPage from "../../layout/LoadingPage";

const Locations = () => {

    const { loading } = useGet([], getLocations, setCachedLocationList);

    return (
        <>
            {loading ?
                <LoadingPage />
                :
                <LocationsTable />
            }
        </>
    );
};

export default Locations;