import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { useLayout } from "./core"
import { PageTitle } from "./PageTitle"

const PageTitleWrapper = () => {
  const { config } = useLayout()
  const activeLocation = useSelector((state: RootState) => state.activeLocation.value);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const toolbar = useSelector((state: RootState) => state.toolbar);

  if (!config.app?.pageTitle?.display) {
    return null
  }

  return <PageTitle text={toolbar.toolbar === 'queue-management' ? activeLocation + ' - ' + ((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00'):toolbar.title} />
}

export { PageTitleWrapper }
