import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HourParametersState {
  from: string
  to: string
}
const initialState: HourParametersState = {
  from: localStorage.getItem('fromHour')!?.toString() != null ? localStorage.getItem('fromHour')!?.toString() : '0',
  to: localStorage.getItem('toHour')!?.toString() != null ? localStorage.getItem('toHour')!?.toString() : '24'
};

export const hourParameters = createSlice({
  name: 'hourParameters',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<HourParametersState>) => {
      state.from = action.payload.from
      state.to = action.payload.to === "0" ? "24" : action.payload.to
      localStorage.setItem("fromHour", action.payload.from)
      localStorage.setItem("toHour", action.payload.to === "0" ? "24" : action.payload.to)

    },
  },
});

export const { setValue } = hourParameters.actions;

export const hourParametersReducer = hourParameters.reducer;
