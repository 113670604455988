import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import queueManagementAvgCashRegisterOpen from '../../web_api/methods/queueManagementDash/avgCashRegisterOpen';
import { setValue as setAvgTimeCashRegisterAllLocations } from '../../redux/avgTimeCashRegisterAllLocationsReducer';
import { setValue as setQueueDurationAllLocations } from '../../redux/queueDurationAllLocationsReducer';
import { setValue as setAvgTimeCashRegisterOpenList } from '../../redux/avgTimeCashRegisterOpenListReducer';
import { setValue as setQueueDurationList } from '../../redux/queueDurationListReducer';
import { ChartsWidget15 } from '../../_metronic/widgets/charts/ChartsWidget15';
import { locationsList, userLocationList } from '../../web_api/models';
import getLocations from '../../web_api/methods/settings/locations/getLocations';
import { getTimeInExactMinutes, getTimeInMinutes, numberToTimeFormat } from '../../web_api/methods/helpers';

type Props = {
    className: string
    from: Date
    to: Date
}

const AvgTimesQueueManagement: React.FC<Props> = ({ className, from, to }) => {
    const [values, setValues] = useState<number[]>([])
    const [secondaryValues, setSecondaryValues] = useState<number[]>([])
    const [names, setNames] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState<string[]>([])
    const [oneLocationSelected, setOneLocationSelected] = useState(false)


    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const selectedUser = useSelector((state: RootState) => state.selectedUser);
    const dateFilter = useSelector((state: RootState) => state.dateFilter);
    const hourParameters = useSelector((state: RootState) => state.hourParameters);
    const userList = useSelector((state: RootState) => state.userList);

    const dispatch = useDispatch()
    useEffect(() => {
        loadDaysList()
    }, [dateFilter])

    const loadDaysList = () => {
        const fromDate = new Date(dateFilter.from);
        const toDate = new Date(dateFilter.to);

        const daysList = [];
        let currentDate = fromDate;
        while (currentDate <= toDate) {
            const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00`;
            daysList.push(dateString);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setDays(daysList)
    }
    const defineLocations = async () => {

    }
    const loadAudioAlerts = async () => {
        try {
            let loadedLocations: locationsList | userLocationList = []
            if (selectedUser.id === '0') {
                loadedLocations = await getLocations();
            }
            else
                loadedLocations = userList.list.find(x => x.id === selectedUser.id)?.locations!
            let forValues = new Array()
            let forSecondaryValues = new Array()
            let forNames = new Array()
            let saveAvgTimesListInStore = new Array()
            let saveQueueDurationListInStore = new Array()
            let avgSum = 0
            let queueDurationSum = 0
            let avgTimeInMinutes = 0
            let avgTimeInExactMinutes = 0
            let queueDurationInMinutes = 0
            let queueDurationInExactMinutes = 0
            if (activeLocation.id === "0") {
                setOneLocationSelected(false)
                for (let i = 0; i < loadedLocations.length; i++) {
                    let avgTimes = await queueManagementAvgCashRegisterOpen(loadedLocations[i].id, new Date(dateFilter.from), new Date(dateFilter.to));
                    let avgCashRegisterOpen = avgTimes.avg_time
                    let queueDuration = avgTimes.queue_duration

                    if (avgCashRegisterOpen !== undefined && avgCashRegisterOpen !== null && avgCashRegisterOpen !== "No data") {
                        avgTimeInMinutes = getTimeInMinutes(avgCashRegisterOpen);
                        avgTimeInExactMinutes = getTimeInExactMinutes(avgCashRegisterOpen);
                        forValues.push(avgTimeInMinutes)
                        saveAvgTimesListInStore.push({ avgTime: avgCashRegisterOpen !== "No data" ? avgTimeInExactMinutes : 0, name: loadedLocations[i].name, id: loadedLocations[i].id })
                    }
                    else forValues.push(0)
                    if (queueDuration !== undefined && queueDuration !== null && queueDuration !== "No data") {
                        queueDurationInMinutes = getTimeInMinutes(queueDuration);
                        queueDurationInExactMinutes = getTimeInExactMinutes(queueDuration);
                        forSecondaryValues.push(queueDurationInMinutes)
                        saveQueueDurationListInStore.push({ queue_duration: queueDuration !== "No data" ? queueDurationInExactMinutes : 0, name: loadedLocations[i].name, id: loadedLocations[i].id })
                    }
                    else forSecondaryValues.push(0)
                    forNames.push(loadedLocations[i].id)
                    if (avgTimeInExactMinutes !== undefined && avgTimeInExactMinutes !== null && !isNaN(avgTimeInExactMinutes))
                        avgSum += avgTimeInExactMinutes
                    if (queueDurationInExactMinutes !== undefined && queueDurationInExactMinutes !== null && !isNaN(queueDurationInExactMinutes))
                        queueDurationSum += queueDurationInExactMinutes
                }
                let minutes = (avgSum / loadedLocations.length); // input value
                let avgTimeCashRegisterAllLocations = numberToTimeFormat(minutes)
                let minutesQueueDuration = (queueDurationSum / loadedLocations.length); // input value
                let queueDurationAllLocations = numberToTimeFormat(minutesQueueDuration)

                dispatch(setAvgTimeCashRegisterAllLocations({
                    value: avgTimeCashRegisterAllLocations
                }))
                dispatch(setQueueDurationAllLocations({
                    value: queueDurationAllLocations
                }))
                dispatch(setAvgTimeCashRegisterOpenList({
                    list: saveAvgTimesListInStore
                }))
                dispatch(setQueueDurationList({
                    list: saveQueueDurationListInStore
                }))
            }
            else {
                setOneLocationSelected(true)
                for (let i = 0; i < days.length; i++) {
                    const date = new Date(days[i])
                    const day = date.getDate();
                    const month = date.toLocaleString('default', { month: 'short' });
                    const formattedDate = `${day} ${month}`;
                    forNames.push(formattedDate)
                    let avgTimes = await queueManagementAvgCashRegisterOpen(parseInt(activeLocation.id), date, date);
                    let avgCashRegisterOpen = avgTimes.avg_time
                    let queueDuration = avgTimes.queue_duration
                    const avgTimeInMinutes = getTimeInMinutes(avgCashRegisterOpen);
                    const queueDurationInMinutes = getTimeInMinutes(queueDuration);
                    forValues.push(avgTimeInMinutes)
                    forSecondaryValues.push(queueDurationInMinutes)
                }
            }
            setLoading(true)
            setValues(forValues)
            setSecondaryValues(forSecondaryValues)
            setNames(forNames)
        }
        catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        setLoading(false)
        defineLocations()
        loadAudioAlerts();
    }, [hourParameters, activeLocation, days, selectedUser])
    return (
        <ChartsWidget15
            className={className}
            xAxis={names}
            yAxis1={values}
            yAxis2={secondaryValues}
            loading={loading}
            oneLocationSelected={oneLocationSelected}
        />

    )
}
export { AvgTimesQueueManagement }