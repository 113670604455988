import React, { memo, useEffect } from "react";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import usePost from '../../hooks/Common/usePost';
import addDetection from '../../web_api/methods/settings/detections/addDetection';
import { useNavigate } from "react-router-dom";
import { CommonModal } from "../Common/CommonModal";
import { toast } from "react-toastify";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";
import { useDispatch } from "react-redux";
import { setValue as setActiveLocation } from "../../redux/activeLocationReducer";
import { setSelectedDetectionType } from "../../redux/selectedDetectionTypeReducer";
import { setValue as setSelectedCamera } from "../../redux/selectedCameraReducer";

type CameraDetectionAddProps = {
    show: boolean;
    toggleModal: () => void;
    camera: {
        name: string;
        id: number;
    };
};

const CameraDetectionAddModal: React.FC<CameraDetectionAddProps> = ({
    show,
    toggleModal,
    camera
}) => {

    const cachedDetectionList = useSelector((state: RootState) => state.detectionList.list);

    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
    const selectedDetectionType = useSelector((state: RootState) => state.selectedDetectionType);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setToolbar } = useToolbarSetter();

    const { data, setData, handleAddData, errors, isAdding } = usePost(
        {
            detectionTypes: [],
            cameraId: camera.id
        },
        async () => {
            try {
                const addDetectionPromises = data.detectionTypes.map((detectionType: number) =>
                    addDetection(detectionType, data.cameraId)
                );
                await Promise.all(addDetectionPromises);
            } catch (error) {
                throw error;
            }
        },
        () => {
            toggleModal();
            toast.success(`Detection${data.detectionTypes.length > 1 ? "s" : ""} added successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
            setData({
                ...data,
                detectionTypes: []
            });
            dispatch(
                setActiveLocation({
                    value: 'All',
                    id: '0'
                })
            );
            dispatch(
                setSelectedDetectionType({
                    value: 'All',
                    id: '0'
                })
            );
            dispatch(
                setSelectedCamera({
                    value: camera.name,
                    id: camera.id.toString()
                })
            );
            navigate(`${process.env.REACT_APP_HOMEPAGE}/detections`);
            setToolbar('detections', 'Detections');
        }
    );

    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };

    }, [errors?.length])
    

    const handleDetectionTypeSelection = (detectionType: number) => {
        const updatedDetectionTypes = data.detectionTypes.includes(detectionType)
            ? data.detectionTypes.filter((dt: number) => dt !== detectionType)
            : [...data.detectionTypes, detectionType];
        setData({ ...data, detectionTypes: updatedDetectionTypes });
    };

    return (
        <CommonModal
            size="lg"
            title={`Assign detections to camera: ${camera.name}`}
            show={show}
            disabled={isAdding}
            showModalCallback={toggleModal}
            closeButton={true}
            confirmButton={true}
            bodyClass="flex-column w-100"
            closeButtonOptions={{
                icon: 'bi bi-x',
                variant: 'secondary',
                class: "",
            }}
            confirmButtonOptions={{
                icon: "bi bi-check text-white",
                variant: 'custom',
                class: "bg-primary text-white",
                onClick: handleAddData
            }}
        >
            <>
                <div className="d-flex flex-column flex-sm-row flex-sm-wrap justify-content-sm-center mt-2 mb-4 gap-3 gap-sm-4 overflow-auto h-300px h-sm-100">
                    {[...cachedDetectionList].sort((a, b) => a.detection_name.localeCompare(b.detection_name)).map((detection) => (
                        <span
                            key={detection.detection_type}
                            className={`badge cursor-pointer p-3 d-flex justify-content-center ${data.detectionTypes.includes(detection.detection_type) ? 'badge-primary' : 'badge-light'}`}
                            onClick={() => handleDetectionTypeSelection(detection.detection_type)}
                        >
                            {detection.detection_name}
                        </span>
                    ))}
                </div>
            </>
        </CommonModal>
    );
};

export default CameraDetectionAddModal;
