import { Link, useParams } from "react-router-dom";
import CameraInfoSettings from "../../Cameras/CameraInfo/CameraInfoSettings";
import { useState } from "react";
import DetectionInfoSettings from "./DetectionInfoSettings";
import placeholderImage from "../../../assets/images/placeholder-denmark.jpg";
import useGet from "../../../hooks/Common/useGet";
import getDetections from "../../../web_api/methods/settings/detections/getDetections";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LoadingPage from "../../../layout/LoadingPage";


const DetectionInfo = () => {

    const params = useParams();

    const id = Number(params.id);

    const { data: detection, loading } = useGet([], () => getDetections({id: id}));

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);

    const cameraName = cachedCameraList.find(camera => camera.id === detection?.[0]?.camera_id)?.name;

    const detectionName = detection?.[0]?.detection_name;

    const [selectedSettings, setSelectedSettings] = useState("detection");

    const handleSettingsChange = (setting: string) => {
        setSelectedSettings(setting);
    };

    return (
        <>
            {loading ? (
              <LoadingPage />
            ) :
                (
                    <div className="container pt-3 px-4">
                        <div className="d-flex p-1">
                            <Link to={`${process.env.REACT_APP_HOMEPAGE}/detections`}>
                                <button className="btn btn-primary mb-5">
                                    <i className="bi bi-arrow-return-left"></i>
                                    Return
                                </button>
                            </Link>
                        </div>


                        <div className="card mb-5 mb-xl-10">
                            <div id="kt_account_profile_details" className="collapse show">
                                <div className="card-body border-top p-5">
                                    <div className="row">
                                        <div className="col-lg-6 position-relative">
                                            <img
                                                src={placeholderImage}
                                                alt="Camera Image"
                                                className="img-fluid rounded w-100"
                                            />
                                        </div>
                                        <div className="col-lg-3 col-xxl-4 order-lg-2 mt-3 mb mt-sm-2">
                                            <p className="fw-bold mb-2">{`${cameraName} - ${detectionName}`}</p>
                                        </div>
                                        <div className="col-lg-3 col-xxl-2 order-lg-3 mt-2">
                                            <div className="d-flex justify-content-lg-end mb-3">
                                                <Link to={`${process.env.REACT_APP_HOMEPAGE}/detections/${id}/annotation`}>
                                                    <button type="submit" className="btn btn-primary me-3">
                                                        Annotate
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bolder fs-3 mb-1">Settings</span>
                                </h3>
                                <div className="d-flex align-items-center flex-shrink-0">
                                    <div className="flex-shrink-0">
                                        <ul className="nav">
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${selectedSettings === "detection" ? "active" : ""
                                                        }`}
                                                    data-bs-toggle="tab"
                                                    onClick={() => handleSettingsChange("detection")}
                                                >
                                                    Detection
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${selectedSettings === "camera" ? "active" : ""
                                                        }`}
                                                    data-bs-toggle="tab"
                                                    onClick={() => handleSettingsChange("camera")}
                                                >
                                                    Camera
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className={`settings-panel ${selectedSettings === "detection" ? "" : "d-none"}`}>
                                    {detection ? <DetectionInfoSettings detection={detection[0]} /> : null}
                                </div>
                                <div className={`settings-panel ${selectedSettings === "camera" ? "" : "d-none"}`}>
                                    {detection ? <CameraInfoSettings id={detection[0].camera_id} /> : null}
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                )}
        </>

    );
};

export default DetectionInfo;
