import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { CommonModal } from "../Common/CommonModal";
import { toast } from "react-toastify";
import useDelete from "../../hooks/Common/useDelete";
import deleteCameraModels from "../../web_api/methods/settings/cameraModels/deleteCameraModels";
import { useEffect, useState } from "react";
import { setcachedCameraModelList } from "../../redux/cameraModelListReducer";


type CameraModelsDeleteModalProps = {
  onModalToggle: () => void,
  show: boolean,
  modelData: {
    checked: number[];
    current: {
      id: number,
      name: string,
      command: string,
      command_local: string
    };
  };
  onDelete: () => void
};

const CameraModelsDeleteModal: React.FC<CameraModelsDeleteModalProps> = ({
  onModalToggle,
  onDelete,
  show,
  modelData
}) => {

  const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const checkedModelNames = modelData.checked.map((cameraModelId) => {
    const cameraModel = cachedCameraModelList.list.find((model) => model.id === cameraModelId);
    return cameraModel ? `${cameraModel.id} - ${cameraModel.name}` : "";
  });

  const handleDeleteCameraModel = async () => {
    try {
      setIsDeleting(true);
      if (modelData.checked.length > 0) {
        await deleteCameraModels(modelData.checked);

        const updatedLocationList = cachedCameraModelList.list.filter(
          (locationItem) => !modelData.checked.includes(locationItem.id)
        );
        dispatch(setcachedCameraModelList({ list: updatedLocationList }));
      } else {
        await deleteCameraModels([modelData.current.id]);
        const updatedLocationList = cachedCameraModelList.list.filter(
          (locationItem) => locationItem.id !== modelData.current.id
        );
        dispatch(setcachedCameraModelList({ list: updatedLocationList }));
      }
      onModalToggle();
      onDelete();
      toast.success(`Camera model${modelData.checked[0] ? 's' : ''} deleted successfully!`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        autoClose: 2000
      });
    } catch (e) {
      console.log(e);
      toast.error(`${e}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <CommonModal
        title="Confirm"
        size={modelData.checked[0] ? "lg" : undefined}
        disabled={isDeleting}
        show={show}
        showModalCallback={onModalToggle}
        closeButton={true}
        confirmButton={true}
        bodyClass="flex-column w-100 ps-15 pe-15 pb-10"
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: "",
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash text-white',
          variant: 'custom',
          class: 'bg-danger text-white',
          onClick: handleDeleteCameraModel
        }}
      >
        <div>{`Are you sure you want to delete the following camera model${modelData.checked[0] ? 's' : ''}?`}</div>
        {modelData.checked[0] ? checkedModelNames.map((name) => (
          <div key={name} className="fw-bolder mt-3">
            {name}
          </div>
        )) :
          <div className="fw-bolder mt-3">{`${modelData.current.id} - ${modelData.current.name}`}</div>}
      </CommonModal>
    </>
  );
}

export default CameraModelsDeleteModal;