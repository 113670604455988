import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface compareLocationState {
  value: string
  id: string
}
const initialState: compareLocationState = {
  value: localStorage.getItem('compareLocation1')!?.toString()!=null?localStorage.getItem('compareLocation1')!?.toString():'All',
  id: localStorage.getItem('compareLocationId1')!?.toString()!=null?localStorage.getItem('compareLocationId1')!?.toString():'0'
};

export const compareLocation1 = createSlice({
  name: 'compareLocation1',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<compareLocationState>) => {
      state.value = action.payload.value
      state.id = action.payload.id
      localStorage.setItem("compareLocation1", action.payload.value)
      localStorage.setItem("compareLocationId1", action.payload.id)

    },
  },
});

export const { setValue } = compareLocation1.actions;

export const compareLocation1Reducer = compareLocation1.reducer;
