import React, { useEffect, useState } from 'react'
import { DayWeekMonthYearEnum } from '../../web_api/models';
import { AlertsByAreaChart } from './AlertsByAreaChart';
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
type Props = {
    className?: string
    filter: string
};

const AlertsByArea: React.FC<Props> = ({ className, filter }) => {
    const dayWeekMonthYear = convertStringToEnumValue(filter)//dependency
    const activeLocation = useSelector((state: RootState) => state.activeLocation)
    const selectedUser = useSelector((state: RootState) => state.selectedUser)
    //dependencies
    const [values, setValues] = useState<number[][]>([])
    const [dates, setDates] = useState<string[]>([])
    const [labels, setLabels] = useState<string[][]>([])
    const [total, setTotal] = useState<number>(0)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        loadGenderReports(new Date(), new Date())
        // console.log(filter)
    }, [dayWeekMonthYear, activeLocation, selectedUser])
    let loadGenderReports = async (from: Date, to: Date) => {
        try {
            setLoaded(false)
            // let loadGender: genderResponse = await peopleCountingGender(from, to);
            //call here
            setValues([[230, 400, 100, 500, 300, 250, 230], [430, 820, 930, 560, 600, 440, 620], [350, 200, 80, 120, 210, 210, 320]])
            setLabels([['Retail'], ['Pump'], ['Forecourt']])
            setDates(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
            setTotal(1230)
            setLoaded(true)
        }
        catch (e) {
            console.log(e)
        }
    };

    return (
        <div className={`card h-100 ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Alerts by area</span>
                    <span className='text-muted fw-semibold fs-7'>Alerts every day</span>
                </h3>
            </div>
            <div className='card-body p-0'>
                <AlertsByAreaChart
                    type='bar'
                    className='w-sm-100'
                    chartColor='danger'
                    chartHeight='150px'
                    filter={DayWeekMonthYearEnum.WEEK}
                    dates={dates}
                    labels={labels}
                    values={values}
                    loaded={loaded}
                />
            </div>
        </div>
    )
}
export { AlertsByArea }