import callApiAxiosClient from "../../../axios/axios_client";
import { cameraModelInList } from "../../../models";

interface updateCameraModelArgs {
    model_id: number,
    name: string,
    command: string,
    command_local: string
}

async function updateCameraModel({ model_id, name, command, command_local }: updateCameraModelArgs): Promise<cameraModelInList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<cameraModelInList>({
        method: "put",
        url: "settings/modify-camera-models",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            model_id: model_id,
            name: name,
            command: command,
            command_local: command_local
        }
    })
}
export default updateCameraModel;