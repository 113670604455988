import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareLocationState } from './compareLocation1Reducer';

const initialState: compareLocationState = {
  value: localStorage.getItem('compareLocation2')!?.toString() != null ? localStorage.getItem('compareLocation2')!?.toString() : 'All',
  id: localStorage.getItem('compareLocationId2')!?.toString() != null ? localStorage.getItem('compareLocationId2')!?.toString() : '0'
};

export const compareLocation2 = createSlice({
  name: 'compareLocation2',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<compareLocationState>) => {
      state.value = action.payload.value
      state.id = action.payload.id
      localStorage.setItem("compareLocation2", action.payload.value)
      localStorage.setItem("compareLocationId2", action.payload.id)

    },
  },
});

export const { setValue } = compareLocation2.actions;

export const compareLocation2Reducer = compareLocation2.reducer;
