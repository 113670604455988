import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToolbarState {
  toolbar: string
  title: string
}
const initialState: ToolbarState = {
  toolbar: localStorage.getItem('toolbar')!?.toString() != null ? localStorage.getItem('toolbar')!?.toString() : 'default',
  title: localStorage.getItem('title')!?.toString() != null ? localStorage.getItem('title')!?.toString() : '',

};

export const toolbar = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<ToolbarState>) => {
      state.toolbar = action.payload.toolbar
      state.title = action.payload.title
      localStorage.setItem("toolbar", action.payload.toolbar)
      localStorage.setItem("title", action.payload.title)

    },
  },
});

export const { setValue } = toolbar.actions;

export const toolbarReducer = toolbar.reducer;
