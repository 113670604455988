/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import { ageResponseList, ComparisonEnum, DayWeekMonthYearEnum, entriesHoursList, evolutionValueList, genderResponse, locationsList } from '../../../web_api/models'
import peopleCountingEvolutionValue from '../../../web_api/methods/peopleCountingDash/evolutionValue'
import { format } from 'date-fns'
import { Modal, Button } from 'react-bootstrap'
import { DateRange, DayPicker } from 'react-day-picker'
import peopleCountingEntriesHours from '../../../web_api/methods/peopleCountingDash/entriesHours'
import peopleCountingGender from '../../../web_api/methods/peopleCountingDash/genderReports'
import peopleCountingAge from '../../../web_api/methods/peopleCountingDash/ageReports'
import { dateFilter } from '../../../redux/dateFilterReducer'
import { hourParameters } from '../../../redux/hourParametersReducer'
import queueManagementAudioAlerts from '../../../web_api/methods/queueManagementDash/audioAlerts'
import getLocations from '../../../web_api/methods/settings/locations/getLocations'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'


type Props = {
  className: string
  chartHeight: string
  chartColor: string
  type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap'
  location: { value: string, id: string }
  comparison: ComparisonEnum
  user: string
}

const ComparisonSeparateChart: React.FC<Props> = ({ className, chartHeight, chartColor, type, location, comparison, user }) => {
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const [days, setDays] = useState<string[]>([])

  const chartRef = useRef<HTMLDivElement | null>(null)
  const [values, setValues] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])
  let forValues = new Array()
  let forLabel = new Array()

  // useEffect(() => {
  //   loadData(comparison)
  // }, [])

  useEffect(() => {
    loadData(comparison)//dupa range
  }, [comparison, location, dateFilter, hourParameters, user])

  let loadEvolutionValues = async (filter: DayWeekMonthYearEnum, from?: Date, to?: Date) => {
    try {
      let evolutionValues: evolutionValueList = await peopleCountingEvolutionValue(filter);
      forValues = []
      forLabel = []
      evolutionValues.forEach((item) => {
        forValues.push(item.object_count)
        forLabel.push(item.date)
      })
      setValues(forValues)
      setLabels(forLabel)
    }
    catch (e) {
      console.log(e)
    }
  };

  let loadEntriesHours = async (filter: DayWeekMonthYearEnum) => {
    try {
      var from = new Date()
      var to = new Date()
      if (filter === DayWeekMonthYearEnum.DAY) {
        from = new Date(new Date().setHours(0, 0, 0, 0));
        to.setDate(to.getDate() + 1)
      }
      else if (filter === DayWeekMonthYearEnum.WEEK) {
        from.setDate(from.getDate() - 7)
      }
      else {
        from = new Date(new Date().setMonth(new Date().getMonth() - 1));
      }
      let loadEntriesWithHours: entriesHoursList = await peopleCountingEntriesHours(from, to);
      forValues = []
      forLabel = []
      loadEntriesWithHours.forEach((item) => {
        forValues.push(item.entries)
        forLabel.push(item.period)
      })
      setValues(forValues)
      setLabels(forLabel)
    }
    catch (e) {
      console.log(e)
    }
  };

  let loadGenderReports = async (from: Date, to: Date) => {
    try {
      let loadGender: genderResponse = await peopleCountingGender(from, to);
      setLabels(['Male', 'Female'])
      setValues([loadGender.male, loadGender.female])

    }
    catch (e) {
      console.log(e)
    }
  };
  let loadAgeReports = async (from: Date, to: Date) => {
    try {
      let loadAge: ageResponseList = await peopleCountingAge(from, to);
      forValues = []
      forLabel = []
      loadAge.forEach((item) => {
        forValues.push(item.object_count)
        forLabel.push(item.age)
      })
      setValues(forValues)
      setLabels(forLabel)
    }
    catch (e) {
      console.log(e)
    }
  };


  const loadAudioAlerts = async () => {
    try {
      //days
      const fromDate = new Date(dateFilter.from);
      const toDate = new Date(dateFilter.to);

      const daysList = [];
      let currentDate = fromDate;
      while (currentDate <= toDate) {
        const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00`;
        daysList.push(dateString);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let loadedLocations: locationsList = await getLocations();
      let forValues = new Array()
      let forValues2 = new Array()
      let forNames = new Array()
      let sum = 0
      let audioAlerts = await queueManagementAudioAlerts(parseInt(location.id), new Date(dateFilter.from), new Date(dateFilter.to), parseInt(hourParameters.from), parseInt(hourParameters.to), user);
      if (location.id === "0") { // toate locatiile
        if (daysList.length > 1) // mai multe zile
          loadedLocations.forEach((loc) => {
            sum = 0
            audioAlerts.filter(x => x.location_id === loc.id).forEach((item) => {
              sum += item.total
            })
            forValues.push(sum)
            forNames.push(loc.id)
          })
        else { // o singura zi
          audioAlerts.forEach((item) => {
            forValues.push(item.total)
            forNames.push(item.location_id)
          })
        }
      }
      else { // o locatie
        if (daysList.length > 1) // mai multe zile
          audioAlerts.forEach((item) => {
            forValues.push(item.total)
            const date = new Date(item.time)
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });

            const formattedDate = `${day} ${month}`;
            forNames.push(formattedDate)
          })
        else { // o singura zi
          audioAlerts.forEach((item) => {
            forValues.push(item.total)
            forNames.push(item.time.toString() + ':00')
          })
        }
      }
      setValues(forValues)
      setLabels(forNames)
    }
    catch (e) {
      console.log(e)
    }
  };
  let loadData = async (comparisonType: ComparisonEnum) => {
    try {
      if (comparisonType === ComparisonEnum.AUDIO_ALERTS)
        loadAudioAlerts()
      else if (comparisonType === ComparisonEnum.QUEUE_ALERTS) {
        chartColor === 'danger' ?
          loadEntriesHours(DayWeekMonthYearEnum.WEEK) :
          loadEntriesHours(DayWeekMonthYearEnum.MONTH)
      }
      else if (comparisonType === ComparisonEnum.AVG_TIME_CASH_REGISTER_OPEN
      ) {

      }
      else if (comparisonType === ComparisonEnum.AVG_TIME_QUEUE
      ) {

      }
    }
    catch (e) {
      console.log(e)
    }
  };

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(chartColor, labels, values, type))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, values, labels])


  return (
    <div className={`card ${className}`}>
      <div className='card-body p-5 pb-0 d-flex flex-column'>
        <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom' data-kt-chart-color={chartColor} style={{ height: chartHeight }}></div>
      </div>
    </div>

  )
}

const chartOptions = (chartColor: string, xAxis1: string[], yAxis: number[], type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap'): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')

  return {
    series: [
      {
        name: 'Audio alerts',
        data: yAxis,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.5
      }
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: xAxis1,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
    colors: [baseColor],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
export { ComparisonSeparateChart }