/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import "react-tooltip/dist/react-tooltip.css";
import { missingProductList } from '../../../web_api/models'
import * as ReactBootstrap from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { bakeryProductId, bakeryProductNameFormat } from '../../../web_api/methods/helpers'
import LoadingPage from '../../../layout/LoadingPage';


type Props = {
    className: string
    title: string | null
    loading: boolean
    missingProducts: missingProductList
}

const MissingProducts: React.FC<Props> = ({ className, title, loading, missingProducts }) => {
    const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
    const [missingProductList, setMissingProductList] = useState<missingProductList>([])
    useEffect(() => { setMissingProductList(missingProducts) }, [missingProducts])
    function handleFilterByFillPercentage() {
        var list = missingProductList;
        var sortedList = list.slice().sort((a, b) => b.missing_points - a.missing_points);
        setMissingProductList([...sortedList]);
        setFilterIcon({ fill: true, availability: false });
    }
    function handleFilterByAvailability() {
        var list = missingProductList;
        var sortedList = list.slice().sort((a, b) => b.availability - a.availability);
        setMissingProductList([...sortedList]);
        setFilterIcon({ fill: false, availability: true });
    }

    const [filterIcon, setFilterIcon] = useState({
        fill: false,
        availability: false
    });

    // const handleDownload = () => {
    //     let values = new Array()
    //     let names = new Array()
    //     avgTimeCashRegisterList.forEach((item) => {
    //         values.push(item.avgTime)
    //         names.push(item.name)
    //     })
    //     exportCSV([['Location', 'Response time']], names, values, 'Response times - ' + activeLocation.value + ' - ' +
    //         ((dateFilter.from === dateFilter.to) ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    //             :
    //             new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
    //             + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    //     )
    // };

    const changingComponent = useMemo(() => {
        return (
            <table className={`table ${window.innerWidth > 576 ? 'table-row-dashed' : ''} table-row-gray-200 align-middle gs-0 gy-4 d-flex flex-column d-sm-table`}>
                {/* begin::Table head */}
                <thead>
                    <tr className='border-0 d-flex d-sm-table-row justify-content-end mb-2 mb-sm-0'>
                        <th className='py-0 w-50px d-none d-sm-table-cell'>Id</th>
                        <th className='py-0 min-w-175px d-none d-sm-table-cell'>Product</th>
                        <th className='p-0 min-w-sm-125px cursor-pointer d-flex d-sm-table-cell text-end text-sm-start' onClick={handleFilterByFillPercentage}>
                            <span className=''>Fill percentage&nbsp;</span>
                            <i className={`bi ${filterIcon.fill && !filterIcon.availability ? 'bi-sort-down' : 'bi-sort-up'} col`} />
                        </th>
                        <th className='py-0 min-w-sm-100px cursor-pointer d-flex d-sm-table-cell text-end text-sm-start' onClick={handleFilterByAvailability}>
                            <span className=''>Availability&nbsp;</span>
                            <i className={`bi ${filterIcon.availability && !filterIcon.fill ? 'bi-sort-down' : 'bi-sort-up'} col-md`} />
                        </th>
                        {/* <th className='p-0 min-w-50px'></th> */}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {missingProductList?.map((item) => {
                        return (
                            <tr key={bakeryProductId(item.product)}>
                                <td className='mx-auto mx-sm-0'>
                                    <div className="fs-sm- fs-md-3 fs-9 fw-bold bg-primarys text-inverse-primary rounded-2 px-2 py-3" style={{ backgroundColor: '#F1416C' }}>
                                        <p className='m-auto'>
                                            {bakeryProductId(item.product)} 
                                        </p>
                                    </div>
                                </td>
                                <td className='w-100'>
                                    <span className='text-dark fw-bold mb-1 fs-sm-6 fs-9'>
                                        {bakeryProductNameFormat(item.product)}
                                    </span>
                                </td>
                                <td className='w-sm-100 text-center text-sm-start'>
                                    <span className='text-dark'>
                                        {Math.round(item.missing_points * 100)}% empty
                                    </span>
                                </td>
                                <td className='w-sm-100 text-center text-sm-start'>
                                    <span className='text-dark '>
                                        {Math.round(item.availability * 100)}%
                                    </span>
                                </td>
                                <td className='d-block d-sm-none'>
                                    <hr />
                                </td>
                            </tr>
                        )
                    })}
                </tbody >
                {/* end::Table body */}
            </table >
        )
    }, [toolbarHourFilter, missingProductList])



    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{title}
                        <span className='fs-7 text-muted ms-xl-2 mt-2 mt-xl-0 d-block d-xl-inline'>
                            {toolbarHourFilter.hour}:00 to {toolbarHourFilter.toHour}:00
                        </span>
                    </span>

                </h3>
                {/* <div className='card-toolbar'>
                    <ul className='nav'>
                        <li className='nav-item m-auto'>
                            <div className={'nav-link btn btn-sm btn-color-dark btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Download'>
                                <i className="bi bi-save p-0 mb-1" />
                            </div>
                        </li>
                    </ul>
                </div> */}

            </div>
            {
                loading ? (
                    <div className='card-body py-3 overflow-auto '>
                        {changingComponent}
                    </div>
                ) : (<LoadingPage />
                )
            }

            {/* end::Body */}
        </div>
    )
}

export { MissingProducts }

