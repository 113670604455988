import React from 'react'
import { RecentAlertItem } from './RecentAlertItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
type Props = {
    className?: string
};

const RecentAlerts: React.FC<Props> = ({ className }) => {
    const activeLocation = useSelector((state: RootState) => state.activeLocation)
    const selectedUser = useSelector((state: RootState) => state.selectedUser)
    //dependencies
    const alerts = [
        { text: 'Smoking in proximity to pump', date: '23/08/2023' },
        { text: 'Unnaccompanied minor on the forecourt', date: '23/08/2023' },
        { text: 'Cellphone use while fuelling', date: '23/08/2023' },
        { text: 'Blocked exit detected', date: '23/08/2023' },
        { text: 'Pump unattended', date: '23/08/2023' }
    ]
    return (
        <div className={`card h-100 ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Recent alerts</span>
                    <span className='text-muted fw-semibold fs-7'>Last 5 recorded alerts</span>
                </h3>
            </div>
            <div className='card-body pt-0'>
                <div className='h-100'>
                    {alerts.map((alert) => {
                        return (
                            <RecentAlertItem key={`${alert.text}-${alert.date}`} text={alert.text} date={alert.date} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export { RecentAlerts }