import callApiAxiosClient from "../../axios/axios_client";
import { mostQueueAlertsList } from "../../models";
import moment from "moment";


async function queueManagementMostQueueAlerts(locationId: number, fromParam: Date, toParam: Date, fromHour: number, toHour: number, userId: string): Promise<mostQueueAlertsList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<mostQueueAlertsList>({
        method: "get",
        url: "programmability/most-queue-alerts",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            locationId: locationId,
            from: from,
            to: to,
            fromHour: fromHour,
            toHour: toHour,
            userId: userId
        }
    })
}
export default queueManagementMostQueueAlerts;