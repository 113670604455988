/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import Dropdown1 from '../../dropdown/Dropdown1'
import { KTSVG } from '../../helpers'
import { numberFormat } from '../../../web_api/methods/helpers'
import * as ApexChartsLib from 'apexcharts';

type Props = {
  xAxis: string[]
  yAxis: number[]
}

const Chart1WidgetWithImage: React.FC<Props> = ({ xAxis, yAxis }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [chartReady, setChartReady] = useState(false);
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, yAxis, xAxis])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(170, xAxis, yAxis))

    if (chart) {
      chart.render().then(() => {
        setChartReady(true)
      }
      )
    }

    return chart
  }




  return (
    <div className='card-body p-0 d-flex flex-column'>
      <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom' data-kt-chart-color={'primary'} style={{ height: '150px' }}></div>
    </div>
  )
}

function getChartOptions(height: number, xAxis: string[], yAxis: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-light')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-light')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const today = new Date().toDateString()
  return {
    series: [
      {
        name: 'Alerts',
        data: yAxis,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: today
          },
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 700
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 700
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return numberFormat(val) + ' entries'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true
        }
      }
    },
  }
}

export { Chart1WidgetWithImage }