type CommonTableColumnProps = {
    name: string
    className: string
}

const CommonTableColumn: React.FC<CommonTableColumnProps> = ({
    name,
    className
}) => {
    return(
        <>
            <th className={`${className} cursor-pointer`}>{name}</th>
        </>
    );
}

export default CommonTableColumn