import callApiAxiosClient from "../../axios/axios_client";
import { userItemInList } from "../../models";


function addUser(email: string, password: string, confirm_password: string, role_id: number, locations: number[]): Promise<userItemInList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<userItemInList>({
        method: "post",
        url: "usersactions/users",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            email: email,
            password: password,
            confirm_password: confirm_password,
            role_id: role_id,
            locations: locations
        }
    })
}
export default addUser;