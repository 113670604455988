import * as ReactBootstrap from 'react-bootstrap'
import LoadingPage from '../../../layout/LoadingPage'

type Props = {
  className: string
  description: string
  color: string
  img: string
  icon: string
  data: { number: number, time: string }
  title: string
  label: string
  subtitle: string
  loading: boolean
}

const ChartsWidget5 = ({ className, description, color, img, icon, data, title, label, subtitle, loading }: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    {
      loading ?
        (<>
          <div className='card-header pt-5'>
            <div className='d-flex flex-center rounded-circle h-80px w-80px' style={{ border: '1px dashed rgba(255,255,255,0.4)' }}>
              <i className={"text-white fs-3qx lh-0 " + icon} />
            </div>
          </div>
          <div className='card-body d-flex align-items-end mb-3'>
            <div className='d-flex align-items-center'>
              <span className="fs-4hx text-white fw-bold me-6">{data.number}</span>
              <div className="fw-bold fs-6 text-white">
                <span className="d-block">{title}</span>
                <span>{label}</span>
              </div>
            </div>
          </div>
          <div className="card-footer" style={{ borderTop: '1px solid(255,255,255,0.3)', backgroundColor: 'rgba(0,0,0,0.15)' }}>
            <div className="fw-bold text-white py-2">
              <span className="fs-1 d-block">{data.time}</span>
              <span className="opacity-50">{subtitle}</span>
            </div>
          </div></>
        )
        :
        (<LoadingPage />)
    }

  </div>
)
export { ChartsWidget5 }
