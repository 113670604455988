import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG, WithChildren } from '../_metronic/helpers'
import { useLayout } from './core'
import { setValue as setToolbar } from "../redux/toolbarReducer"
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  tab: string
  handleToggleSub?: (title: string) => void
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  tab,
  handleToggleSub
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config
  const dispatch = useDispatch();
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);

  function handleChangeTab() {
    const sidebar = document.getElementById('kt_app_sidebar');
    const toggleClasses = ['drawer', 'drawer-start', 'drawer-on', 'animated', 'fadeInLeft'];
    toggleClasses.forEach(x => sidebar?.classList.remove(x));
    dispatch(setToolbar(
      {
        toolbar: tab,
        // title: activeLocation.value + ' - ' + ((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00')
        title: tab === 'queue-management' ? activeLocation.value + ' - ' + ((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00') : title
      }))
  }
  return (
    <div className='menu-item' onClick={() => {
      handleChangeTab();
      if (handleToggleSub) {
        handleToggleSub(title);
      }
    }}>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {!icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTSVG path={fontIcon!} className={fontIcon} />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export { SidebarMenuItem }
