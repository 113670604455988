import { toast } from "react-toastify"
import useDelete from "../../hooks/Common/useDelete"
import useModals from "../../hooks/Common/useModals"
import deleteDetection from "../../web_api/methods/settings/detections/deleteDetections"
import { CommonCard } from "../Common/CommonCard/CommonCard"
import CommonCardButton from "../Common/CommonCard/CommonCardButton"
import CommonCardItem from "../Common/CommonCard/CommonCardItem"
import { CommonModal } from "../Common/CommonModal"
import { percentageFormat } from "../../web_api/methods/helpers"
import { useEffect, useState } from "react"
import { cameraDetectionInList } from "../../web_api/models"
import addDetection from "../../web_api/methods/settings/detections/addDetection"

type DetectionCardProps = {
    detectionItem: {
        id: number
        detection_id: number
        detection_name: string
        dependent_detection_id: number
        camera_id: number
        camera_name: string
        created_date: Date
        detection_type: number
        is_alert: boolean
        max_nr_objects: number
        processing_window: number
        min_nr_objects: number
        nr_occurrences: number
        save_picture: boolean
        threshold: number
        user_id: string
        object_class: string
        fps: number
        dependent_no_of_frames: number
        detection_server_id: number
        has_next_page: boolean
        region_name: number
    },
    onDetectionCheck: (id: number) => void,
    onDetectionDelete: (id: number) => void,
    onDetectionAdd: (detection: cameraDetectionInList) => void;
}

const DetectionCard: React.FC<DetectionCardProps> = ({
    detectionItem,
    onDetectionCheck,
    onDetectionDelete,
    onDetectionAdd
}) => {

    const { show, toggleModal } = useModals([false]);

    const [isAdding, setIsAdding] = useState<boolean>(false);

    const handleDuplicateDetection = async () => {
        try {
            setIsAdding(true);
            let duplicatedDetection = await addDetection(
                detectionItem.detection_type,
                detectionItem.camera_id,
                detectionItem.detection_server_id
            )

            const detectionToAdd = {
                ...duplicatedDetection,
                camera_name: detectionItem.camera_name,
                detection_name: detectionItem.detection_name
            };

            onDetectionAdd(detectionToAdd);

            setIsAdding(false);

            toast.success('Detection added successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });

        } catch (e) {
            console.log(e);
            setIsAdding(false);
            toast.error(`${e}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        }
    };

    const { deleteItems: handleDeleteDetection, errors, isDeleting } = useDelete(
        () => deleteDetection([detectionItem.id]),
        () => {
            toggleModal(0);
            onDetectionDelete(detectionItem.id)
            toast.success('Detection deleted successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
    );

    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    }, [errors?.length])

    return (
        <>
            <div className='animated zoomIn col-12 col-md-6 col-xxl-4'>
                <CommonCard
                    key={detectionItem.camera_name}
                    containerClass={`my-3 h-min-300px animated ${window.innerWidth > 576 ? 'hover-grow' : ''} w-100`}
                    background='url("https://t4.ftcdn.net/jpg/04/67/96/13/360_F_467961350_LlpfNFYVGUwkofWQzB4uptbSxl12rWps.jpg")'
                    headingLarge={detectionItem.detection_name}
                    headingSmall={detectionItem.camera_name}
                    handleTrashIconClick={() => toggleModal(0)}
                    handleCheckClick={() => onDetectionCheck(detectionItem.id)}
                    items={
                        <>
                            <CommonCardItem
                                textLarge="Camera ID"
                                textSmall=""
                                value={detectionItem.camera_id}
                            />
                            <CommonCardItem
                                textLarge="Detection ID"
                                textSmall=""
                                value={detectionItem.id}
                            />
                            <CommonCardItem
                                textLarge="Threshold"
                                textSmall=""
                                value={percentageFormat(detectionItem.threshold)}
                            />
                        </>
                    }
                    buttons={
                        <>
                            <CommonCardButton
                                text="Edit detection settings"
                                link={`${process.env.REACT_APP_HOMEPAGE}/detections/${detectionItem.id}`}
                                icon="bi bi-clipboard2"
                                handleIconClick={handleDuplicateDetection}
                                disabledIcon={isAdding}
                                iconTooltip="Duplicate this detection"
                            />
                            <CommonCardButton
                                text="Add detection regions"
                                link={`${process.env.REACT_APP_HOMEPAGE}/detections/${detectionItem.id}/annotation`}
                            />
                        </>
                    }
                />
            </div>
            <CommonModal
                title={"Confirm"}
                show={show[0]}
                disabled={isDeleting}
                showModalCallback={() => toggleModal(0)}
                closeButton={true}
                confirmButton={true}
                bodyClass="flex-column w-100"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: "bi bi-trash3 text-white",
                    variant: 'custom',
                    class: "bg-danger text-white",
                    onClick: handleDeleteDetection
                }}
            >
                <div className="text-justify">Are you sure you want to delete the following the camera's detection?</div>
                <div className="fw-bolder mt-2">{`${detectionItem.id} - ${detectionItem.camera_name} - ${detectionItem.detection_name}`}</div>
            </CommonModal>
        </>
    )
}

export default DetectionCard;