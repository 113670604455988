/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils'
import { ComparisonEnum, DayWeekMonthYearEnum, locationsList, MixedOrSeparateEnum } from '../../../web_api/models'
import peopleCountingEntriesHours from '../../../web_api/methods/peopleCountingDash/entriesHours'
import * as ReactBootstrap from 'react-bootstrap'
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import test from '../../../assets/images/sm-megamall.jpg'
import 'react-day-picker/dist/style.css';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from '../../../redux/compareWithLocationReducer'
import { RootState } from '../../../redux/store'
import getLocations from '../../../web_api/methods/settings/locations/getLocations'
import { LocationData } from '../../../pages/EvolutionComparison/LocationData'
type Props = {
    className: string
    callbackComparison: (active: ComparisonEnum) => void

}

const ChartsWidget12: React.FC<Props> = ({ className, callbackComparison }) => {
    const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
    const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
    const compareUser1 = useSelector((state: RootState) => state.compareUser1);
    const compareUser2 = useSelector((state: RootState) => state.compareUser2);

    const [chartComparison, setChartComparison] = useState<ComparisonEnum>(ComparisonEnum.AUDIO_ALERTS)

    return (
        <>
            <div className={`card ${className}`}>
                {/* <div className='card-header border-0 p-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Hourly</span>
                        <span className='text-muted fw-semibold fs-7'>Entries in hour intervals</span>
                    </h3>
                 
                </div> */}
                <div className='card-body p-10 pb-0'>
                    <div className='d-flex flex-row'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 col-sm-6'>
                            <LocationData location={compareLocation1} user={compareUser1.value} />
                        </div>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 col-sm-6'>
                            <LocationData location={compareLocation2} user={compareUser2.value} />
                        </div>
                    </div>

                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.AUDIO_ALERTS)
                                setChartComparison(ComparisonEnum.AUDIO_ALERTS)
                            }
                            }
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (chartComparison === ComparisonEnum.AUDIO_ALERTS && 'active')
                                }
                                to=''
                            >
                                Audio alerts
                            </Link>
                        </li>
                        {/* <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.QUEUE_ALERTS)
                                setChartComparison(ComparisonEnum.QUEUE_ALERTS)
                            }
                            } className={
                                `nav-link text-active-primary me-6 ` +
                                (chartComparison === ComparisonEnum.QUEUE_ALERTS && 'active')
                            }
                                to=''
                            >
                                Queue alerts
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.AVG_TIME_CASH_REGISTER_OPEN)
                                setChartComparison(ComparisonEnum.AVG_TIME_CASH_REGISTER_OPEN)
                            }
                            } className={
                                `nav-link text-active-primary me-6 ` +
                                (chartComparison === ComparisonEnum.AVG_TIME_CASH_REGISTER_OPEN && 'active')
                            }
                                to=''
                            >
                                Avg response time
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.AVG_TIME_QUEUE)
                                setChartComparison(ComparisonEnum.AVG_TIME_QUEUE)
                            }
                            } className={
                                `nav-link text-active-primary me-6 ` +
                                (chartComparison === ComparisonEnum.AVG_TIME_QUEUE && 'active')
                            }
                                to=''
                            >
                                Avg queue duration
                            </Link>
                        </li> */}
                        {/* <li className='nav-item'>
                    <Link onClick={() => 
                            {callbackComparison(ComparisonEnum.IMPRESSIONS)
                            setChartComparison(ComparisonEnum.IMPRESSIONS)}
                        }                            className={
                                `nav-link text-active-primary me-6 ` +
                                (chartComparison === ComparisonEnum.IMPRESSIONS && 'active')
                            }
                            to=''
                        >
                            Impressions
                        </Link>
                    </li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export { ChartsWidget12 }
