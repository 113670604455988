/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import peopleCountingGender from '../../../web_api/methods/peopleCountingDash/genderReports'
import { genderResponse } from '../../../web_api/models'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import ApexCharts, { ApexOptions } from 'apexcharts'
import * as ReactBootstrap from 'react-bootstrap'
import LoadingPage from '../../../layout/LoadingPage'



type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  from: Date
  to: Date
}

const ChartsWidget4: FC<Props> = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  from,
  to
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [male, setMale] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [female, setFemale] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    loadGenderReports(from, to)
    // console.log(filter)
  }, [from, to])
  let loadGenderReports = async (from: Date, to: Date) => {
    try {
      let loadGender: genderResponse = await peopleCountingGender(from, to);
      setMale(loadGender.male)
      setFemale(loadGender.female)
      setTotal(loadGender.object_count)
      setLoading(true)
    }
    catch (e) {
      console.log(e)
    }
  };


  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    var chart = new ApexCharts(chartRef.current, chartOptions([male, female], total))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }

  }, [chartRef, total, male, female])

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{total}</span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Persons</span>
        </div>
      </div>

      <div className='card-body p-0 align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          {
            loading ?
              (<div ref={chartRef} id='kt_charts_widget_2_chart' />)
              :
              (<LoadingPage />)
          }
        </div>
      </div>
    </div>
  )
}
const chartOptions = (counts: number[], totalCount: number): ApexOptions => {
  return {
    chart: {
      type: 'donut',
      width: 300,
    },
    series: counts,
    labels: ['Male', 'Female'],
    colors: ['#008FFB', '#FF4560'],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: false,
              label: 'Total',
              formatter: () => totalCount.toString()
            }
          }
        }
      }
    }
  };
};
export { ChartsWidget4 }
