import callApiAxiosClient from "../../../axios/axios_client";
import { camerasList } from "../../../models";

type getCamerasParams = {
    id?: number,
    locationId?: number,
    pageIndex?: number,
    pageSize?: number,
}

async function getCameras(params?: getCamerasParams): Promise<camerasList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<camerasList>({
        method: "get",
        url: "settings/cameras",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            id: params?.id,
            location_id: params?.locationId,
            page_index: params?.pageIndex,
            page_size: params?.pageSize
        }
    })
}
export default getCameras;