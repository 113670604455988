import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  user_name: string
  access_token: string | null
  tenant_key: string
}
const initialState: UserState = {
  user_name: localStorage.getItem('user_name')!?.toString() != null ? localStorage.getItem('user_name')!?.toString() : '',
  access_token: localStorage.getItem('access_token')!?.toString() != null ? localStorage.getItem('access_token')!?.toString() : null,
  tenant_key: localStorage.getItem('tenant_key')!?.toString() != null ? localStorage.getItem('tenant_key')!?.toString() : '',
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<UserState>) => {
      state.user_name = action.payload.user_name
      state.access_token = action.payload.access_token
      state.tenant_key = action.payload.tenant_key
      localStorage.setItem("user_name", action.payload.user_name)
      localStorage.setItem("access_token", action.payload.access_token!)
      localStorage.setItem("tenant_key", action.payload.tenant_key)
    },
  },
});

export const { setValue } = user.actions;

export const userReducer = user.reducer;
