import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CommonFormDropdownField from "../Common/CommonFormDropdownField";
import { CommonModal } from "../Common/CommonModal"
import { useState } from "react";
import { toast } from "react-toastify";
import { detectionServerInList } from "../../web_api/models";
import addDetectionServer from "../../web_api/methods/settings/detectionServers/addDetectionServer";
import CommonFormField from "../Common/CommonFormField";

type DetectionServerAddFormModalProps = {
  handleToggleModal: () => void;
  show: boolean;
  onDetectionServerAdd: (detectionServer: detectionServerInList) => void;
}

const DetectionServerAddFormModal: React.FC<DetectionServerAddFormModalProps> = ({
  show,
  handleToggleModal,
  onDetectionServerAdd
}) => {

  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
  
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const [detectionServer, setDetectionServer] = useState({
    name: "",
    locationId: -1
  });

  const [isFormValid, setIsFormValid] = useState({
    name: "",
    locationId: ""
  });

  const resetFields = () => {
    setDetectionServer({
      name: "",
      locationId: -1
    });
    setIsFormValid({
      name: "",
      locationId: ""
    });
  };


  const handleAddDetectionServer = async () => {

    if (!detectionServer.name || detectionServer.locationId === -1) {
      setIsFormValid({
        name: detectionServer.name ? "is-valid" : "is-invalid",
        locationId: detectionServer.locationId !== -1 ? "is-valid" : "is-invalid",
      });
      return;
    };

    try {
      setIsAdding(true);
      let addedDetectionServer = await addDetectionServer(
        detectionServer.name,
        detectionServer.locationId
      );

      onDetectionServerAdd(addedDetectionServer)

      handleToggleModal();


      toast.success('Detection server added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        autoClose: 2000
      });

      setIsAdding(false);
      setTimeout(() => {
        resetFields()
      }, 500);
      
    } catch (e) {
      setIsAdding(false);
      setIsFormValid({
        name: '',
        locationId: ''
      })
      console.log(e);
      toast.error(`${e}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    };
  };

  return (
      <CommonModal
          title="Add Detection Server"
          size="lg"
          disabled={isAdding}
          show={show}
          showModalCallback={handleToggleModal}
          closeButton={true}
          confirmButton={true}
          bodyClass="flex-column w-100 ps-sm-15 pe-sm-15 pb-sm-10"
          closeButtonOptions={{
              icon: 'bi bi-x',
              variant: 'secondary',
          }}
          confirmButtonOptions={{
              icon: "bi bi-check-lg",
              variant: 'custom',
              class: "bg-primary text-white",
              onClick: handleAddDetectionServer
          }}
      >
        <CommonFormField
          inputClass={isFormValid.name}
          id="name"
          label="Name"
          placeholder="Server name"
          type="text"
          value={detectionServer.name}
          onChange={(e) => setDetectionServer({ ...detectionServer, name: (e.target.value) })}
          invalidFeedback=""
          feedbackClass="d-none"
        />
          <CommonFormDropdownField
            inputClass={isFormValid.locationId}
            label="Location"
            value={detectionServer.locationId}
            onChange={(e) => setDetectionServer({ ...detectionServer, locationId: parseInt(e.target.value) })}
          >
              <option value="" hidden>Select a location</option>
              {cachedLocationsList.map((location) => {
                  return <option key={location.id} value={location.id}>{location.name}</option>
              })}
          </CommonFormDropdownField>
      </CommonModal>
)
}

export default DetectionServerAddFormModal