import callApiAxiosClient from "../../../axios/axios_client";
import { cameraModelInList } from "../../../models";

interface addCameraModelArgs {
    name: string,
    command: string,
    command_local: string
}

async function addCameraModel({ name, command, command_local }: addCameraModelArgs): Promise<cameraModelInList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<cameraModelInList>({
        method: "post",
        url: "settings/add-camera-models",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            name: name,
            command: command,
            command_local: command_local
        }
    })
}
export default addCameraModel;