import axios, { AxiosRequestConfig } from "axios"

async function callApiAxiosClient<T>(apiCall: AxiosRequestConfig): Promise<T> {
    try {
        apiCall.url = `api/v1/${apiCall.url}`
        // apiCall.baseURL=document.location.origin
        apiCall.baseURL=process.env.REACT_APP_API_BUILD_URL
        var response = (await axios(apiCall)).data
        return Promise.resolve(response)
    } catch (error: any) {
        throw handleApiErrors(error);
    }
}

function handleApiErrors(error: any) {
    if (error.response) {
        let concatErrors: any = "Error received from API: ";
        let errorObj = error.response.data.errors;
        for (let keys in errorObj) {
            for (let i = 0; i < errorObj[keys].length; i++) {
                concatErrors += errorObj[keys][i] + "\n";
            }
        }
        throw concatErrors
    } else if (error.request) {

        throw error.request
    } else {

        // Something happened in setting up the request that triggered an Error
        throw error.request;
    }
}

export default callApiAxiosClient;