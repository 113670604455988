import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userLocationItemInList {
    id: number
    name: string
}
type userLocationList = userLocationItemInList[]

export interface userItemInList {
    id: string
    name: string
    role_id: number
    locations: userLocationList
}
export interface userListState {
    list: userItemInList[]
}
const initialState: userListState = {
    list: localStorage.getItem("userList") != null ? JSON.parse(localStorage.getItem("userList") ?? '') : []
};

export const userList = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<userListState>) => {
            state.list = action.payload.list
            localStorage.setItem("userList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setValue } = userList.actions;

export const userListReducer = userList.reducer;
