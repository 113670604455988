import { setCachedLocationList } from "../../redux/cachedLocationsReducer";


import getLocations from "../../web_api/methods/settings/locations/getLocations";
import useGet from "../../hooks/Common/useGet";
import { LeafMap } from "./LeafMap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";

const MapLocationWrapper = () => {


    useGet([], getLocations, setCachedLocationList);
    const [markers, setMarkers] = useState<{ id: number, position: { lat: number, long: number }, name: string }[]>([])
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
    useEffect(() => {
        let forMarkers = new Array()
        cachedLocationsList.list.map((location) => {
            forMarkers.push({ id: location.id, position: { lat: location.lat, long: location.long }, name: location.name })
        })
        setMarkers(forMarkers)
    }, [cachedLocationsList.list.length])
    return (
        <div style={{height: '93vh'}}>
            <LeafMap className={'h-100'} allowMultipleMarkers={true} allowDelete={true} markers={markers} allowAdd={false} />
        </div>
    );
};

export default MapLocationWrapper;