import { CommonCard } from "../Common/CommonCard/CommonCard";

type DetectionCardAddProps = {
    toggleModal: () => void;
}

const DetectionCardAdd: React.FC<DetectionCardAddProps> = ({
    toggleModal
}) => {
    return (
        <div className="animated zoomIn col-12 col-md-6 col-xxl-4 cursor-pointer" onClick={toggleModal}>
            <CommonCard
                containerClass={`my-3 h-min-300px animated ${window.innerWidth > 576 ? 'hover-grow' : ''} w-100`}
                background="linear-gradient(to bottom, #666666, #999999)"
                headingLarge="Add a new detection"
                headingSmall="&nbsp;"
                items={
                    <>
                        <div className="text-center p-4 mb-12 d-flex justify-content-center align-items-center">
                            <i className="bi bi-eye" style={{ fontSize: "9em" }}></i>
                        </div>
                    </>
                }
                buttons={
                    <>
                    </>
                }
            />
        </div>
    )
}

export default DetectionCardAdd