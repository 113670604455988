import CommonAnnotatedImage from "../../Common/CommonAnnotatedImage"
import placeholderImage from "../../../assets/images/placeholder-denmark.jpg";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { polygonObjectList } from "../../../web_api/models";
import useGet from "../../../hooks/Common/useGet";
import addDetectionRegion from "../../../web_api/methods/settings/detectionRegions/addDetectionRegion";
import getDetectionRegions from "../../../web_api/methods/settings/detectionRegions/getDetectionRegions";
import { ToastContainer, toast } from "react-toastify";
import deleteDetectionRegions from "../../../web_api/methods/settings/detectionRegions/deleteDetectionRegions";

const DetectionInfoAnnotation = () => {

    const params = useParams();
    const id = params.id;

    const { data, setData } = useGet([], () => getDetectionRegions(id));

    const [save, setSave] = useState(false);

    const saveChanges = async (currentPolygons: polygonObjectList) => {
        if (save && data) {

            const originalPolygons = [...data.polygons];

            const addedPolygons = currentPolygons.filter(
                (currentPolygon) => !originalPolygons.some((p) => p.id === currentPolygon.id)
            );

            const deletedPolygons = originalPolygons.filter(
                (originalPolygon) => !currentPolygons.some((p) => p.id === originalPolygon.id)
            );

            const deletedPolygonIds = deletedPolygons.map((polygon) => polygon.id);

            const updatedPolygons = currentPolygons.filter((currentPolygon) => {
                const originalPolygon = originalPolygons.find((p) => p.id === currentPolygon.id);
                if (!originalPolygon) {
                    return true;
                }
                const polygonChanged = originalPolygon.polygon !== currentPolygon.polygon;
                const bodyChanged = JSON.stringify(originalPolygon.body) !== JSON.stringify(currentPolygon.body);
                return polygonChanged || bodyChanged;
            });

            const errors: string[] = [];

            const handleAddAnnotation = async () => {
                try {
                    let addedDetectionRegion = await addDetectionRegion(id ? id : -1, addedPolygons);
                } catch (error) {
                    console.log(error);
                    errors.push(`${error}`);
                }
            };

            const handleDeleteAnnotation = async () => {
                try {
                    let deletedDetectionRegions = await deleteDetectionRegions(deletedPolygonIds);
                } catch (error) {
                    console.log(error);
                    errors.push(`${error}`);
                }
            };

            const handleUpdateAnnotation = async () => {
                try {
                    // API needed
                } catch (error) {
                    console.log(error);
                    errors.push(`${error}`);
                }
            };

            if (addedPolygons.length > 0) {
                await handleAddAnnotation();
            };

            if (deletedPolygons.length > 0) {
                await handleDeleteAnnotation();
            };

            if (updatedPolygons.length > 0) {
                await handleUpdateAnnotation();
            };

            if (errors.length > 0) {
                errors.forEach((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored",
                        autoClose: 2000,
                    });
                });
            } else if(addedPolygons.length > 0 || deletedPolygons.length > 0 || updatedPolygons.length > 0) {
                toast.success("Changes saved successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored",
                    autoClose: 2000,
                });
            }
            setData({
                detection_setting_id: Number(id),
                polygons: currentPolygons
            })
            setSave(false);
        }
    };



    return (
        <>
            <div className="d-flex mb-2 gap-3 justify-content-start py-3 px-3 px-sm-8">
                <Link to={`${process.env.REACT_APP_HOMEPAGE}/detections/${id}`}>
                    <button className="btn btn-primary">
                        <i className="bi bi-arrow-return-left"></i>
                        Return
                    </button>
                </Link>
                <button className="btn btn-success" onClick={() => setSave(true)}>
                    <i className="bi bi-check-lg"></i>
                    Save Annotation
                </button>
            </div>
            {data && (
                <div className="d-flex justify-content-center">
                    <div className="bg-body p-2 rounded" style={{maxWidth: '75%'}}>
                        <CommonAnnotatedImage
                            img={placeholderImage}
                            className="rounded img-fluid"
                            saveChanges={saveChanges}
                            data={data.polygons}
                            save={save}
                        />
                    </div>
                </div>
            )}
            <ToastContainer />
        </>
    )
}

export default DetectionInfoAnnotation;