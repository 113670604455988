import { setCachedLocationList } from "../../redux/cachedLocationsReducer";


import useGet from "../../hooks/Common/useGet";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { LeafMap } from "../Map/LeafMap";
import getCameras from "../../web_api/methods/settings/cameras/getCameras";
import { setCachedCameraList } from "../../redux/camerasListReducer";

const MapCameraWrapper = () => {

    useGet([], getCameras, setCachedCameraList);
    const [markers, setMarkers] = useState<{ id: number, position: { lat: number, long: number }, name: string }[]>([])
    const camerasList = useSelector((state: RootState) => state.camerasList);
    useEffect(() => {
        let forMarkers = new Array()
        camerasList.list.map((camera) => {
            forMarkers.push({ id: camera.id, position: { lat: camera.lat, long: camera.long }, name: camera.name })
        })
        setMarkers(forMarkers)
    }, [camerasList.list.length])
    return (
        <div style={{ height: '93vh'}}> 
            <LeafMap className={'h-100'} allowMultipleMarkers={true} allowDelete={true} markers={markers} allowAdd={false} />            
        </div>
    );
};

export default MapCameraWrapper;