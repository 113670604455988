import * as ReactBootstrap from 'react-bootstrap'
import { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils'
import { exportCSV, numberFormat } from '../../../web_api/methods/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import LoadingPage from '../../../layout/LoadingPage'



type Props = {
  className: string
  loading:boolean
  xAxis: string[]
  yAxis: number[]

}


const ChartsWidget13: React.FC<Props> = ({ className, loading, xAxis, yAxis }) => {
  
  const chartRef = useRef<HTMLDivElement | null>(null)
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const audioAlerts = useSelector((state: RootState) => state.audioAlerts.value);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);




  function getChartOptions(height: number, xAxis: string[], yAxis: number[]): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = '#f0416c'
    const secondaryColor = getCSSVariableValue('--kt-gray-300')
    return {
      series: [
        {
          name: 'Audio alerts',
          data: yAxis,
        }
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
        },
        // events: {
        //   dataPointSelection: function (event, chartContext, config) {
        //     console.log(chartContext.w.globals.labels[config.dataPointIndex]);
        //     // dispatch(setValue({
        //     //   from: new Date(chartContext.w.globals.labels[config.dataPointIndex]).toISOString(),
        //     //   to: new Date(chartContext.w.globals.labels[config.dataPointIndex]).toISOString()
        //     // }))
        //   }
        // }
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: xAxis,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: '12px',
            fontWeight: 700
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
            fontWeight: 700
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return numberFormat(val)
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true
          }
        }
      },
    }
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, xAxis, yAxis))
    if (chart) {
      chart.render()
    }

    return chart
  }

  const handleDownload = () => {
    exportCSV([['Location', 'Audio alerts','test']], xAxis, yAxis, 'Audio alerts - ' +
      ((dateFilter.from === dateFilter.to) ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
        :
        new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
        + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    )
  };

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, xAxis, yAxis, loading])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Audio alerts {(activeLocation.id != "0" && ' - ' + activeLocation.value)}</span>
          <span className='text-muted fw-semibold fs-7'>Total of <span className='fw-bold text-dark'>{audioAlerts}</span> audio alerts sent </span>
        </h3>
        <div className='flex-shrink-0 my-auto'>
          <ul className='nav' onClick={handleDownload}>
            <li className='nav-item m-auto'>
              <div className={'nav-link btn btn-sm btn-color-dark btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Download'>
                <i className="bi bi-save p-0 mb-1" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body pt-0'>
        {
          loading ?
            (<div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '100%' }} />)
            :
            (<LoadingPage />)
        }
      </div>
    </div>
  )
}

export { ChartsWidget13 }
