import React, { ChangeEventHandler } from "react";

type CommonFormDropdownFieldProps = {
    containerClass?: string;
    labelClass?: string;
    inputClass?: string;
    feedbackClass?: string;
    invalidFeedback?: string;
    label: string;
    value: string | number;
    onChange: ChangeEventHandler<HTMLSelectElement>;
    children: React.ReactNode
}

const CommonFormDropdownField: React.FC<CommonFormDropdownFieldProps> = ({
    containerClass,
    labelClass,
    inputClass,
    feedbackClass,
    invalidFeedback,
    label,
    value,
    onChange,
    children
}) => {

    const isInvalid = inputClass?.includes("is-invalid");
    const feedbackVisibilityClass = isInvalid ? "" : "invisible";

    return(
        <div className={`${containerClass} fv-row d-flex flex-wrap `}>
            <label className={`form-label fs-6 fw-bolder text-dark mb-2 ${labelClass}`} htmlFor='camera-model'>{label}</label>
            <select
                className={`form-select fs-6 fw-bolder mb-2 ${isInvalid ? "border-danger" : ""}`}
                value={value}
                onChange={onChange}
            >
                {children}
            </select>
            <div className={`text-danger text-start ms-3 mt-1 fs-9 ${feedbackClass} ${feedbackVisibilityClass}`}>
                {invalidFeedback}
            </div>
        </div>
    );
}

export default CommonFormDropdownField;
