import callApiAxiosClient from "../../../axios/axios_client";
import { detectionServerInList } from "../../../models";

interface updateDetectionServerArgs {
    id: number,
    name: string,
    locationId: number
}

async function updateDetectionServer({ id, name, locationId }: updateDetectionServerArgs): Promise<detectionServerInList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<detectionServerInList>({
        method: "put",
        url: "settings/modify-detection-servers",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            id: id,
            name: name,
            location_id: locationId
        }
    })
}
export default updateDetectionServer;