import callApiAxiosClient from "../../../axios/axios_client";
import { cameraDetectionInList } from "../../../models";

interface UpdateDetectionArgs {
    id: number,
    min_nr_objects: number,
    max_nr_objects: number,
    nr_occurences: number,
    processing_window: number,
    threshold: number,
    fps: number,
    region_name: number,
    detection_server_id: number
};



async function updateDetection({ id, min_nr_objects, max_nr_objects, nr_occurences, processing_window, threshold, fps, region_name, detection_server_id }: UpdateDetectionArgs): Promise<cameraDetectionInList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<cameraDetectionInList>({
        method: "put",
        url: "settings/put-detection-settings",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            id: id,
            min_nr_objects: min_nr_objects,
            max_nr_objects: max_nr_objects,
            nr_occurences: nr_occurences,
            processing_window: processing_window,
            threshold: threshold,
            fps: fps,
            region_name: region_name,
            detection_server_id: detection_server_id
        }
    })
}
export default updateDetection;