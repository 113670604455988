import { ChangeEvent, ChangeEventHandler } from "react";

type CommonSettingsFieldProps = {
  label: string;
  type: string;
  placeholder?: string;
  containerClass?: string;
  labelClass?: string;
  inputClass?: string;
  feedbackClass?: string;
  id: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  invalidFeedback: string;
  children?: React.ReactNode
};

const CommonSettingsField: React.FC<CommonSettingsFieldProps> = ({
  label,
  type,
  placeholder,
  containerClass,
  labelClass,
  inputClass,
  feedbackClass,
  id,
  value,
  onChange,
  invalidFeedback,
  children
}) => {
  const isInvalid = inputClass?.includes("is-invalid");
  const feedbackVisibilityClass = isInvalid ? "" : "invisible";

  return (
    <div className={`row mb-6 ${containerClass}`}>
      <label className={`col-lg-4 col-form-label required fw-bold fs-6 ${labelClass}`}>
        {label}
      </label>
      <div className="col-lg-8 fv-row">
        {children ? (
          <>
            <select
              value={value}
              id={id}
              className={`form-select form-select-solid form-select-lg ${inputClass}`}
              onChange={onChange}
            >
                {children}
            </select>
            <div
              className={`text-danger text-start ms-3 mt-1 fs-9 ${feedbackClass} ${feedbackVisibilityClass}`}
            >
              {invalidFeedback}
            </div>
          </>
        ) : (
          <>
            <input
              id={id}
              value={value}
              type={type}
              onChange={onChange}
              className={`form-control form-control-lg form-control-solid ${inputClass}`}
              placeholder={placeholder}
            />
            <div
              className={`text-danger text-start ms-3 mt-1 fs-9 ${feedbackClass} ${feedbackVisibilityClass}`}
            >
              {invalidFeedback}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CommonSettingsField;
