import callApiAxiosClient from "../../../axios/axios_client";

type updateLocationArgs = {
    id: number,
    name?: string,
    lat?: number,
    lng?: number
}

async function updateLocation({ id, name, lat, lng }: updateLocationArgs){
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient({
        method: "put",
        url: "settings/locations",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            id: id,
            name: name,
            lat: lat,
            lng: lng
        }
    })
}
export default updateLocation;