import { toAbsoluteUrl } from "../_metronic/helpers";
import { ChartsWidget10 } from "../_metronic/widgets/charts/ChartsWidget10";
import { MixedCharts1 } from "../_metronic/widgets/charts/MixedCharts1";
import MixedCharts2 from "../_metronic/widgets/charts/MixedCharts2";
import { DataWidgetWithImage } from "../_metronic/widgets/data_widgets/DataWidgetWithImage";
import { MonthlyReportsPeopleCounting } from "./PeopleCounting/MonthlyReportsPeopleCounting";
import { WeeklyReportsPeopleCounting } from "./PeopleCounting/WeeklyReportsPeopleCounting";

export default function Home() {
    return (
        <div className="container">
            <div className="d-flex flex-column flex-xxl-row">
                <div className="d-flex flex-column col-xxl-6 p-0">
                    <div className="d-flex flex-column flex-sm-row w-sm-100 h-100">
                        <div className="p-5 w-sm-50">
                            <WeeklyReportsPeopleCounting className='h-sm-100 mb-xl-5 animated zoomIn' />
                        </div>
                        <div className="p-5 w-sm-50">
                            <MonthlyReportsPeopleCounting className='h-sm-100 mb-xl-5 animated zoomIn' />
                        </div>
                    </div>
                    <div className="d-flex flex-row w-sm-100 h-100">
                        <div className="p-5 w-sm-100 w-100">
                            <DataWidgetWithImage
                                className='h-md-100 mb-xl-5 animated zoomIn'
                                // color='#F1416C'
                                color='cornflowerblue'
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex col-xxl-6 p-5">
                    <MixedCharts1
                        className='w-sm-100 w-100 animated zoomIn'
                    />
                </div>
            </div>
            <div className="d-flex flex-column flex-xxl-row">
                <div className="d-flex flex-column col-xxl-6 p-5">
                    <ChartsWidget10 className='h-100 animated zoomIn' />
                </div>
                <div className="d-flex col-xxl-6 flex-column p-5 animated zoomIn">
                    <MixedCharts2 />
                </div>
            </div>
        </div>
    )
}