import React, { useEffect, useState } from 'react'
import { DayWeekMonthYearEnum, evolutionValueList } from '../../web_api/models';
import { AlertsNumberChart } from './AlertsNumberChart';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import peopleCountingEvolutionValue from '../../web_api/methods/peopleCountingDash/evolutionValue';
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers';
type Props = {
    className?: string
    filter: string
};

const AlertsNumber: React.FC<Props> = ({ className, filter }) => {
    const dayWeekMonthYear = convertStringToEnumValue(filter)//dependency
    const activeLocation = useSelector((state: RootState) => state.activeLocation)
    const selectedUser = useSelector((state: RootState) => state.selectedUser)
    //dependencies
    const [barOrArea, setBarOrArea] = useState<boolean>(false)
    const [values, setValues] = useState<number[]>([])
    const [valuesPrev, setValuesPrev] = useState<number[]>([])
    const [dates, setDates] = useState<string[]>([])
    const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        loadEvolutionValues(dayWeekMonthYear)
    }, [dayWeekMonthYear, activeLocation, selectedUser])

    let loadEvolutionValues = async (filter: DayWeekMonthYearEnum) => {
        try {
            setLoaded(false)
            let evolutionValues: evolutionValueList = await peopleCountingEvolutionValue(filter);
            let forValues = new Array()
            let forValuesPrev = new Array()
            let forDate = new Array()

            evolutionValues.forEach((item) => {
                forValues.push(item.object_count)
                forValuesPrev.push(item.object_count + 123)

                if (filter === DayWeekMonthYearEnum.YEAR)
                    forDate.push(monthNames[parseInt(item.date.slice(5, 7)) - 1])
                else if (filter === DayWeekMonthYearEnum.MONTH)
                    forDate.push(new Date(item.date).toLocaleString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    }).replace(/\//g, '-'))
                else if (filter === DayWeekMonthYearEnum.WEEK)
                    forDate.push(dayNames[new Date(item.date).getDay() - 1])
                else if (filter === DayWeekMonthYearEnum.DAY)
                    forDate.push(item.date.slice(item.date.length - 3, item.date.length) + ':00')

            })
            setValues(forValues)
            setValuesPrev(forValuesPrev)
            setDates(forDate)
            setLoaded(true)
        }
        catch (e) {
            console.log(e)
        }
    };
    return (
        <div className={`card h-100 ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Number of alerts</span>
                    <span className='text-muted fw-semibold fs-7'>Current and last {dayWeekMonthYear}</span>
                </h3>
                <div className='card-title btn btn-light-primary btn-icon btn-sm' onClick={() => setBarOrArea(!barOrArea)}><i className={`bi-${!barOrArea ? 'bar-chart' : 'graph-down'}`} /></div>
            </div>
            <div className='card-body p-0'>
                <AlertsNumberChart
                    type={barOrArea ? 'bar' : 'area'}
                    className='w-sm-100'
                    chartColor='danger'
                    chartSecondaryColor='info'
                    chartHeight='150px'
                    filter={dayWeekMonthYear}
                    dates={dates}
                    values={values}
                    valuesPrev={valuesPrev}
                    loaded={loaded} />
            </div>
        </div>
    )
}
export { AlertsNumber }