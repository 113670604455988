import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import deleteDetection from "../../web_api/methods/settings/detections/deleteDetections";
import { CommonModal } from "../Common/CommonModal";
import useDelete from "../../hooks/Common/useDelete";
import useModals from "../../hooks/Common/useModals";


interface DetectionMassDelete {
    checkedDetections: number[];
    onShowMassDeleteModal: (detectionIds: number[]) => { [key: string]: string };
    onDetectionMassDelete: () => void;
}

const DetectionMassDelete: React.FC<DetectionMassDelete> = ({
    checkedDetections,
    onShowMassDeleteModal,
    onDetectionMassDelete
}) => {

    const { show, toggleModal } = useModals([false]);

    const [names, setNames] = useState({});

    const handleToggleDeleteModal = () => {
        toggleModal(0);
        const names = onShowMassDeleteModal(checkedDetections);
        setNames(names);
    };

    const { deleteItems: handleMassDelete, errors, isDeleting } = useDelete(
        () => deleteDetection(checkedDetections),
        () => {
            toggleModal(0);
            onDetectionMassDelete();
            toast.success('Detections deleted successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
    );

    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    }, [errors?.length])


    return (
        <>
            <div className={`d-flex flex-column flex-md-row p-1 flex-end ${checkedDetections.length > 0 ? 'visible' : 'invisible'}`}>
                <div className='btn btn-danger' onClick={handleToggleDeleteModal}>Delete detections</div>
            </div>
            <CommonModal
                title={"Confirm"}
                show={show[0]}
                disabled={isDeleting}
                showModalCallback={() => toggleModal(0)}
                closeButton={true}
                confirmButton={true}
                bodyClass="flex-column w-100"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: "bi bi-trash3 text-white",
                    variant: 'custom',
                    class: "bg-danger text-white",
                    onClick: handleMassDelete
                }}
            >
                <div>Are you sure you want to delete the following detections?</div>
                {Object.entries(names).map(([key, value]) => (
                    <div key={key} className="fw-bolder mt-3">
                        {key} - {value as string}
                    </div>
                )).reverse()}

            </CommonModal>
        </>
    )
}

export default DetectionMassDelete