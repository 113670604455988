import callApiAxiosClient from "../../../axios/axios_client";
import { addCameraResponse } from "../../../models";

async function addCamera(name: string, ip_address: string, port: string, user: string, password: string, model_id: number, camera_location_id: number): Promise<addCameraResponse> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<addCameraResponse>({
        method: "post",
        url: "settings/cameras",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            name: name,
            ip_address: ip_address,
            port: port, 
            user: user,
            password: password,
            model_id: model_id,
            camera_location_id: camera_location_id
        }
    })
}
export default addCamera;