import { RefObject, useRef } from "react";

type CommonPaginationProps = {
    handlePrevChange: () => void,
    handleNextChange: () => void,
    disablePrevious: boolean,
    disableNext: boolean,
    startIndex: number
    containerClass?: string
}

const CommonPagination: React.FC<CommonPaginationProps> = ({
    handlePrevChange,
    handleNextChange,
    disablePrevious,
    disableNext,
    startIndex,
    containerClass
}) => {

    const prevButtonRef = useRef<HTMLButtonElement>(null)
    const nextButtonRef = useRef<HTMLButtonElement>(null)


    const handleClickSpam = (ref: RefObject<HTMLButtonElement>) => {
        if (ref.current) {
            ref.current.disabled = true;
        };
    };

    return (
        <div className={`d-flex p-1 flex-end ${containerClass}`}>
            <button className='btn btn-primary' ref={prevButtonRef} disabled={disablePrevious} onClick={() => { handleClickSpam(prevButtonRef); handlePrevChange()}}>
                <i className="bi bi-chevron-double-left p-0" /></button>
            <button className='btn btn-secondary' disabled>{startIndex}</button>
            <button className='btn btn-primary' ref={nextButtonRef} disabled={disableNext} onClick={() => { handleClickSpam(nextButtonRef); handleNextChange()}}>
                <i className="bi bi-chevron-double-right p-0" />
            </button>
        </div>
    )
}

export default CommonPagination;