import callApiAxiosClient from "../../axios/axios_client";
import { ageResponseList } from "../../models";
import moment from "moment";

async function peopleCountingAge(fromParam: Date, toParam: Date): Promise<ageResponseList> {
    const token = JSON.parse(localStorage.getItem('user')!)

    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<ageResponseList>({
        method: "get",
        url: "programmability/age",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            from: from,
            to: to
        }
    })
}
export default peopleCountingAge;