import { Link, useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useDispatch } from 'react-redux';
import logo from '../../../assets/images/logo_c2i.svg'
import camera from '../../../assets/images/png-clipart-gray-and-black-cctv-camera-illustration-surveillance-video-camera-icon-realistic-silver-webcam-angle-electronics.png'
import registerUser from "../../../web_api/methods/account/registerUser";
import FormField from "../../Common/CommonFormField";
import loginWeb from '../../../web_api/methods/account/login';
import { loginResBodySuccess } from 'common/api/services/authService/models';
import { setValue } from '../../../redux/userReducer';
import { setValue as setIsValid } from '../../../redux/isValidReducer';
import getDetectionTypes from '../../../web_api/methods/settings/detections/getDetectionTypes';
import { setCachedDetectionTypeList } from '../../../redux/detectionTypeListReducer';
import * as ReactBootstrap from 'react-bootstrap';

const Register = () => {

    const countryOptions = [
        'Romania',
        'United States',
        'Canada',
        'United Kingdom',
        'Australia',
        'Germany',
        'France',
        'Italy',
        'Spain',
        'Netherlands',
        'Sweden',
        'Norway',
        'Denmark',
        'Finland',
        'Switzerland',
        'Belgium',
        'Austria',
        'Ireland',
        'New Zealand',
        'Japan',
        'South Korea',
        'China',
        'India',
        'Brazil',
        'Mexico',
        'Argentina',
    ];

    const [userData, setUserData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        country: "Romania",
        company: ""
    });

    const [isFormValid, setIsFormValid] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        company: ""
    });

    const [isAdding, setIsAdding] = useState<boolean>(false);

    const [apiErrors, setApiErrors] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailRegex.test(userData.email);
        return isEmailValid;
    };

    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isPasswordValid = passwordRegex.test(userData.password);
        return isPasswordValid;
    };

    const validateConfirmPassword = () => {
        const isConfirmPasswordValid = userData.password === userData.confirmPassword && userData.password;
        return isConfirmPasswordValid;
    };

    const validateCompanyName = () => {
        const isCompanyValid = userData.company.length;
        return isCompanyValid;
    };

    const resetValidation = () => {
        setIsFormValid({
            email: "",
            password: "",
            confirmPassword: "",
            company: ""
        })
    };

    const handleRegisterSubmit = async () => {
        setApiErrors('');
        const isEmailValid = validateEmail();
        const isPasswordValid = validatePassword();
        const isConfirmPasswordValid = validateConfirmPassword();
        const isCompanyValid = validateCompanyName();

        if (!isEmailValid || !isPasswordValid || !isConfirmPasswordValid || !isCompanyValid) {
            setIsFormValid({
                email: isEmailValid ? 'is-valid' : 'is-invalid',
                password: isPasswordValid ? 'is-valid' : 'is-invalid',
                confirmPassword: isConfirmPasswordValid ? 'is-valid' : 'is-invalid',
                company: isCompanyValid ? 'is-valid' : 'is-invalid',
            });
            return;
        };

        try {
            setIsAdding(true);
            resetValidation();
            await registerUser(
                userData.email,
                userData.password,
                userData.confirmPassword,
                userData.company,
                userData.country
            );

            loginWeb(userData.email, userData.password)
                .then(async (loginResponse: loginResBodySuccess) => {
                    localStorage.setItem("user", JSON.stringify(loginResponse.access_token))
                    localStorage.setItem("user_name", JSON.stringify(loginResponse.user_name))
                    localStorage.setItem("is_valid", JSON.stringify(loginResponse.is_valid))
                    localStorage.setItem("tenant_key", JSON.stringify(loginResponse.tenant_key))
                    localStorage.setItem("toolbar", 'people-counting')
                    dispatch(setValue({
                        user_name: loginResponse.user_name,
                        access_token: loginResponse.access_token.token,
                        tenant_key: loginResponse.tenant_key
                    }))
                    dispatch(setIsValid({
                        is_valid: loginResponse.is_valid
                    }))
                    if (loginResponse) {
                        const detectionTypes = await getDetectionTypes();
                        dispatch(setCachedDetectionTypeList({
                            list: detectionTypes
                        }));
                    }
                    window.location.href = process.env.REACT_APP_HOMEPAGE + '/home';
                    setIsAdding(false);
                })
        } catch (e) {
            setIsAdding(false);
            resetValidation();
            console.log(e);
            setApiErrors(e as string);
        };
    };

    return (
        <>
            <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
                <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
                    <div className='d-flex flex-center flex-column flex-lg-row-fluid' >
                        <div className='w-lg-500px p-10 bg-body shadow'>
                            <div className='text-center mb-15'>
                                <h1 className='text-dark fw-bolder'>
                                    C2I
                                </h1>
                                <h2 className='text-gray-500 fw-semibold fs-6'>
                                    Efficiency
                                    through
                                    artificial intelligence
                                </h2>
                            </div>
                            <div className='text-center'>
                                <form onKeyDown={(e) => {
                                    if (e.key === "Enter" && !isAdding) {
                                        handleRegisterSubmit();
                                    }
                                }}>
                                    <FormField
                                        inputClass={isFormValid.email}
                                        id="email"
                                        label="Email"
                                        placeholder="Email"
                                        type="email"
                                        value={userData.email}
                                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                        invalidFeedback="Please provide a valid email address."
                                        feedbackClass='mb-2'
                                    />
                                    <FormField
                                        inputClass={isFormValid.password}
                                        id="password"
                                        label="Password"
                                        placeholder="Password"
                                        type="password"
                                        value={userData.password}
                                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                        invalidFeedback="One lowercase letter, one uppercase letter, one digit, and one special character required."
                                        feedbackClass='mb-2'
                                    />
                                    <FormField
                                        inputClass={isFormValid.confirmPassword}
                                        id="confirmPass"
                                        label="Confirm Password"
                                        placeholder="Re-enter password"
                                        type="password"
                                        value={userData.confirmPassword}
                                        onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                                        invalidFeedback=""
                                    />
                                    <div className="d-flex flex-row w-100 gap-5">
                                        <FormField
                                            containerClass="w-50 h-100"
                                            inputClass={isFormValid.company}
                                            id="company"
                                            label="Company Name"
                                            placeholder="Company"
                                            type="text"
                                            value={userData.company}
                                            onChange={(e) => setUserData({ ...userData, company: e.target.value })}
                                            invalidFeedback=""
                                        />
                                        <div className='fv-row m d-flex flex-wrap mb-6 w-50'>
                                            <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='country'>Country</label>
                                            <select className='form-select bg-transparent border' value={userData.country} onChange={(e) => setUserData({ ...userData, country: e.target.value })}>
                                                {countryOptions &&
                                                    countryOptions.map((country: string) => (
                                                        <option key={country} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={`text-danger text-start ms-3 mt-1 fs-9`}>
                                        {apiErrors}
                                    </div>
                                    <button className='btn btn-primary w-100 mt-5' disabled={isAdding} type={'button'} onClick={handleRegisterSubmit}>
                                        Sign Up
                                        {isAdding &&
                                            <ReactBootstrap.Spinner animation='border' size='sm' className='ms-2' />}
                                    </button>
                                    <div className='text-gray-500 text-center fw-semibold fs-6 mt-5'>Already a member? &nbsp;
                                        <Link className='link-primary cursor-pointer' to={process.env.REACT_APP_HOMEPAGE + '/login'}>Log in</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
                    style={{
                        backgroundImage: `url("https://t4.ftcdn.net/jpg/04/67/96/13/360_F_467961350_LlpfNFYVGUwkofWQzB4uptbSxl12rWps.jpg")`,
                        minHeight: "100vh"
                    }}>
                    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                        <div className='w-lg-500px p-10'>
                            <div className='text-center mb-15'>
                                <div className='mb-10' >
                                    <img src={logo} />
                                    <img src={camera} className='w-100  mt-10' />
                                </div>
                                <div className='text-center mb-15'>
                                    <h1 className='text-white fw-bolder fs-2qx mb-5'>
                                        C2I
                                    </h1>
                                    <h2 className='text-white fs-base'>The C2I video surveillance software is designed to work in various environments and to be easily adapted to different scenarios.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;