import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../redux/store";
import { setCachedLocationList } from "../../redux/cachedLocationsReducer";
import { CommonModal } from "../Common/CommonModal";
import addLocation from "../../web_api/methods/settings/locations/addLocation";
import updateLocation from "../../web_api/methods/settings/locations/updateLocation";
import FormField from "../Common/CommonFormField";
import { toast } from "react-toastify";
import usePut from "../../hooks/Common/usePut";
import { LeafMap } from "../Map/LeafMap";

type LocationsFormModalProps = {
    onModalToggle: () => void;
    show: boolean;
    currentLocation: {
        id: number;
        name: string;
        lat: number;
        long: number;
    };
};

const LocationsFormModal: React.FC<LocationsFormModalProps> = ({
    onModalToggle,
    show,
    currentLocation,
}) => {

    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { isFormValid, setIsFormValid, data, setData, handleUpdateData, errors, isUpdating } = usePut(
        {
            name: currentLocation.name,
            lat: currentLocation.lat,
            long: currentLocation.long
        },
        () =>
            updateLocation({
                id: currentLocation.id ? currentLocation.id : 0,
                name: data.name,
                lat: data.lat,
                lng: data.long
            }),
        setCachedLocationList, cachedLocationsList.list, 'id', currentLocation.id,
        () => {
            onModalToggle();
            toast.success(`Location updated successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
    );

    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    }, [errors?.length]);

    const resetFields = () => {
        setData({
            name: "",
            lat: 0,
            long: 0
        });
        setIsFormValid({
            name: '',
            lat: '',
            long: ''
        })
    };

    const handleAddLocation = async () => {
        if (!data.name || !data.lat || !data.long) {
            setIsFormValid({
                name: !data.name ? "is-invalid" : "is-valid",
                lat: !data.lat ? "is-invalid" : "is-valid",
                long: !data.long ? "is-invalid" : "is-valid",
            });
            return;
        }
        try {
            setIsFormValid({
                name: '',
                lat: '',
                long: ''
            });
            setIsAdding(true);
            let locationToAdd = await addLocation({
                name: data.name,
                lat: data.lat,
                long: data.long
            });

            const updatedList = [...cachedLocationsList.list];

            updatedList.unshift({
                name: data.name,
                lat: data.lat,
                long: data.long,
                id: locationToAdd.id,
                location_type: null,
                city_id: 0,
                type: null,
                tenant_id: 26
            });
            dispatch(setCachedLocationList({ list: updatedList }));
            onModalToggle();
            toast.success(`Location added successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });

        } catch (e) {
            console.log(e);
            toast.error(`${e}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        } finally {
            setIsAdding(false);
            setTimeout(() => resetFields(), 500)
        };
    };

    const handleUpdateDataFromMarker = (name: string, lat: number, long: number) => {
        setData({
            name: name,
            lat: lat,
            long: long
        });
    }

    useEffect(() => {
        if (currentLocation.id) {
            setData({
                name: currentLocation.name,
                lat: currentLocation.lat,
                long: currentLocation.long
            });
        } else {
            setData({
                name: "",
                lat: 0,
                long: 0
            });
        }
    }, [currentLocation.id]);

    return (
        <div>
            <CommonModal
                title={currentLocation.id ? "Edit the location" : "Add a location"}
                size="lg"
                show={show}
                showModalCallback={onModalToggle}
                closeButton={true}
                confirmButton={true}
                disabled={currentLocation.id ? isUpdating : isAdding}
                bodyClass="flex-column w-100 ps-15 pe-15 pb-10"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: 'bi bi-check-lg',
                    variant: 'custom',
                    class: 'bg-primary text-white',
                    onClick: currentLocation.id ? handleUpdateData : handleAddLocation
                }}
            >
                <FormField
                    inputClass={isFormValid.name}
                    id="name"
                    label="Name"
                    placeholder="Location name"
                    type="text"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: (e.target.value) })}
                    invalidFeedback=""
                    feedbackClass="d-none"
                />
                <i className="text-muted">Please fill the Name field before placing the marker on the map. You can move it afterwards</i>
                <div className="d-flex flex-row">
                    <FormField
                        inputClass={isFormValid.lat}
                        id="lat"
                        label="Latitude"
                        placeholder="Latitude"
                        type="number"
                        value={data.lat}
                        onChange={(e) => setData({ ...data, lat: parseFloat(e.target.value) })}
                        invalidFeedback=""
                        feedbackClass="d-none"
                        containerClass="mt-2 col-5"
                    />
                    <FormField
                        inputClass={isFormValid.long}
                        id="long"
                        label="Longitude"
                        placeholder="Longitude"
                        type="number"
                        value={data.long}
                        onChange={(e) => setData({ ...data, long: parseFloat(e.target.value) })}
                        invalidFeedback=""
                        feedbackClass="d-none"
                        containerClass="mt-2 offset-2 col-5 "
                    />
                </div>
                <LeafMap
                    className="h-300px mt-5"
                    data={data}
                    currentMarker={currentLocation.id !== 0 ?
                        [{
                            id: currentLocation.id,
                            position: { lat: currentLocation.lat, long: currentLocation.long },
                            name: currentLocation.name
                        }] : undefined}
                    markers={[]}
                    allowAdd={true}
                    onMarkerAddedOrChanged={handleUpdateDataFromMarker} />
            </CommonModal>
        </div>
    );
}

export default LocationsFormModal;