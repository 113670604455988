import callApiAxiosClient from "../../axios/axios_client";
import { entriesLocationsList } from "../../models";
import moment from "moment";


function peopleCountingEntriesLocations(fromParam: Date, toParam: Date): Promise<entriesLocationsList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<entriesLocationsList>({
        method: "get",
        url: "programmability/entries-locations",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params:{
            from: from,
            to: to
        }
    })
}
export default peopleCountingEntriesLocations;