/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import peopleCountingAge from '../../../web_api/methods/peopleCountingDash/ageReports'
import { ageResponseList } from '../../../web_api/models'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import { KTSVG } from '../../helpers'
import ApexCharts, { ApexOptions } from 'apexcharts'
import * as ReactBootstrap from 'react-bootstrap'
import LoadingPage from '../../../layout/LoadingPage'


type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  from: Date
  to: Date
}

const ChartsWidget11: FC<Props> = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  from,
  to
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [count, setCount] = useState<number[]>([])
  const [max, setMax] = useState<number>(0)
  const [age, setAge] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    loadAgeReports(from, to)
  }, [from, to])

  let loadAgeReports = async (from: Date, to: Date) => {
    try {
      let loadAge: ageResponseList = await peopleCountingAge(from, to);
      let forCount = new Array()
      let pMax = 0
      let forAge = new Array()
      loadAge.forEach((item) => {
        forCount.push(item.object_count)
        pMax += item.object_count
        forAge.push(`${item.age} yrs`)
      })
      setAge(forAge)
      setCount(forCount)
      setMax(pMax)
      setLoading(true)
    }
    catch (e) {
      console.log(e)
    }
  };

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(age, count, max))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }

  }, [chartRef, age, count])

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5 '>
        <div className='card-title d-flex flex-column invisible'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{max}</span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Persons</span>
        </div>
      </div>

      <div className='card-body p-0 align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          {
            loading ?
              (<div ref={chartRef} id='kt_charts_widget_1_chart' />)
              :
              (<LoadingPage />)
          }
        </div>

        {/* <div className='d-flex flex-row content-justify-center flex-row-fluid'>
          {age.map((item, index) => (
            <div key={index} className='d-flex flex-column fw-semibold align-items-center my-3'>
              <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
              <div className='text-gray-500 me-4'>{age[index]}</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{count[index]}</div>
            </div>
          )
          )}

        </div> */}
      </div>
    </div>
  )
}

const chartOptions = (ageGroups: string[], counts: number[], totalCount: number): ApexOptions => {
  return {
    chart: {
      type: 'donut',
      width: 300,
    },
    series: counts,
    labels: ageGroups,
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#4CAF50'], // You can modify this to your liking
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: false,
              label: 'Total',
              formatter: () => totalCount.toString()
            }
          }
        }
      }
    }
  };
};
export { ChartsWidget11 }
