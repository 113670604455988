type CommonReportProps = {
    children?: React.ReactNode,
    leftTitle?: string,
    centerTitle?: string,
    currentTime?: string,
    prevOnClick?: () => void,
    nextOnClick?: () => void,
    reportOnClick?: () => void,
    imageOnClick?: () => void,
    handlePlayerClick?: () => void
}

const CommonReport: React.FC<CommonReportProps> = ({
    children,
    leftTitle,
    centerTitle,
    currentTime,
    prevOnClick,
    nextOnClick,
    reportOnClick,
    imageOnClick,
    handlePlayerClick
}) => {

    return(
        <div className={`card transparent-to-white-gradient min-h-600px col-12`}>
            <div className='card-header border-0 pt-5 d-flex justify-content-between'>
                <h3 className='card-title align-items-start flex-column '>
                    <span className='card-label fw-bold fs-3 mb-1'>
                        {leftTitle}
                    </span>
                </h3>
                {centerTitle ?
                    <span className='m-auto'>{centerTitle}</span>
                    :
                    null
                }
                {reportOnClick?
                    <div className='m-auto me-2 ms-0'>
                        <div className='btn btn-sm btn-primary' onClick={reportOnClick}>Generate report</div>
                    </div>
                :
                null}
                {imageOnClick ?
                    <span className='btn btn-sm btn-primary m-auto mx-0' onClick={imageOnClick}>View image</span>
                :
                null}
            </div>
            <div className='card-body py-3 overflow-auto'>
                {children}
            </div >
            <div className='d-flex align-items-center flex-column py-0 w-100 card-footer'>
                <div className="d-flex flex-row w-100 mb-3">
                    {prevOnClick ?
                        <div className="btn m-auto btn-active-light" onClick={prevOnClick}>
                            <p className="text-dark m-auto">Prev</p>
                        </div>
                        :
                        null
                    }
                    {currentTime ? 
                        <p className='text-dark opacity-75 fw-semibold fs-6 m-auto col-sm-4 text-center'>{currentTime}</p>
                        :
                        null
                    }
                    {nextOnClick ?
                        <div className="btn m-auto btn-active-light" onClick={nextOnClick}>
                            <p className="text-dark m-auto">Next</p>
                        </div>
                        :
                        null
                    }
                </div>
            </div>

        </div>
    );
}

export default CommonReport