import CommonSettingsField from "../../Common/CommonSettingsField";
import usePut from "../../../hooks/Common/usePut";
import { cameraModelInList, locationsInList } from "../../../web_api/models";
import updateCamera from "../../../web_api/methods/settings/cameras/updateCamera";
import { setCachedCameraList } from "../../../redux/camerasListReducer";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect } from "react";
import * as ReactBootstrap from 'react-bootstrap';


const CameraInfoSettings = ({
    id
}: { id: number }) => {

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedLocationList = useSelector((state: RootState) => state.cachedLocations);
    const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList);

    const activeCamera = cachedCameraList.find(camera => camera.id === id);

    const { data, setData, isFormValid, handleUpdateData, errors, isUpdating } = usePut(
        {
            model: activeCamera?.model_id,
            locationId: activeCamera?.location_id,
            name: activeCamera?.name,
            ip: activeCamera?.ip_address,
            port: activeCamera?.port,
            user: activeCamera?.user,
            password: activeCamera?.password
        },
        () =>
            updateCamera({
                camera_id: id,
                name: data.name,
                ip_address: data.ip,
                port: data.port,
                user: data.user,
                password: data.password
            }),
        setCachedCameraList, cachedCameraList, 'id', id,
        () => {
            toast.success(`Camera updated successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
    );

    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    }, [errors?.length])

    return (

        <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form' onKeyDown={(e) => {
                if (e.key === 'Enter' && !isUpdating) {
                    handleUpdateData();
                };
            }}>
                <div className='card-body border-top p-6 p-sm-9'>
                    <CommonSettingsField
                        value={data.model}
                        onChange={(e) => setData({ ...data, model: parseInt(e.target.value) })}
                        id="models"
                        label="Camera Models"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    >
                        {cachedCameraModelList &&
                            cachedCameraModelList.list.map((model: cameraModelInList) => (
                                <option key={model.id} value={model.id}>
                                    {`${model.id} - ${model.name}`}
                                </option>
                            ))}
                    </CommonSettingsField>

                    <CommonSettingsField
                        id="locations"
                        label="Location"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                        value={data.locationId}
                        onChange={(e) => setData({ ...data, locationId: parseInt(e.target.value) })}
                    >
                        {cachedLocationList &&
                            cachedLocationList.list.map((location: locationsInList) => (
                                <option key={location.id} value={location.id}>
                                    {`${location.id} - ${location.name}`}
                                </option>
                            ))}
                    </CommonSettingsField>

                    <CommonSettingsField
                        inputClass={isFormValid.name}
                        id="name"
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        label="Name"
                        placeholder="Name"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    />

                    <CommonSettingsField
                        inputClass={isFormValid.ip}
                        id="ip"
                        value={data.ip}
                        onChange={(e) => setData({ ...data, ip: e.target.value })}
                        label="IP Address"
                        placeholder="IP Address"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    />

                    <CommonSettingsField
                        inputClass={isFormValid.port}
                        id="port"
                        value={data.port}
                        onChange={(e) => setData({ ...data, port: e.target.value })}
                        label="Port"
                        placeholder="Port"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    />

                    <CommonSettingsField
                        inputClass={isFormValid.user}
                        id="user"
                        value={data.user}
                        onChange={(e) => setData({ ...data, user: e.target.value })}
                        label="User"
                        placeholder="User"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    />

                    <CommonSettingsField
                        inputClass={isFormValid.password}
                        id="pass"
                        value={data.password}
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                        label="Password"
                        placeholder="Password"
                        type="password"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    />
                    <div className="d-flex flex-end">
                        <button disabled={isUpdating} className='btn btn-primary' type='button' onClick={handleUpdateData}>
                            Save Changes
                            {isUpdating &&
                                <ReactBootstrap.Spinner animation='border' size='sm' className='ms-2' />
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default CameraInfoSettings