import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from "../../../redux/activeLocationReducer";
import { setValue as setToolbar } from "../../../redux/toolbarReducer";
import { setValue as setBakeryHourFilter } from "../../../redux/toolbarHourFilterReducer";
import { RootState } from '../../../redux/store';
import { useEffect } from "react";
import FruitsVegetablesCharts from "./FruitsVegetablesCharts";
import FruitsVegetablesImages from "./FruitsVegetablesImages";

const FruitsVegetablesInfo = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const locationId = params.locationId;


    return (
        <>
            <div className='animated fadeInLeft position-absolute' style={{ top: '6%', right: '65%' }}>
                <i className='bi bi-shop-window bg-muted opacity-25' style={{ fontSize: '40.5rem' }}>
                    <span
                        className='bg-dark text-inverse-dark rounded-2 p-2 position-absolute fs-4x cursor-pointer'
                        onClick={() => {
                            navigate(-1);
                            dispatch(setToolbar(
                                {
                                    toolbar: 'fruits-and-vegetables',
                                    title: 'Fruits & Vegetables'
                                }))
                        }}
                        style={{ top: '6%', right: '20%' }}>
                        <i className='bi bi-chevron-left fs-4x text-white'
                        />
                        {locationId}
                    </span>
                </i>
            </div>
            <div className="container">
                <div className="d-flex flex-column animated zoomIn" style={{ marginTop: '100px' }}>
                    <FruitsVegetablesImages />
                    <FruitsVegetablesCharts />
                </div>
            </div>
        </>
    );
}

export default FruitsVegetablesInfo;