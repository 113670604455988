import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import * as ReactBootstrap from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LoadingPage from '../../layout/LoadingPage';


type Props = {
  title: string,
  className: string,
  barColors: ({ value }: { value: number }) => string,
  values: number[],
  names: string[],
  loading: boolean,
  formatter: (value: number) => string;
}

const CommonBarChart: React.FC<Props> = ({
  title,
  barColors,
  formatter,
  className,
  values,
  names,
  loading
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(values, names, barColors, formatter));
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, values, names])

  return (
    <div className={`card ${className}`} >
      <div className='card-body p-0 d-flex justify-content-between flex-column'>
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <span className='card-label fw-bold fs-3 mb-1'>{title}
                <span className='fs-7 text-muted ms-2'>
                  {toolbarHourFilter.hour}:00 to {toolbarHourFilter.toHour}:00
                </span>
              </span>
            </div>
          </div>
        </div>
        <div style={{ height: '400px', overflow: 'scroll' }}>
          {
            !loading ?
              (<div ref={chartRef} className='mixed-widget-10-chart card-rounded-bottom px-6'></div>)
              :
              (<LoadingPage />)
          }
        </div>
      </div>
    </div>
  )
}

const chartOptions = (values: number[], names: string[], barColors: ({ value }: { value: number }) => string, formatter: (value: number) => string): ApexOptions => {

  return {
    series: [{
      name: 'Availability',
      data: values
    }],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: values.length * 40 < 400 ? 400 : values.length * 40,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: names,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '100%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return formatter(val);
      },
      distributed: true,
      style: {
        colors: ['white']
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },

    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '9px',
      },
      y: {
        formatter: function (val) {
          return formatter(val)
        },
      },
    },
    colors: [barColors],
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 15
      }
    }
  }
}

export { CommonBarChart }
