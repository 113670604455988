import { useDispatch } from "react-redux";
import { setValue } from "../../redux/toolbarReducer";

const useToolbarSetter = () => {

    const dispatch = useDispatch();

    const setToolbar = (toolbar: string, title: string) => {
        dispatch(setValue({
            toolbar: toolbar,
            title: title
        }))
    };

    return {
        setToolbar
    }
};

export default useToolbarSetter;