/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import "react-tooltip/dist/react-tooltip.css";
import { avgTimeCashRegisterOpenList, ChartOrListEnum, detectionSettingsList, mostQueueAlertsItemInList, mostQueueAlertsList, queueDurationList } from '../../../web_api/models'
import { toAbsoluteUrl, KTSVG } from '../../helpers'
import * as ReactBootstrap from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { exportCSV, mostQueueAlertsFormat, numberFormat, numberToTimeFormat } from '../../../web_api/methods/helpers'
import { useDispatch } from 'react-redux'
import { setValue } from '../../../redux/activeLocationReducer'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils'
import { Treemap } from '../charts/Treemap'
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoadingPage from '../../../layout/LoadingPage';


type Props = {
  className: string
  title: string | null
  averageTime: string
  queueDuration: string
  loading: boolean
}

const DataWidget3: React.FC<Props> = ({ className, title, averageTime, queueDuration, loading }) => {
  const [avgTimeCashRegisterList, setAvgTimeCashRegisterList] = useState<avgTimeCashRegisterOpenList>([])
  const [queueDurationLoadedList, setQueueDurationLoadedList] = useState<queueDurationList>([])
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const avgTimesCashRegisterOpenList = useSelector((state: RootState) => state.avgTimeCashRegisterOpenList.list);
  const queueDurationList = useSelector((state: RootState) => state.queueDurationList.list);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);

  const dispatch = useDispatch()

  useEffect(() => {
    setAvgTimeCashRegisterList(avgTimesCashRegisterOpenList)
    setQueueDurationLoadedList(queueDurationList)
  }, [avgTimesCashRegisterOpenList, queueDurationList])

  const handleDownload = () => {
    let values = new Array()
    let names = new Array()
    avgTimeCashRegisterList.forEach((item) => {
      values.push(numberToTimeFormat(item.avgTime))
      names.push(item.name)
    })
    exportCSV([['Location', 'Response time']], names, values, 'Response times - ' + activeLocation.value + ' - ' +
      ((dateFilter.from === dateFilter.to) ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
        :
        new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
        + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    )
  };

  const changingComponent = useMemo(() => {
    return (
      <table className={`table d-flex flex-column d-sm-table table-row-gray-200 align-middle gs-0 gy-4 ${window.innerWidth > 768 ? "table-row-dashed" : ""}`}>
        {/* begin::Table head */}
        <thead>
          <tr className='border-0'>
            <th className='p-0 w-50px'></th>
            <th className='p-0 min-w-150px'></th>
            <th className='p-0 min-w-50px'></th>
            <th className='p-0 min-w-50px'></th>
            {/* <th className='p-0 min-w-50px'></th> */}
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {avgTimeCashRegisterList?.map((item, index) => {
            return (
              <tr className='d-flex flex-row flex-wrap gap-3 justify-content-center d-sm-table-row my-4 my-sm-0' key={item.id}>
                <td className='ps-sm-0 pe-sm-3 py-sm-4 p-2'>
                  <div className="fs-3 fw-bold bg-primarys text-inverse-primary rounded-2 px-2 py-3 cursor-pointer" style={{ backgroundColor: '#F1416C' }}
                    onClick={() => {
                      dispatch(setValue({
                        value: item.name,
                        id: item.id.toString()
                      }))
                    }}
                  >
                    <p className='m-auto text-center'>
                      {item.id}
                    </p>
                  </div>
                </td>
                <td className='w-100 text-center text-sm-start px-sm-3 py-sm-4 p-2'>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6' onClick={() => {
                    dispatch(setValue({
                      value: item.name,
                      id: item.id.toString()
                    }))
                  }}>
                    {item.name}
                  </a>
                </td>
                <td className='w-sm-100 d-sm-table-cell px-sm-3 py-sm-4 p-2' >
                  <span id={'response-time-' + index} className={(item.avgTime < 4) ? 'badge badge-light-success' : item.avgTime < 8 ? 'badge badge-light-warning' : 'badge badge-light-danger'}>{numberToTimeFormat(item.avgTime)}</span>
                  <ReactTooltip
                    anchorId={'response-time-' + index}
                    place="bottom"
                    content="Response time"
                  />

                </td>
                <td className='w-sm-100 d-sm-table-cell ps-sm-3 pe-sm-0 py-sm-4 p-2' >
                  <span id={'queue-duration-' + index} className={(queueDurationLoadedList[index].queue_duration < 4) ? 'badge badge-light-success' : queueDurationLoadedList[index].queue_duration < 8 ? 'badge badge-light-warning' : 'badge badge-light-danger'}>{numberToTimeFormat(queueDurationLoadedList[index].queue_duration)}</span>
                  <ReactTooltip
                    anchorId={'queue-duration-' + index}
                    place="bottom"
                    content="Queue duration"
                  />
                </td>
              </tr>
            )
          })}
        </tbody >
        {/* end::Table body */}
      </table >
    )
  }, [activeLocation, avgTimeCashRegisterList, dateFilter, selectedUser])



  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}
            <span className='fs-7 text-muted'>
              {dateFilter.from === dateFilter.to ? (' on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
                :
                ' - ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
                + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}
            </span>
          </span>
          <div className='d-flex flex-row'>
            <span className='text-muted mt-1 fw-semibold fs-7'>Response time: <span className='fw-bold text-dark'>{averageTime}</span> </span>
            <span className='text-muted mt-1 fw-semibold fs-7 ms-3'>Queue duration: <span className='fw-bold text-dark'>{queueDuration}</span> </span>
          </div>

        </h3>
        <div className='card-toolbar'>
          <ul className='nav' onClick={handleDownload}>
            <li className='nav-item m-auto'>
              <div className={'nav-link btn btn-sm btn-color-dark btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Download'>
                <i className="bi bi-save p-0 mb-1" />
              </div>
            </li>
          </ul>
        </div>

      </div>
      {
        loading ? (
          <div className='card-body py-3 overflow-auto h-150px'>
            {changingComponent}
          </div>
        ) : (<LoadingPage />)
      }

      {/* end::Body */}
    </div>
  )
}

export { DataWidget3 }

