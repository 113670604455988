import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { locationsList, userLocationList } from '../../../web_api/models';
import getLocations from '../../../web_api/methods/settings/locations/getLocations';
import { LocationStatus } from './LocationStatus';

export default function LocationsBakery() {
    const userList = useSelector((state: RootState) => state.userList);
    const selectedUser = useSelector((state: RootState) => state.selectedUser);
    const [locations, setLocations] = useState<locationsList>([])
    const [loadLocationsFrom, setLoadLocationsFrom] = useState<locationsList | userLocationList>([])
    useEffect(() => {
        loadLocations()
    }, [])
    useEffect(() => {
        if (selectedUser.id === '0') {
            setLoadLocationsFrom(locations)
        }
        else
            setLoadLocationsFrom(userList.list.find(x => x.id === selectedUser.id)?.locations!)
    }, [selectedUser])

    const loadLocations = async () => {
        try {
            let loadedLocations: locationsList = await getLocations();
            setLocations(loadedLocations)
            if (selectedUser.id === '0') {
                setLoadLocationsFrom(loadedLocations)
            }
            else
                setLoadLocationsFrom(userList.list.find(x => x.id === selectedUser.id)?.locations!)
        }
        catch (e) {
            console.log(e)
        }
    };

    return (

        <div className='row'>
            {
                loadLocationsFrom.map((item, index) => (
                    <LocationStatus key={item.id} locationId={item.id} locationName={item.name} className='animated zoomIn' delay={`0.${index+1}s`} />
                ))
            }
        </div>

    )
}
