import callApiAxiosClient from "../../axios/axios_client";
import moment from "moment";
import { avgTimes } from "../../models";


async function queueManagementAvgCashRegisterOpen(locationId:number, fromParam: Date, toParam: Date): Promise<avgTimes> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<avgTimes>({
        method: "get",
        url: "programmability/avg-cash-register-open",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            locationId:locationId,
            from: from,
            to: to
        }
    })
}
export default queueManagementAvgCashRegisterOpen;