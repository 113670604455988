import callApiAxiosClient from "../../axios/axios_client";
import { detectionList, detectionType } from "../../models";


async function getDetectionTypes(): Promise<detectionType[]> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<detectionType[]>({
        method: "get",
        url: "detections/distinct-detection-types",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        }
    })
}
export default getDetectionTypes;