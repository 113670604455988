import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RootState } from "../../redux/store";
import { setCachedCameraList } from '../../redux/camerasListReducer';
import addCamera from "../../web_api/methods/settings/cameras/addCamera";
import CameraAddFormModal from "./CameraAddFormModal";
import CommonNewUserCard from "../Common/CommonNewUserCard";

type CameraAddProps = {
    show: boolean,
    toggleModal: () => void,
    onCameraAdd: () => void
};


const CameraAdd: React.FC<CameraAddProps> = ({
    show,
    toggleModal,
    onCameraAdd
}) => {

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const dispatch = useDispatch();

    const isFirstCamera = cachedCameraList.length === 0 && cachedLocationsList.length > 0 && activeLocation.id === '0';
    const selectedLocationHasNoCameras = cachedCameraList.length === 0 && cachedLocationsList.length > 0;

    const [isAdding, setIsAdding] = useState<boolean>(false);
    
    const [camera, setCamera] = useState({
        modelId: -1,
        locationId: -1,
        name: "",
        ip: "",
        port: "",
        user: "",
        password: ""
    });

    const [isFormValid, setIsFormValid] = useState({
        modelId: "",
        locationId: "",
        name: "",
        ip: "",
        port: "",
        user: "",
        password: "",
    });

    const resetValidation = () => {
        setIsFormValid({
            modelId: "",
            locationId: "",
            name: "",
            ip: "",
            port: "",
            user: "",
            password: "",
        });
    };

    const handleToggleModal = () => {
        toggleModal();
        if (show) {
            resetValidation();
        }
    };

    const resetFields = () => {
        setCamera({
            modelId: -1,
            locationId: -1,
            name: "",
            ip: "",
            port: "",
            user: "",
            password: ""
        });
    }

    const handleAddCamera = async () => {
        if (!camera.name || !camera.ip || !camera.port || !camera.user || !camera.password || camera.modelId == -1 || camera.locationId === -1) {
            setIsFormValid({
                modelId: camera.modelId !== -1 ? "is-valid" : "is-invalid",
                locationId: camera.locationId !== -1 ? "is-valid" : "is-invalid",
                name: camera.name ? "is-valid" : "is-invalid",
                ip: camera.ip ? "is-valid" : "is-invalid",
                port: camera.port ? "is-valid" : "is-invalid",
                user: camera.user ? "is-valid" : "is-invalid",
                password: camera.password ? "is-valid" : "is-invalid",
            });
            return;
        }
        try {
            setIsAdding(true);
            let cameraToAdd = await addCamera(
                camera.name,
                camera.ip,
                camera.port,
                camera.user,
                camera.password,
                camera.modelId,
                camera.locationId
            )

            onCameraAdd();

            const updatedList = [...cachedCameraList];
            updatedList.unshift({
                id: cameraToAdd.id,
                name: camera.name,
                ip_address: camera.ip,
                port: camera.port,
                user: camera.user,
                password: camera.password,
                model_id: camera.modelId,
                location_id: camera.locationId,
                location: "",
                lat: 0,
                long: 0,
                has_next_page: true
            });
            dispatch(setCachedCameraList({ list: updatedList }));
            handleToggleModal();
            resetFields();
            toast.success('Camera added successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
            setIsAdding(false);

        }
        catch (e) {
            setIsAdding(false);
            resetValidation();
            console.log(e)
            toast.error(`${e}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    };

    return (
        <>
            {isFirstCamera ?
                <CommonNewUserCard
                    title="Add your first camera!"
                    content={`Click on this card to add your first camera.\n
                        Fill out the fields and press the "Confirm" button to register a camera.`}
                    className="w-xl-50 mx-auto"
                    icon="bi bi-camera"
                    buttonText="Add camera"
                    onClick={() => handleToggleModal()}
                />
                : null
            }

            {selectedLocationHasNoCameras && !isFirstCamera ?
                <CommonNewUserCard
                    title="Oops!"
                    content="This location has no available cameras, please try another one."
                    className="w-xl-50 mx-auto"
                    icon="bi bi-info"
                />
                : null
            }

            {!isFirstCamera && !selectedLocationHasNoCameras ?
                <>
                    <div className='d-flex flex-column flex-md-row p-1 flex-end'>
                        <div className='btn btn-primary' onClick={() => handleToggleModal()}>Add camera</div>
                    </div>
                </>
                : null
            }
        
        
            <CameraAddFormModal
                isAdding={isAdding}
                title="Add camera"
                isFormValid={isFormValid}
                show={show}
                camera={camera}
                onClick={handleAddCamera}
                handleToggleModal={handleToggleModal}
                setCamera={setCamera}
                confirmClass="bg-primary text-white"
                confirmIcon="bi bi-check-lg"
            />
        </>

    )
}

export default CameraAdd