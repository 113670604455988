import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CommonFormDropdownField from "../Common/CommonFormDropdownField";
import { CommonModal } from "../Common/CommonModal"
import addDetection from "../../web_api/methods/settings/detections/addDetection";
import { useState } from "react";
import { toast } from "react-toastify";
import { cameraDetectionInList } from "../../web_api/models";

type DetectionAddFormModalProps = {
    handleToggleModal: () => void;
    show: boolean;
    onDetectionAdd: (detection: cameraDetectionInList) => void;
}

const DetectionAddFormModal: React.FC<DetectionAddFormModalProps> = ({
    show,
    handleToggleModal,
    onDetectionAdd
}) => {

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedDetectionList = useSelector((state: RootState) => state.detectionList.list);
    const cachedDetectionServerList = useSelector((state: RootState) => state.detectionServerList.list);

    const [isAdding, setIsAdding] = useState<boolean>(false);
    
    const [detection, setDetection] = useState({
        cameraId: -1,
        detectionType: -1,
        serverId: -1
    });

    const [isFormValid, setIsFormValid] = useState({
        cameraId: "",
        detectionType: "",
        serverId: ""
    });

    const handleAddCameraDetection = async () => {
        if (detection.detectionType === -1 || detection.cameraId === -1 || detection.serverId === -1) {
            setIsFormValid({
                cameraId: detection.cameraId !== -1 ? "is-valid" : "is-invalid",
                detectionType: detection.detectionType !== -1 ? "is-valid" : "is-invalid",
                serverId: detection.serverId !== -1 ? "is-valid" : "is-invalid",
            });
            return;
        };
        try {
            setIsFormValid({
                cameraId: '',
                detectionType: '',
                serverId: ''
            });
            setIsAdding(true);
            let addedCameraDetection = await addDetection(
                detection.detectionType,
                detection.cameraId,
                detection.serverId
            );

            const cameraName = cachedCameraList.filter(camera => camera.id === detection.cameraId)[0].name;
            const detectionTypeName = cachedDetectionList.find(type => type.detection_type === detection.detectionType)?.detection_name || "";

            const newDetection = {
                ...addedCameraDetection,
                camera_name: cameraName,
                detection_name: detectionTypeName,
            };

            onDetectionAdd(newDetection);

            handleToggleModal();
            setDetection({
                cameraId: -1,
                detectionType: -1,
                serverId: -1
            });
            setIsFormValid({
                cameraId: '',
                detectionType: '',
                serverId: ''
            });
            setIsAdding(false);
            
            toast.success('Detection added successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });

        } catch (e) {
            setIsAdding(false);
            setIsFormValid({
                cameraId: '',
                detectionType: '',
                serverId: ''
            });
            console.log(e);
            toast.error(`${e}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    };

    return (
        <CommonModal
            title="Add Detection"
            size="lg"
            disabled={isAdding}
            show={show}
            showModalCallback={handleToggleModal}
            closeButton={true}
            confirmButton={true}
            bodyClass="flex-column w-100 ps-sm-15 pe-sm-15 pb-sm-10"
            closeButtonOptions={{
                icon: 'bi bi-x',
                variant: 'secondary',
            }}
            confirmButtonOptions={{
                icon: "bi bi-check-lg",
                variant: 'custom',
                class: "bg-primary text-white",
                onClick: handleAddCameraDetection
            }}
        >
            <CommonFormDropdownField
                label="Camera"
                value={detection.cameraId}
                inputClass={isFormValid.cameraId}
                onChange={(e) => setDetection({ ...detection, cameraId: parseInt(e.target.value) })}
            >
                <option value="" hidden>Select a camera</option>
                {cachedCameraList.map((camera) => {
                    return <option key={camera.id} value={camera.id}>{camera.name}</option>
                })}
            </CommonFormDropdownField>

            <CommonFormDropdownField
                label="Detection"
                inputClass={isFormValid.detectionType}
                value={detection.detectionType}
                onChange={(e) => setDetection({ ...detection, detectionType: parseInt(e.target.value) })}
            >
                <option value="" hidden>Select a detection type</option>
                {[...cachedDetectionList].sort((a, b) => a.detection_name.localeCompare(b.detection_name)).map((detection) => {
                    return <option key={detection.detection_type} value={detection.detection_type}>{detection.detection_name}</option>
                })}
            </CommonFormDropdownField>

            <CommonFormDropdownField
                label="Server"
                inputClass={isFormValid.serverId}
                value={detection.serverId}
                onChange={(e) => setDetection({ ...detection, serverId: parseInt(e.target.value) })}
            >
                <option value="" hidden>Select a detection server</option>
                {cachedDetectionServerList.map((server) => {
                    return <option key={server.id} value={server.id}>{`${server.id} - ${server.name}`}</option>
                })}
            </CommonFormDropdownField>
        </CommonModal>
    )
}

export default DetectionAddFormModal