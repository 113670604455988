import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface QueueDurationAllLocationsState {
  value: string
}
const initialState: QueueDurationAllLocationsState = {
  value: localStorage.getItem('queueDurationAllLocations')!?.toString()!=null?localStorage.getItem('queueDurationAllLocations')!?.toString():'undefined'
};

export const queueDurationAllLocations = createSlice({
  name: 'queueDurationAllLocations',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<QueueDurationAllLocationsState>) => {
      state.value = action.payload.value
      localStorage.setItem("queueDurationAllLocations", action.payload.value)

    },
  },
});

export const { setValue } = queueDurationAllLocations.actions;

export const queueDurationAllLocationsReducer = queueDurationAllLocations.reducer;
