import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from "../../redux/store";
import { detectionList, personItemInList, personItemInListObj, personList } from "../../web_api/models";
import * as ReactBootstrap from 'react-bootstrap'
import getPersons from "../../web_api/methods/faceRecognitionDash/getPersons";
import { setCachedPersonList } from "../../redux/personListReducer";
import deleteAlertDetail from "../../web_api/methods/alerts/deleteAlertDetail";
import { CommonModal } from "../Common/CommonModal";
import useModals from "../../hooks/Common/useModals";
import useGet from "../../hooks/Common/useGet";
import PersonsMassDelete from "../FaceRecognition/PersonMassDelete";
import FaceRecognitionCard from "../FaceRecognition/FaceRecognitionCard";
import CanvasComponent from "../FaceRecognition/CanvasComponent";
import { useParams } from "react-router-dom";
import getDetections from "../../web_api/methods/alerts/getDetections";
import AlertCard from "./AlertCard";
import LoadingPage from "../../layout/LoadingPage";
interface RouteParams {
    [key: string]: string | undefined;
}
export default function Alerts() {

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(30);
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const selectedUser = useSelector((state: RootState) => state.selectedUser);
    const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
    const dateFilter = useSelector((state: RootState) => state.dateFilter);
    const params = useParams<RouteParams>();
    const detectionType = params.detectionType;
    const detectionTypeName = params.detectionTypeName;

    const { loading, data, loadItems } = useGet([selectedUser.id, activeLocation.id, selectedCamera.id, pageIndex, pageSize], () => getDetections(parseInt(detectionType!), parseInt(activeLocation.id), pageIndex + 1, pageSize, new Date(dateFilter.from), new Date(dateFilter.to)),)
    const handleNext = () => {
        setPageIndex(prevPageIndex => prevPageIndex + 1);
    }

    const handlePrev = () => {
        setPageIndex(prevPageIndex => Math.max(prevPageIndex - 1, 0));
    }

    let loadAlerts = loadItems;
    useEffect(() => {
        setPageIndex(0); // reset page index when selectedUser changes
        loadAlerts();
    }, [activeLocation.id, selectedUser.id, dateFilter.from, dateFilter.to, detectionType]);

    return (
        <div className="container">
            <div className="d-flex flex-column flex-md-column col-12 p-5 pb-0">
                <div className="d-inline-flex flex-end">
                    <div className='d-flex flex-column flex-md-row p-1 flex-end'>
                        <button className='btn btn-primary' onClick={handlePrev} disabled={pageIndex === 0}>
                            <i className="bi bi-chevron-double-left p-0" /></button>
                        <button className='btn btn-secondary' disabled >{pageIndex + 1}</button>
                        <button className='btn btn-primary' disabled={data === undefined || data.length < 30} onClick={handleNext}>
                            <i className="bi bi-chevron-double-right p-0" />
                        </button>
                    </div>
                </div>
                <div className="row">
                    {(!loading) ?
                        data &&
                        data.map((alert) => (
                            <>
                                <AlertCard
                                    key={alert.detection_gu_id}
                                    alert={alert}
                                />
                            </>
                        ))
                        :
                        (<LoadingPage />)
                    }

                </div>
            </div>
            {/* <CommonModal
                size='xl'
                title={personItem!.person}
                show={showPicture}
                showModalCallback={() => toggleModal(0)}
                closeButton={true}
                confirmButton={false}
                bodyClass="flex-column w-100"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: "bi bi-trash3",
                    variant: 'custom',
                    class: "bg-danger text-white",
                }}
            >
                <CanvasComponent

                    imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem?.filename}`}
                    actualHeight={personItem!.picture_height}
                    actualWidth={personItem!.picture_width}
                    bboxes={[{ x1: personItem!.bbox1, y1: personItem!.bbox2, x2: personItem!.bbox3, y2: personItem!.bbox4 }]}
                    canvasHeight={"500px"}
                    canvasWidth={"100%"} />
            </CommonModal > */}
            <ToastContainer />
        </div >
    )
}
