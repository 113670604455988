import callApiAxiosClient from "../../axios/axios_client";
import { genderResponse } from "../../models";
import moment from "moment";

async function peopleCountingGender(fromParam: Date, toParam: Date): Promise<genderResponse> {
    const token = JSON.parse(localStorage.getItem('user')!)

    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<genderResponse>({
        method: "get",
        url: "programmability/gender",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            from: from,
            to: to
        }
    })
}
export default peopleCountingGender;