export interface dashboardAlertsItemInList {
    value: number
    week_day: number
}
export type dashboardAlertsList = dashboardAlertsItemInList[]

export interface dailyReportsItemInList {
    average: number
    zi: number
}
export type dailyReportsList = dailyReportsItemInList[]

export interface personItemInList {
    id: number
    detection_id: string
    date: Date
    camera_id: number
    recognition_confidence: number
    person: string
    bbox1: number
    bbox2: number
    bbox3: number
    bbox4: number
    picture_width: number
    picture_height: number
    filename: string

}

export class personItemInListObj implements personItemInList {
    id = 0
    detection_id = ''
    date = new Date()
    camera_id = 0
    recognition_confidence = 0
    person = ''
    bbox1 = 0
    bbox2 = 0
    bbox3 = 0
    bbox4 = 0
    picture_width = 0
    picture_height = 0
    filename = ''
}
export type personList = personItemInList[]

export interface monthlyReportsItemInList {
    average: number
    luna: number
    an: number
    // last_year:number
}
export type monthlyReportsList = monthlyReportsItemInList[]

export interface detectionSettingsItem {
    id: number
    detection_name: string
    camera_id: number
    camera_name: string
    min_nr_objects: number
    threshold: number
}
export type detectionSettingsList = detectionSettingsItem[]

export interface entriesHoursItem {
    period: string
    entries: number
}
export type entriesHoursList = entriesHoursItem[]

export interface entriesLocationsItem {
    loc: string
    entries: number
    day: Date
}
export type entriesLocationsList = entriesLocationsItem[]

export interface evolutionIndex {
    year_evolution: number
    year_value: number
    month_evolution: number
    month_value: number
    week_evolution: number
    week_value: number
    day_evolution: number
    day_value: number

}

export interface evolutionValueItem {
    date: string
    object_count: number
}
export type evolutionValueList = evolutionValueItem[]

export interface ageResponse {
    age: string
    object_count: number
}
export type ageResponseList = ageResponse[]

export interface genderResponse {
    object_count: number
    male: number
    female: number
}

export interface Location {
    id: number
    name: string
    location_type: number
    city_id: number
    type: string
    lat: number
    long: number
    tenant_id: number
}
export type locationsList = Location[]

export enum GraphOrListEnum {
    GRAPH,
    LIST
}

export enum DayWeekMonthYearEnum {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    CUSTOM = 'custom'
}
export enum PdfOrCsvEnum {
    PDF,
    CSV
}
export enum MixedOrSeparateEnum {
    MIXED,
    SEPARATE
}
export enum ChartOrListEnum {
    LIST,
    CHART
}
export enum ComparisonEnum {
    AUDIO_ALERTS = 'Audio alerts',
    QUEUE_ALERTS = 'Queue alerts',
    AVG_TIME_CASH_REGISTER_OPEN = 'Avg response time',
    AVG_TIME_QUEUE = 'Avg queue duration'
}
export enum ShelfStatusEnum {
    EMPTY = 'Empty',
    OCCLUSION = 'Occlusion',
    LOW = '1%-25%',
    HALF = '26%-50%',
    ALMOST = '51%-75%',
    FULL = '76%-100%'
}
export enum ShelfStatusDescriptionEnum {
    EMPTY = 'Empty',
    OCCLUSION = 'Occlusion',
    LOW = 'Low',
    HALF = '< Half',
    ALMOST = 'Almost full',
    FULL = 'Full'
}
export enum ShelfStatusColorsEnum {
    EMPTY = 'danger',
    OCCLUSION = 'secondary',
    LOW = 'danger',
    HALF = 'warning',
    ALMOST = 'primary',
    FULL = 'success'
}
export interface loginRequest {
    user_name: string;
    password: string;
};

export interface setup2FA {
    barcode_url: string
}

export interface ValueDateLocation {
    value: number
    day: string
    loc: string
}

export interface mostQueueAlertsItemInList {
    id: number
    name: string
    value: number
    day: string
}
export type mostQueueAlertsList = mostQueueAlertsItemInList[]

export interface audioAlerts {
    time: string
    location_id: number
    total: number
}
export type audioAlertsList = audioAlerts[]

export interface shelfStatus {
    product: string
    fill: string
    filename: string
    hour: number
}
export type shelfStatusList = shelfStatus[]

export interface lfShelvesStatusItemInList {
    hour: number
    detection_gu_id: string
    camera_id: number
    detection_type: number
    detection_description: string
    filename: string
    picture_width: number
    picture_height: number
    detection_time: string
    row_num: number
    object_class: string
    detail_description: string
    feature1: string
    feature2: string
    threshold: number
    bbox1: number
    bbox2: number
    bbox3: number
    bbox4: number
    object_id: number

}
export type lfShelvesStatusList = lfShelvesStatusItemInList[]

export interface missingProduct {
    product: string
    missing_points: number
    availability: number
}
export type missingProductList = missingProduct[]

export interface lfProductAvailabilityItemInList {
    product: string
    availability: number
}
export type lfProductAvailabilityList = lfProductAvailabilityItemInList[]

export class shelfStatusObj implements shelfStatus {
    product = ''
    fill = ''
    filename = ''
    hour = 9
}

export interface productEvolution {
    product: string
    fill: string
    time: string
    filename: string
}
export type productEvolutionList = productEvolution[]

export interface avgTimeCashRegisterOpenItemInList {
    id: number
    avgTime: number
    name: string
}
export type avgTimeCashRegisterOpenList = avgTimeCashRegisterOpenItemInList[]

export interface queueDurationItemInList {
    id: number
    queue_duration: number
    name: string
}
export type queueDurationList = queueDurationItemInList[]


export interface avgTimes {
    avg_time: string
    queue_duration: string
}

export interface userItemInList {
    id: string
    name: string
    role_id: number
    locations: userLocationList
}
export type userItemList = userItemInList[]

export interface userLocationItemInList {
    id: number
    name: string
}
export type userLocationList = userLocationItemInList[]

export interface camerasInList {
    id: number
    name: string
    ip_address: string
    port: string
    user: string
    password: string
    model_id: number
    location: string
    location_id: number
    lat: number
    long: number
    has_next_page: boolean
}

export type camerasList = camerasInList[]

export interface addCameraResponse {
    id: number
}


export interface locationsInList {
    id: number,
    location_type: number | null,
    city_id: number,
    name: string | null,
    type: string | null,
    lat: number | null,
    long: number | null,
    tenant_id: number | null
}

export interface detectionTypeInList {
    product_id: number,
    detection_type: number,
    detection_name: string
}

export type detectionTypeList = detectionTypeInList[]
export type cachedLocationList = locationsInList[];

export interface bboxWithDescription {
    bboxes: number[]
    detail_description: string
}

export interface detectionItemInList {
    row_num: number
    camera_id: number
    detection_type: number
    detection_gu_id: string
    detection_description: string
    filename: string
    picture_width: number
    picture_height: number
    detection_time: string
    feature1: string
    feature2: string
    threshold: number
    multiple_bboxes: bboxWithDescription[]
}
export type detectionList = detectionItemInList[];

export interface detectionType {
    detection_type: number
    detection_type_name: string
}

export interface cameraDetectionInList {
    id: number
    detection_id: number
    detection_name: string
    dependent_detection_id: number
    camera_id: number
    camera_name: string
    created_date: Date
    detection_type: number
    is_alert: boolean
    max_nr_objects: number
    processing_window: number
    min_nr_objects: number
    nr_occurrences: number
    save_picture: boolean
    threshold: number
    user_id: string
    object_class: string
    fps: number
    region_name: number
    dependent_no_of_frames: number
    detection_server_id: number
    has_next_page: boolean
}


export type cameraDetectionList = cameraDetectionInList[]

export interface addDetectionResponse {
    id: number
    detection_id: number
    detection_name: string
    dependent_detection_id: number
    camera_id: number
    camera_name: string
    created_date: Date
    detection_type: number
    is_alert: boolean
    max_nr_objects: number
    processing_window: number
    min_nr_objects: number
    nr_occurrences: number
    save_picture: boolean
    threshold: number
    user_id: string
    object_class: string
    fps: number
    dependent_no_of_frames: number
    region_name: number
    detection_server_id: number
    has_next_page: boolean
}

export interface polygonObjectInList {
    id: string,
    body: {
        type: string,
        value: string,
        purpose: string
    }[],
    polygon: string
}

export type polygonObjectList = polygonObjectInList[]

export type detectionRegion = {
    detection_setting_id: number,
    polygons: polygonObjectList
}

export interface cameraModelInList {
    id: number,
    name: string,
    command: string,
    command_local: string
}

export type cameraModelList = cameraModelInList[]

export interface detectionServerInList {
    id: number,
    tenant_id: number,
    location_id: number,
    name: string
}

export type detectionServerList = detectionServerInList[]
