import callApiAxiosClient from "../../../axios/axios_client";
import { detectionServerInList } from "../../../models";

async function addDetectionServer(name: string, locationId: number): Promise<detectionServerInList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient({
        method: "post",
        url: "settings/add-detection-servers",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            name: name,
            location_id: locationId
        }
    })
}
export default addDetectionServer;