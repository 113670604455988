import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MasterLayout from '../layout/MasterLayout';

import PasswordReset from '../pages/Account/PasswordReset/PasswordReset';
import Users from '../pages/Account/Users/Users';
import Alerts from '../pages/Alerts/Alerts';
import BakeryAnalytics from '../pages/Bakery/Analytics/BakeryAnalytics';
import LocationInfoBakery from '../pages/Bakery/Analytics/LocationInfoBakery';
import BakeryReports from '../pages/Bakery/Reports/BakeryReports';
import CameraModels from '../pages/CameraModels/CameraModels';
import CameraInfo from '../pages/Cameras/CameraInfo/CameraInfo';
import Cameras from '../pages/Cameras/Cameras';
import MapCameraWrapper from '../pages/Cameras/MapCameraWrapper';
import DetectionServers from '../pages/DetectionServers/DetectionServers';
import DetectionInfo from '../pages/Detections/DetectionInfo/DetectionInfo';
import DetectionInfoAnnotation from '../pages/Detections/DetectionInfo/DetectionInfoAnnotation';
import Detections from '../pages/Detections/Detections';
import EvolutionComparison from '../pages/EvolutionComparison/EvolutionComparison';
import FaceRecognition from '../pages/FaceRecognition/FaceRecognition';
import FruitsVegetables from '../pages/FruitsVegetables/FruitsVegetables';
import FruitsVegetablesInfo from '../pages/FruitsVegetables/FruitsVegetablesInfo/FruitsVegetablesInfo';
import HSSE from '../pages/HSSE/HSSE';
import Home from '../pages/Home';
import Locations from '../pages/Locations/Locations';
import Login from '../pages/Login';
import MapLocationWrapper from '../pages/Map/MapLocationWrapper';
import QueueManagement from '../pages/QueueManagement/QueueManagement';
import ReportAProblem from '../pages/Support/ReportAProblem';
import TwoFactorAuth from '../pages/2FA/TwoFactorAuth';

// const Home = React.lazy(() => import('../pages/Home'));
// const QueueManagement = React.lazy(() => import('../pages/QueueManagement/QueueManagement'));
// const EvolutionComparison = React.lazy(() => import('../pages/EvolutionComparison/EvolutionComparison'));
// const PasswordReset = React.lazy(() => import('../pages/Account/PasswordReset/PasswordReset'));
// const Users = React.lazy(() => import('../pages/Account/Users/Users'));
// const BakeryAnalytics = React.lazy(() => import('../pages/Bakery/Analytics/BakeryAnalytics'));
// const BakeryReports = React.lazy(() => import('../pages/Bakery/Reports/BakeryReports'));
// const LocationInfoBakery = React.lazy(() => import('../pages/Bakery/Analytics/LocationInfoBakery'));
// const ReportAProblem = React.lazy(() => import('../pages/Support/ReportAProblem'));
// const Cameras = React.lazy(() => import('../pages/Cameras/Cameras'));
// const Locations = React.lazy(() => import('../pages/Locations/Locations'));
// const FaceRecognition = React.lazy(() => import('../pages/FaceRecognition/FaceRecognition'));
// const FruitsVegetables = React.lazy(() => import('../pages/FruitsVegetables/FruitsVegetables'));
// const FruitsVegetablesInfo = React.lazy(() => import('../pages/FruitsVegetables/FruitsVegetablesInfo/FruitsVegetablesInfo'));
// const CameraInfo = React.lazy(() => import('../pages/Cameras/CameraInfo/CameraInfo'));
// const Detections = React.lazy(() => import('../pages/Detections/Detections'));
// const DetectionInfo = React.lazy(() => import('../pages/Detections/DetectionInfo/DetectionInfo'));
// const DetectionInfoAnnotation = React.lazy(() => import('../pages/Detections/DetectionInfo/DetectionInfoAnnotation'));
// const Alerts = React.lazy(() => import('../pages/Alerts/Alerts'));
// const CameraModels = React.lazy(() => import('../pages/CameraModels/CameraModels'));
// const MapLocationWrapper = React.lazy(() => import('../pages/Map/MapLocationWrapper'));
// const MapCameraWrapper = React.lazy(() => import('../pages/Cameras/MapCameraWrapper'));
// const DetectionServers = React.lazy(() => import('../pages/DetectionServers/DetectionServers'));
// const HSSE = React.lazy(() => import('../pages/HSSE/HSSE'));
// const Login = React.lazy(() => import('../pages/Login'));
// const LoadingPage = React.lazy(() => import('../layout/LoadingPage'));

export default function PrivateRoutes() {
    
    return (
        // <Suspense fallback={<LoadingPage />}>
            <Routes>
                {localStorage.getItem("is_valid") === 'true' ?
                    (
                        <Route element={<MasterLayout />}>
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/home'} element={<Home />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/queue-management'} element={<QueueManagement />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/alerts/:detectionType&:detectionTypeName'} element={<Alerts />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/face-recognition'} element={<FaceRecognition />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/builder'} element={<Login />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/comparison'} element={<EvolutionComparison />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/detections'} element={<Detections />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/detections/:id'} element={<DetectionInfo />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/detections/:id/annotation'} element={<DetectionInfoAnnotation />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} element={<PasswordReset />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/account/2fa'} element={<TwoFactorAuth />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/support/report-a-problem'} element={<ReportAProblem />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/account/users'} element={<Users />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/bakery/analytics'} element={<BakeryAnalytics />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/bakery/analytics/:locationId'} element={<LocationInfoBakery />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/bakery/reports'} element={<BakeryReports />} />

                            <Route path={process.env.REACT_APP_HOMEPAGE + '/fruits-and-vegetables'} element={<FruitsVegetables />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/fruits-and-vegetables/:locationId'} element={<FruitsVegetablesInfo />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/cameras'} element={<Cameras />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/cameras/:id'} element={<CameraInfo />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/locations'} element={<Locations />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/map-locations'} element={<MapLocationWrapper />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/map-cameras'} element={<MapCameraWrapper />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/camera-models'} element={<CameraModels />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/detection-servers'} element={<DetectionServers />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/hsse'} element={<HSSE />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/login/*'} element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/home'} />} />
                            <Route path='/*' element={<QueueManagement />} />
                        </Route>
                    )
                    :
                    (
                        <>
                            <Route path='/*' element={<PasswordReset />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} element={<PasswordReset />} />
                            <Route path={process.env.REACT_APP_HOMEPAGE + '/login/*'} element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} />} />
                        </>
                    )
                }

            </Routes>
        // </Suspense>
    )
}
