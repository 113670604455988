import { useMemo, useState } from 'react'
import { ComparisonEnum, MixedOrSeparateEnum } from '../../web_api/models'

import { ComparisonMixedChart } from '../../_metronic/widgets/charts/ComparisonMixedChart'
import { ChartsWidget12 } from '../../_metronic/widgets/charts/ChartsWidget12'
import { ComparisonSeparateChart } from '../../_metronic/widgets/charts/ComparisonSeparateChart'
import { format } from 'date-fns'
import { Modal, Button } from 'react-bootstrap'
import { DateRange, DayPicker } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { hourParameters } from '../../redux/hourParametersReducer'

export default function EvolutionComparison() {
  const [mixedOrSeparate, setMixedOrSeparate] = useState<MixedOrSeparateEnum>(MixedOrSeparateEnum.SEPARATE)
  const [chartComparison, setChartComparison] = useState<ComparisonEnum>(ComparisonEnum.AUDIO_ALERTS)
  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setShow(false);
    setFirstRange(range);
    setSecondRange(range2)
  }
  const handleShowModal = () => setShow(true);

  const defaultSelected: DateRange = {
    from: new Date(),
    to: new Date()
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [range2, setRange2] = useState<DateRange | undefined>(defaultSelected);
  const [firstRange, setFirstRange] = useState<DateRange | undefined>(defaultSelected);
  const [secondRange, setSecondRange] = useState<DateRange | undefined>(defaultSelected);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
  const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
  const compareUser1 = useSelector((state: RootState) => state.compareUser1);
  const compareUser2 = useSelector((state: RootState) => state.compareUser2);

  let selectedRange = <p className='w-100'>Please pick the first day.</p>;
  if (range?.from) {
    if (!range.to) {
      selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      selectedRange = (
        <p className='w-100'>
          {format(range.from, 'PPP')} - {format(range.to, 'PPP')}
        </p>
      );
    }
  }
  let selectedRange2 = <p className='w-100'>Please pick the first day.</p>;
  if (range2?.from) {
    if (!range2.to) {
      selectedRange2 = <p className='w-100'>{format(range2.from, 'PPP')}</p>;
    } else if (range2.to) {
      selectedRange2 = (
        <p className='w-100'>
          {format(range2.from, 'PPP')} - {format(range2.to, 'PPP')}
        </p>
      );
    }
  }
  const handleCallbackComparison = (active: ComparisonEnum) => {
    setChartComparison(active)
  };

  const changingComponent = useMemo(() => {
    return (mixedOrSeparate === MixedOrSeparateEnum.MIXED) ?
      (
        <div className="d-flex flex-column col-sm-12 p-0">
          <ComparisonMixedChart
            type='area'
            className='w-sm-100'
            chartColor='primary'
            chartSecondaryColor='danger'
            chartHeight='150px'
            comparison={chartComparison}
            from={new Date(dateFilter.from)}
            to={new Date(dateFilter.to)}
          />
        </div>
      )
      :
      (
        <div className="d-flex flex-row col-sm-12 p-0">
          <div className="d-flex flex-column col-sm-6">
            <ComparisonSeparateChart
              type='bar'
              className='w-sm-100'
              chartColor='primary'
              chartHeight='150px'
              location={compareLocation1}
              comparison={chartComparison}
              user={compareUser1.id}
            />
          </div>
          <div className="d-flex flex-column col-sm-6">
            <ComparisonSeparateChart
              type='bar'
              className='w-sm-100'
              chartColor='danger'
              chartHeight='150px'
              location={compareLocation2}
              comparison={chartComparison}
              user={compareUser2.id}
            />
          </div>
        </div>
      )
  }, [mixedOrSeparate, chartComparison, compareLocation1, compareLocation2, dateFilter, hourParameters, compareUser1, compareUser2])

  return (
    <div className="container">
      <div className="d-flex flex-column">
        {/* Header */}
        <div className="d-flex flex-column col-sm-12 p-0">          
          <div className="d-flex flex-column w-sm-100 h-100 p-5">
          Please select 2 locations to compare
            <ChartsWidget12 className='h-100' callbackComparison={handleCallbackComparison} />
          </div>
        </div>
        <div className="d-flex flex-row col-sm-12 p-5 pt-0">
          <div className='card w-sm-100'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Comparison</span>
                <span className='text-muted fw-semibold fs-7'>Compare similar periods of time</span>
              </h3>
              <div className='d-flex align-items-center flex-shrink-0'>
                <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
                  Filter By:
                </span>
                <div className='flex-shrink-0 '>
                  <ul className='nav'>
                    <li className='nav-item' onClick={() => setMixedOrSeparate(MixedOrSeparateEnum.SEPARATE)}>
                      <a
                        className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (mixedOrSeparate === MixedOrSeparateEnum.SEPARATE ? ' active' : '')}
                        data-bs-toggle='tab'
                      >
                        Side to side
                      </a>
                    </li>

                    <li className='nav-item' onClick={() => setMixedOrSeparate(MixedOrSeparateEnum.MIXED)}>
                      <a
                        className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (mixedOrSeparate === MixedOrSeparateEnum.MIXED ? ' active' : '')}
                        data-bs-toggle='tab'
                      >
                        Mixed
                      </a>
                    </li>
                    {/* <li className='nav-item ' onClick={() => { handleShowModal() }}>
                      <div className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1'}
                        data-bs-toggle='tab'>
                        <i className='bi bi-calendar' />
                        <a>
                          Time period
                        </a>
                      </div>

                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='card-body p-0'>
              {changingComponent}
            </div>
          </div>
        </div>
      </div>
      <Modal size='lg' show={show} onHide={handleCloseModal}>
        <Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-row'>
            <DayPicker id='1'
              fromYear={2020}
              toYear={2024}
              captionLayout="dropdown"
              defaultMonth={new Date()}
              mode="range"
              selected={range}
              onSelect={setRange}
              footer={selectedRange}
            />
            <DayPicker id='2'
              fromYear={2020}
              toYear={2024}
              captionLayout="dropdown"
              defaultMonth={new Date()}
              mode="range"
              selected={range2}
              onSelect={setRange2}
              footer={selectedRange2} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}
