/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import { ComparisonEnum, locationsList } from '../../../web_api/models'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import queueManagementAudioAlerts from '../../../web_api/methods/queueManagementDash/audioAlerts'
import getLocations from '../../../web_api/methods/settings/locations/getLocations'


type Props = {
  className: string
  chartHeight: string
  chartColor: string
  chartSecondaryColor: string
  type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap'
  comparison: ComparisonEnum
  from: Date
  to: Date
}

const ComparisonMixedChart: React.FC<Props> = ({ className, chartHeight, chartColor, type, chartSecondaryColor, comparison }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [values1, setValues1] = useState<number[]>([])
  const [values2, setValues2] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])
  const [days, setDays] = useState<string[]>([])

  const [values, setValues] = useState<number>(0)

  const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
  const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
  const compareUser1 = useSelector((state: RootState) => state.compareUser1);
  const compareUser2 = useSelector((state: RootState) => state.compareUser2);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);

  useEffect(() => {
    loadData(comparison)//dupa range
  }, [comparison, compareLocation1, compareLocation2, dateFilter, hourParameters, compareUser1, compareUser2])

  const loadAudioAlerts = async () => {
    try {
      //days
      const fromDate = new Date(dateFilter.from);
      const toDate = new Date(dateFilter.to);

      const daysList = [];
      let currentDate = fromDate;
      while (currentDate <= toDate) {
        const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00`;
        daysList.push(dateString);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let loadedLocations: locationsList = await getLocations();
      let forValues = new Array()
      let forValues2 = new Array()
      let forNames = new Array()
      let sum = 0
      let audioAlerts = await queueManagementAudioAlerts(parseInt(compareLocation1.id), new Date(dateFilter.from), new Date(dateFilter.to), parseInt(hourParameters.from), parseInt(hourParameters.to), compareUser1.id);
      if (compareLocation1.id === "0") { // toate locatiile
        if (daysList.length > 1) // mai multe zile
          loadedLocations.forEach((location) => {
            sum = 0
            audioAlerts.filter(x => x.location_id === location.id).forEach((item) => {
              sum += item.total
            })
            forValues.push(sum)
            forNames.push(location.id)
          })
        else { // o singura zi
          audioAlerts.forEach((item) => {
            forValues.push(item.total)
            forNames.push(item.location_id)
          })
        }
      }
      else { // o locatie
        if (daysList.length > 1) // mai multe zile
          audioAlerts.forEach((item) => {
            forValues.push(item.total)
            const date = new Date(item.time)
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });

            const formattedDate = `${day} ${month}`;
            forNames.push(formattedDate)
          })
        else { // o singura zi
          audioAlerts.forEach((item) => {
            forValues.push(item.total)
            forNames.push(item.time.toString() + ':00')
          })
        }
      }
      let audioAlertsCompare = await queueManagementAudioAlerts(parseInt(compareLocation2.id), new Date(dateFilter.from), new Date(dateFilter.to), parseInt(hourParameters.from), parseInt(hourParameters.to), compareUser2.id);
      if (compareLocation2.id === "0") { // toate locatiile
        if (daysList.length > 1) // mai multe zile
          loadedLocations.forEach((location) => {
            sum = 0
            audioAlertsCompare.filter(x => x.location_id === location.id).forEach((item) => {
              sum += item.total
            })
            forValues2.push(sum)
            forNames.push(location.id)
          })
        else { // o singura zi
          audioAlertsCompare.forEach((item) => {
            forValues2.push(item.total)
            forNames.push(item.location_id)
          })
        }
      }
      else { // o locatie
        if (daysList.length > 1) // mai multe zile
          audioAlertsCompare.forEach((item) => {
            forValues2.push(item.total)
            const date = new Date(item.time)
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });

            const formattedDate = `${day} ${month}`;
            forNames.push(formattedDate)
          })
        else { // o singura zi
          audioAlertsCompare.forEach((item) => {
            forValues2.push(item.total)
            forNames.push(item.time.toString() + ':00')
          })
        }
      }
      setValues1(forValues)
      setValues2(forValues2)
      setLabels(forNames)
    }
    catch (e) {
      console.log(e)
    }
  };
  let loadData = async (comparisonType: ComparisonEnum) => {
    try {
      if (comparisonType === ComparisonEnum.AUDIO_ALERTS) {
        loadAudioAlerts()
      }
      else if (comparisonType === ComparisonEnum.QUEUE_ALERTS) {
      }
      else if (comparisonType === ComparisonEnum.AVG_TIME_CASH_REGISTER_OPEN
      ) {
      }
      else if (comparisonType === ComparisonEnum.AVG_TIME_QUEUE
      ) {
      }
    }
    catch (e) {
      console.log(e)
    }
  };

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(chartColor, labels, values1, values2, type, chartSecondaryColor, compareLocation1.value, compareLocation2.value))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, values1, labels, values2])


  return (
    <div className={`card ${className}`}>
      <div className='card-body p-5 pb-0 d-flex flex-column'>
        <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom' data-kt-chart-color={chartColor} style={{ height: chartHeight }}></div>
      </div>
    </div>

  )
}

const chartOptions = (chartColor: string, xAxis1: string[], yAxis1: number[], yAxis2: number[], type: 'line'
| 'area'
| 'bar'
| 'histogram'
| 'pie'
| 'donut'
| 'radialBar'
| 'scatter'
| 'bubble'
| 'heatmap'
| 'candlestick'
| 'boxPlot'
| 'radar'
| 'polarArea'
| 'rangeBar'
| 'rangeArea'
| 'treemap', chartSecondaryColor: string, location1: string, location2: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const secondaryColor = getCSSVariableValue('--kt-' + chartSecondaryColor)
  return {
    series: [
      {
        name: location1,
        data: yAxis1,
      },
      {
        name: location2,
        data: yAxis2,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.5
      }
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor, secondaryColor],
    },
    xaxis: {
      categories: xAxis1,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: [baseColor, secondaryColor],
      strokeWidth: 3,
    },
  }
}
export { ComparisonMixedChart }