import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';

import { setValue as setDateFilter } from '../../../redux/dateFilterReducer';

import resetPassword from '../../../web_api/methods/account/resetPassword';
import { setValue } from '../../../redux/mostQueueAlertsReducer';
import { useNavigate } from 'react-router-dom';


export default function PasswordReset() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hasError, setHasError] = useState<boolean>(false)
    const oldPasswordInputValue = useRef<HTMLInputElement>(null)
    const newPasswordInputValue = useRef<HTMLInputElement>(null)
    const confirmNewPasswordInputValue = useRef<HTMLInputElement>(null)
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            const oldPassword = oldPasswordInputValue.current!.value
            const newPassword = newPasswordInputValue.current!.value
            const confirmNewPassword = confirmNewPasswordInputValue.current!.value

            resetPassword(oldPassword, newPassword, confirmNewPassword)
                .then(async () => {
                    localStorage.clear()

                    dispatch(setValue({
                        list: []
                    }))
                    dispatch(setDateFilter({
                        from: new Date().toISOString(),
                        to: new Date().toISOString()
                    }))

                    navigate(process.env.REACT_APP_HOMEPAGE + '/login')
                    window.location.reload()
                })
                .catch(e => {
                    setHasError(true)
                })
        }
    };

    async function handlePasswordReset() {
        const oldPassword = oldPasswordInputValue.current!.value
        const newPassword = newPasswordInputValue.current!.value
        const confirmNewPassword = confirmNewPasswordInputValue.current!.value

        resetPassword(oldPassword, newPassword, confirmNewPassword)
            .then(async () => {
                localStorage.clear()

                dispatch(setValue({
                    list: []
                }))
                dispatch(setDateFilter({
                    from: new Date().toISOString(),
                    to: new Date().toISOString()
                }))

                navigate(process.env.REACT_APP_HOMEPAGE + '/login')
                window.location.reload()
            })
            .catch(e => {
                setHasError(true)
            })
    }
    return (
        <>
            <div className='d-flex flex-center'>
                <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
                    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                        <div className='w-lg-500px p-10'>
                            <div className='text-center mb-15'>
                                <h1 className='text-dark fw-bolder'>
                                    Reset your password
                                </h1>
                                <h2 className='text-gray-500 fw-semibold fs-6'>

                                </h2>
                            </div>
                            <div className='text-center'>
                                <form onKeyPress={handleKeyPress}>
                                    <div className='fv-row my-6 d-flex flex-wrap'>
                                        <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>Old password</label>
                                        <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={oldPasswordInputValue} type={'password'} id='old-pass' placeholder='Old password' />
                                    </div>
                                    <div className='fv-row my-6 d-flex flex-wrap '>
                                        <label className='form-label fw-bolder text-dark fs-6 mb-2' htmlFor='pass'>New password</label>
                                        <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={newPasswordInputValue} type={'password'} id='new-pass' placeholder='New password' />
                                    </div>
                                    <div className='fv-row my-6 d-flex flex-wrap'>
                                        <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>Confirm new password</label>
                                        <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={confirmNewPasswordInputValue} type={'password'} id='confirm-new-pass' placeholder='Confirm new password' />
                                    </div>
                                    <div className='text-gray-500 my-6 text-center fw-semibold fs-6'>Password must contain at least one digit, one lower letter, one upper letter and one non alphanumeric character! &nbsp;
                                    </div>
                                    <div className='d-grid my-6'>
                                        <button className='btn btn-primary' type={'button'} onClick={handlePasswordReset}>Reset password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
