/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import { DayWeekMonthYearEnum, evolutionValueList } from '../../../web_api/models'
import peopleCountingEvolutionValue from '../../../web_api/methods/peopleCountingDash/evolutionValue'
import { format } from 'date-fns'
import { Modal, Button } from 'react-bootstrap'
import { DateRange, DayPicker } from 'react-day-picker'
import { numberFormat } from '../../../web_api/methods/helpers'


type Props = {
  className: string
  chartHeight: string
  chartColor: string
  type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap'
  filter: DayWeekMonthYearEnum

}

const Chart1MixedCharts1: React.FC<Props> = ({ className, chartHeight, chartColor, type, filter }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [values, setValues] = useState<number[]>([])
  const [dates, setDates] = useState<string[]>([])
  const [show, setShow] = useState(false);
  const [rangeEnum, setFilter] = useState<DayWeekMonthYearEnum>(DayWeekMonthYearEnum.DAY)
  const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const defaultSelected: DateRange = {
    from: new Date(),
    to: new Date()
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

  let selectedRange = <p className='w-100'>Please pick the first day.</p>;
  if (range?.from) {
    if (!range.to) {
      selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      selectedRange = (
        <p className='w-100'>
          {format(range.from, 'PPP')} - {format(range.to, 'PPP')}
        </p>
      );
    }
  }

  useEffect(() => {
    loadEvolutionValues(filter)
  }, [filter])

  let loadEvolutionValues = async (filter: DayWeekMonthYearEnum) => {
    try {

      let evolutionValues: evolutionValueList = await peopleCountingEvolutionValue(filter);
      let forValues = new Array()
      let forDate = new Array()

      evolutionValues.forEach((item) => {
        forValues.push(item.object_count)
        if (filter === DayWeekMonthYearEnum.YEAR)
          forDate.push(monthNames[parseInt(item.date.slice(5, 7)) - 1])
        else if (filter === DayWeekMonthYearEnum.MONTH)
          forDate.push(new Date(item.date).toLocaleString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          }).replace(/\//g, '-'))
        else if (filter === DayWeekMonthYearEnum.WEEK)
          forDate.push(dayNames[new Date(item.date).getDay() - 1])
        else if (filter === DayWeekMonthYearEnum.DAY)
          forDate.push(item.date.slice(item.date.length - 3, item.date.length) + ':00')

      })
      setValues(forValues)
      setDates(forDate)
    }
    catch (e) {
      console.log(e)
    }
  };

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor, dates, values, type, filter))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, values, dates])


  return (
    <div className={`card ${className}`}>
      <div className='card-body p-5 d-flex flex-column'>
        <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom' data-kt-chart-color={chartColor} style={{ height: chartHeight }}></div>
      </div>
    </div>

  )
}

const chartOptions = (chartHeight: string, chartColor: string, xAxis: string[], yAxis: number[], type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap', filter: DayWeekMonthYearEnum): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  let title = ''
  if (filter === DayWeekMonthYearEnum.DAY) {
    const date = new Date();
    title = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  }
  else if (filter === DayWeekMonthYearEnum.WEEK) {
    const date = new Date();
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const weekNum = Math.ceil(((date.valueOf() - firstDayOfYear.valueOf()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
    title = `Week ${weekNum} ${date.toLocaleDateString('en-US', { month: 'long' })}`;
  }
  else if (filter === DayWeekMonthYearEnum.MONTH) {
    const date = new Date();
    title = date.toLocaleDateString('en-US', { month: 'long' });
  }
  else {
    const date = new Date();
    title = date.getFullYear().toString();
  }
  return {
    series: [
      {
        name: 'Detections',
        data: yAxis,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: 'Total detections - ' + title
          }
        }
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: [baseColor, lightColor],
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.5
      }
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return numberFormat(val)
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
export { Chart1MixedCharts1 }