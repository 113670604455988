import { useEffect } from "react"
import { useLayout, ILayout } from "./core"
import { ToolbarReports } from "./ToolbarReports"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { ToolbarPageTitle } from "./ToolbarPageTitle"
import { ToolbarComparison } from "./ToolbarComparison"
import { ToolbarUsersAndDate } from "./ToolbarUsersAndDate"
import { ToolbarUsersAndLocation } from "./ToolbarUsersAndLocation"
import { ToolbarFaceRecognition } from "./ToolbarFaceRecognition"
import { ToolbarDateAndHour } from "./ToolbarDateAndHour"
import { ToolbarLocationsCamerasAlgorithms } from "./ToolbarLocationsCamerasAlgorithms"

const Toolbar = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
    document.body.setAttribute('data-kt-app-toolbar-enabled', 'true')
  }, [config])
  const toolbar = useSelector((state: RootState) => state.toolbar.toolbar);

  switch (toolbar) {
    case 'bakery':
      return <ToolbarUsersAndDate />
    case 'alerts':
      return <ToolbarReports />
    case 'date-and-hour':
      return <ToolbarUsersAndDate />
    case 'queue-management':
      return <ToolbarReports />
    case 'comparison':
      return <ToolbarComparison />
    case 'cameras':
      return <ToolbarUsersAndLocation />
    case 'hsse':
      return <ToolbarUsersAndLocation />
    case 'face-recognition':
      return <ToolbarFaceRecognition />
    case 'fruits-and-vegetables':
      return <ToolbarUsersAndDate />
    case 'detections':
      return <ToolbarLocationsCamerasAlgorithms />
    default:
      return <ToolbarPageTitle />
  }
}

const updateDOM = (config: ILayout) => {
  let appToolbarSwapAttributes: { [attrName: string]: string } = {}
  const appToolbarSwapEnabled = config.app?.toolbar?.swap?.enabled
  if (appToolbarSwapEnabled) {
    appToolbarSwapAttributes = config.app?.toolbar?.swap?.attributes as { [attrName: string]: string }
  }

  let appToolbarStickyAttributes: { [attrName: string]: string } = {}
  const appToolbarStickyEnabled = config.app?.toolbar?.sticky?.enabled
  if (appToolbarStickyEnabled) {
    appToolbarStickyAttributes = config.app?.toolbar?.sticky?.attributes as {
      [attrName: string]: string
    }

    let appToolbarMinimizeAttributes: { [attrName: string]: string } = {}
    const appToolbarMinimizeEnabled = config.app?.toolbar?.minimize?.enabled
    if (appToolbarMinimizeEnabled) {
      appToolbarMinimizeAttributes = config.app?.toolbar?.minimize?.attributes as {
        [attrName: string]: string
      }
    }

    if (config.app?.toolbar?.fixed?.desktop) {
      document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    }

    if (config.app?.toolbar?.fixed?.mobile) {
      document.body.setAttribute('data-kt-app-toolbar-fixed-mobile', 'true')
    }

    setTimeout(() => {
      const toolbarElement = document.getElementById('kt_app_toolbar')
      // toolbar
      if (toolbarElement) {
        const toolbarAttributes = toolbarElement
          .getAttributeNames()
          .filter((t) => t.indexOf('data-') > -1)
        toolbarAttributes.forEach((attr) => toolbarElement.removeAttribute(attr))

        if (appToolbarSwapEnabled) {
          for (const key in appToolbarSwapAttributes) {
            if (appToolbarSwapAttributes.hasOwnProperty(key)) {
              toolbarElement.setAttribute(key, appToolbarSwapAttributes[key])
            }
          }
        }

        if (appToolbarStickyEnabled) {
          for (const key in appToolbarStickyAttributes) {
            if (appToolbarStickyAttributes.hasOwnProperty(key)) {
              toolbarElement.setAttribute(key, appToolbarStickyAttributes[key])
            }
          }
        }

        if (appToolbarMinimizeEnabled) {
          for (const key in appToolbarMinimizeAttributes) {
            if (appToolbarMinimizeAttributes.hasOwnProperty(key)) {
              toolbarElement.setAttribute(key, appToolbarMinimizeAttributes[key])
            }
          }
        }
      }
    }, 0)
  }
}

export { Toolbar }
