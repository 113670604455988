import { Tooltip as BsTooltip } from "bootstrap";
import React, { useEffect, useRef } from "react";

export const CommonTooltip = (p: { children: JSX.Element; text: string, bg: string, textColor: string }) => {
    const childRef = useRef(undefined as unknown as Element);

    useEffect(() => {
        const t = new BsTooltip(childRef.current, {
            title: p.text,
            placement: "bottom",
            trigger: "hover",
            template: `<div class="tooltip custom-class text-justify" role="tooltip"><div class="tooltip-arrow invisible"></div><div class="tooltip-inner ${p.bg} ${p.textColor}"></div></div>`
        });
        return () => t.dispose();
    }, [p.text, p.bg]);

    return React.cloneElement(p.children, { ref: childRef });
};
