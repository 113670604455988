import callApiAxiosClient from "../../../axios/axios_client";
import { detectionServerList } from "../../../models";

async function getDetectionServers(id?: number): Promise<detectionServerList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<detectionServerList>({
        method: "get",
        url: "settings/get-detection-servers",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params: {
            id: id
        }
    })
}
export default getDetectionServers;