/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ChartOrListEnum, detectionSettingsList, mostQueueAlertsItemInList, mostQueueAlertsList, queueDurationList } from '../../../web_api/models'
import { toAbsoluteUrl, KTSVG } from '../../helpers'
import * as ReactBootstrap from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { exportCSV, mostQueueAlertsFormat, numberFormat, numberToTimeFormat } from '../../../web_api/methods/helpers'
import { useDispatch } from 'react-redux'
import { setValue } from '../../../redux/activeLocationReducer'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils'
import { Treemap } from '../charts/Treemap'
import LoadingPage from '../../../layout/LoadingPage'




type Props = {
  className: string
  title: string | null
  totalQueueAlerts: string
  loading: boolean
}

const DataWidget2: React.FC<Props> = ({ className, title, totalQueueAlerts, loading }) => {
  const [mostQueueAlerts, setMostQueueAlerts] = useState<mostQueueAlertsList>([])
  const [queueDurationList, setQueueDurationList] = useState<queueDurationList>([])
  const [chartOrList, setChartOrList] = useState<boolean>(false)
  const [xAxis, setxAxis] = useState<string[]>([])
  const [yAxis, setyAxis] = useState<number[]>([])
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const mostQueueAlertsList = useSelector((state: RootState) => state.mostQueueAlerts.list);
  const queueDurationListInStore = useSelector((state: RootState) => state.queueDurationList.list);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);

  const dateFilter = useSelector((state: RootState) => state.dateFilter);

  const dispatch = useDispatch()

  useEffect(() => {
    const list = mostQueueAlertsFormat(mostQueueAlertsList)
    setMostQueueAlerts(list)
    setQueueDurationList(queueDurationListInStore)
    let forX = new Array()
    let forY = new Array()
    list.forEach((item) => {
      forX.push(item.id.toString())
      forY.push(item.value);
    })
    setxAxis(forX)
    setyAxis(forY)
    setChartOrList(false)
  }, [mostQueueAlertsList, queueDurationListInStore])

  const handleDownload = () => {
    let values = new Array()
    let names = new Array()
    mostQueueAlerts.forEach((item) => {
      values.push(item.value)
      names.push(item.name)
    })
    exportCSV([['Location', 'Queue alerts']], names, values, 'Queue alerts - ' + activeLocation.value + ' - ' +
      ((dateFilter.from === dateFilter.to) ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
        :
        new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
        + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    )
  };

  const changingComponent = useMemo(() => {
    return (!chartOrList) ?
      (
        <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 d-flex flex-column'>
          {/* begin::Table head */}
          <thead>
            <tr className='border-0'>
              <th className='p-0 w-50px'></th>
              <th className='p-0 min-w-150px'></th>
              <th className='p-0 min-w-140px'></th>
              <th className='p-0 min-w-110px'></th>
              {/* <th className='p-0 min-w-50px'></th> */}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {mostQueueAlerts?.map((item) => {
              return (
                <tr key={`${item.id}-${item.name}`} className='cursor-pointer' onClick={() => {
                  if (activeLocation.id === "0") dispatch(setValue({
                    value: item.name,
                    id: item.id.toString()
                  }))
                }}>
                  <td>
                    <div className="fs-3 fw-bold bg-primarys text-inverse-primary rounded-2 px-2 py-3" style={{ backgroundColor: '#F1416C' }}>
                      <p className='m-auto text-center'>
                        {activeLocation.id === '0' ? item.id : item.name[0] + item.name[item.name.length - 1]}
                      </p>
                    </div>
                  </td>
                  <td className='w-100'>
                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6' onClick={() => {
                      if (activeLocation.id === "0") dispatch(setValue({
                        value: item.name,
                        id: item.id.toString()
                      }))
                    }}>
                      {item.name}
                    </a>
                    <span className='text-muted fw-semibold d-block'>{item.value} queue alerts</span>
                  </td>
                  <td className='text-end text-muted fw-semibold'>{
                    (queueDurationList.find(x => x.id === item.id)?.queue_duration != null
                      || queueDurationList.find(x => x.id === item.id)?.queue_duration != undefined)
                      ? numberToTimeFormat(queueDurationList.find(x => x.id === item.id)!?.queue_duration) : ''
                  }</td>
                  <td className='text-end ms-auto w-100'>
                    <span className={(activeLocation.id === "0") ? 'badge badge-secondary' : 'badge badge-light-success'}>{(activeLocation.id === "0") ? 'See details' : 'Open'}</span>
                  </td>
                  {/* <td className='text-end'>
            <a
              href='#'
              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
            >
              <i className='bi bi-gear'></i>
            </a>
          </td> */}
                </tr>
              )
            })}
          </tbody >
          {/* end::Table body */}
        </table >
      )
      :
      (
        <Treemap xAxis={xAxis} yAxis={yAxis} />
      )
  }, [chartOrList, activeLocation, mostQueueAlerts, dateFilter, selectedUser])



  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title} {dateFilter.from === dateFilter.to ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
            :
            ' - ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
            + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total of <span className='fw-bold text-dark'>{totalQueueAlerts}</span> queue alerts</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav' onClick={() => setChartOrList(!chartOrList)}>
            <li className='nav-item'>
              <div className={'nav-link btn btn-sm btn-color-dark btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Treemap'>
                <i className={'bi p-0 mb-1 ' + (chartOrList ? 'bi-list' : 'bi-map')} />
              </div>
            </li>
          </ul>
          <ul className='nav' onClick={handleDownload}>
            <li className='nav-item m-auto'>
              <div className={'nav-link btn btn-sm btn-color-dark btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Download'>
                <i className="bi bi-save p-0 mb-1" />
              </div>
            </li>
          </ul>
        </div>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {
        loading ? (
          <div className='card-body px-5 py-5 px-sm-9 py-sm-3 overflow-auto h-150px'>
            <div className='tab-content'>
              {/* begin::Tap pane */}
              <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  {changingComponent}
                </div>
                {/* end::Table */}
              </div>
            </div>
          </div>
        ) : (<LoadingPage />)
      }

      {/* end::Body */}
    </div>
  )
}

export { DataWidget2 }

