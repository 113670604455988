type CommonPlayerProps = {
    handlePlayerClick?: () => void,
    playerProgress: number,
    timeoutIds: NodeJS.Timeout | undefined
}

const CommonPlayer: React.FC<CommonPlayerProps> = ({
    handlePlayerClick,
    playerProgress,
    timeoutIds
}) => {
    return (
        <div className="d-flex align-items-center animated">
            <i
                className={`${timeoutIds ? 'bi bi-pause-fill' : 'bi bi-play-fill'} cursor-pointer mb-1`}
                style={{ fontSize: '3.5rem' }}
                onClick={handlePlayerClick}
            ></i>
            <div
                className="progress ml-3 flex-grow-1"
                role="progressbar"
                aria-label="Animated striped example"
                aria-valuenow={playerProgress}
                aria-valuemin={0}
                aria-valuemax={100}
            >
                <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                    style={{ width: `${playerProgress}%` }}
                ></div>
            </div>
        </div>
    )
}

export default CommonPlayer;