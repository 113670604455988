import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface distinctPersonListState {
    list: string[]
}
const initialState: distinctPersonListState = {
    list: localStorage.getItem("distinctPersonList") != null ? JSON.parse(localStorage.getItem("distinctPersonList") ?? '') : []
};

export const distinctPersonList = createSlice({
    name: 'distinctPersonList',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<distinctPersonListState>) => {
            state.list = action.payload.list
            localStorage.setItem("distinctPersonList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setValue } = distinctPersonList.actions;

export const distinctPersonListReducer = distinctPersonList.reducer;
