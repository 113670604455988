/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import * as ReactBootstrap from 'react-bootstrap'
import { numberFormat } from '../../../web_api/methods/helpers'
import LoadingPage from '../../../layout/LoadingPage'

type Props = {
    className: string
    chartColor: string
    chartSecondColor: string
    values: number[],
    valuesLastWeek: number[]
    days: string[],
    daysLastWeek: string[],
    average: number,
    max: number,
    loading: boolean
}

const ChartsWidget9: React.FC<Props> = ({ className, chartColor, chartSecondColor, values, valuesLastWeek, days, daysLastWeek, average, max, loading }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartSecondColor, values, daysLastWeek, valuesLastWeek, max))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, days, values, valuesLastWeek, max])

    return (
        <div className={`card ${className}`}>
            <div className='card-body d-flex flex-column p-0'>
                <div className='flex-grow-1 card-p pb-0'>
                    <div className='d-flex flex-stack flex-wrap'>
                        <div className='me-2'>
                            <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
                                Last week
                            </a>
                            <div className='text-muted fs-7 fw-semibold'>Daily average</div>
                        </div>
                        <div className={`fw-bold fs-7 text-${chartColor}`}>{numberFormat(average)} entries</div>
                    </div>
                </div>
                {
                    loading ?
                        (<div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>)
                        :
                        (<LoadingPage />)
                }
            </div>
        </div>
    )
}

const chartOptions = (chartColor: string, chartSecondColor: string, values: number[], days: string[], valuesLastWeek: number[], max: number): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-800')
    const strokeColor = getCSSVariableValue('--kt-gray-300')
    const baseColor = getCSSVariableValue('--kt-' + chartColor)
    const secondColor = getCSSVariableValue('--kt-' + chartSecondColor)
    const lightSecondColor = getCSSVariableValue('--kt-' + chartSecondColor + '-light')
    const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
    return {
        series: [
            {
                name: 'Current',
                data: values,
            },
            {
                name: 'Last week',
                data: valuesLastWeek,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: 150,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
            style: {
                colors: [baseColor, secondColor]
            }
        },
        fill: {
            type: "gradient",
            gradient: {

                opacityFrom: 1,
                opacityTo: 0.5,

            }
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [baseColor, secondColor],
        },
        xaxis: {
            categories: days,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: strokeColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            min: 0,
            max: max + (0.1 * max),
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '9px',
            },
            y: {
                formatter: function (val) {
                    return numberFormat(val) + ' entries'
                },
            },
        },
        colors: [baseColor, secondColor],
        markers: {
            colors: [baseColor, secondColor],
            strokeColors: [lightColor, lightSecondColor],
            strokeWidth: 3,
        },
    }
}

export { ChartsWidget9 }
