import callApiAxiosClient from "../../../axios/axios_client";
import { cameraDetectionList } from "../../../models";

type getDetectionsParams = {
    id?: number, 
    detection_id?: number,
    location_id?: number,
    camera_id?: number,
    page_index?: number,
    page_size?: number
}

async function getDetections(params?: getDetectionsParams): Promise<cameraDetectionList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<cameraDetectionList>({
        method: "get",
        url: "settings/get-detection-settings",
        headers: {
            "Content-Type": "Application/json", 
            "Authorization": authorization

        },
        params: {
            id: params?.id,
            detection_id: params?.detection_id,
            location_id: params?.location_id,
            camera_id: params?.camera_id,
            page_index: params?.page_index,
            page_size: params?.page_size
        }
    })
}
export default getDetections;