import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface avgTimeCashRegisterOpenItemInList {
  id:number
  avgTime: number
  name: string
}
export interface avgTimeCashRegisterOpenListState {
  list: avgTimeCashRegisterOpenItemInList[]
}
const initialState: avgTimeCashRegisterOpenListState = {
  list: localStorage.getItem("avgTimeCashRegisterOpenList") != null ? JSON.parse(localStorage.getItem("avgTimeCashRegisterOpenList") ?? '') : []
};

export const avgTimeCashRegisterOpenList = createSlice({
  name: 'avgTimeCashRegisterOpenList',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<avgTimeCashRegisterOpenListState>) => {
      state.list = action.payload.list
      localStorage.setItem("avgTimeCashRegisterOpenList", JSON.stringify(action.payload.list))
    },
  },
});

export const { setValue } = avgTimeCashRegisterOpenList.actions;

export const avgTimeCashRegisterOpenListReducer = avgTimeCashRegisterOpenList.reducer;
