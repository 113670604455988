import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ActiveLocationState {
  value: string
  id: string
}
const initialState: ActiveLocationState = {
  value: localStorage.getItem('activeLocation')!?.toString()!=null?localStorage.getItem('activeLocation')!?.toString():'All',
  id: localStorage.getItem('activeLocationId')!?.toString()!=null?localStorage.getItem('activeLocationId')!?.toString():'0'
};

export const activeLocation = createSlice({
  name: 'activeLocation',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<ActiveLocationState>) => {
      state.value = action.payload.value
      state.id = action.payload.id
      localStorage.setItem("activeLocation", action.payload.value)
      localStorage.setItem("activeLocationId", action.payload.id)

    },
  },
});

export const { setValue } = activeLocation.actions;

export const activeLocationReducer = activeLocation.reducer;
