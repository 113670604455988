import callApiAxiosClient from "../../../axios/axios_client";
import { setup2FA } from "../../../models";



function setup2fa(): Promise<setup2FA> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<setup2FA>({
        method: "post",
        url: "account/setup-2fa",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        }
    })
}
export default setup2fa;