import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { CommonModal } from "../Common/CommonModal";
import useDelete from "../../hooks/Common/useDelete";
import deleteCameras from "../../web_api/methods/settings/cameras/deleteCameras";


interface CameraMassDelete {
    onShowMassDeleteModal: (cameraIds: number[]) => string[]
    onCameraMassDelete: (id: number) => void;
    checkedCameras: number[];
}

const CameraMassDelete: React.FC<CameraMassDelete> = ({ checkedCameras, onCameraMassDelete, onShowMassDeleteModal }) => {
    const [showDelete, setShowDelete] = useState(false);
    const [cameraNames, setCameraNames] = useState<string[]>([]);

    const handleToggleDeleteModal = () => {
        setShowDelete(prevShowModal => !prevShowModal);
        if (!showDelete) {
            setShowDelete(true);
            const names = onShowMassDeleteModal(checkedCameras);
            setCameraNames(names);
        }
    };

    const { deleteItems: handleMassDelete, errors, isDeleting } = useDelete(
        () => deleteCameras(checkedCameras),
        () => {
            checkedCameras.forEach(onCameraMassDelete);
            handleToggleDeleteModal();
            toast.success('Cameras deleted successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
    );

    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    }, [errors?.length]);

    return (
        <>
                <div className={`d-flex flex-column flex-md-row p-1 flex-end ${checkedCameras.length > 0 ? 'visibile' : 'invisible'}`}>
                    <div className='btn btn-danger' onClick={() => handleToggleDeleteModal()}>Delete cameras</div>
                </div>
            <CommonModal
                title={"Confirm"}
                show={showDelete}
                disabled={isDeleting}
                showModalCallback={handleToggleDeleteModal}
                closeButton={true}
                confirmButton={true}
                bodyClass="flex-column w-100"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: "bi bi-trash3 text-white",
                    variant: 'custom',
                    class: "bg-danger text-white",
                    onClick: handleMassDelete
                }}
            >
                <div>Are you sure you want to delete the following cameras?</div>
                {cameraNames.map((name) => (
                    <div key={name} className="fw-bolder mt-3">
                        {name}
                    </div>
                ))}
            </CommonModal>
        </>
    )
}

export default CameraMassDelete