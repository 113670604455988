import * as ReactBootstrap from 'react-bootstrap'
import useModals from '../../hooks/Common/useModals';
import { Link } from 'react-router-dom';
import placeholderImage from '../../assets/images/placeholder-denmark.jpg';
import { CommonModal } from '../Common/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from "../../redux/activeLocationReducer";
import { setValue as setToolbar } from "../../redux/toolbarReducer";
import { setValue as setBakeryHourFilter } from "../../redux/toolbarHourFilterReducer";
import { RootState } from '../../redux/store';
import { useEffect } from "react";
type FruitsVegetablesCardProps = {
    location: {
        id: number,
        location_type: number | null,
        city_id: number,
        name: string | null,
        type: string | null,
        lat: number | null,
        long: number | null,
        tenant_id: number | null
    },
    loading: boolean
}

const FruitsVegetablesCard: React.FC<FruitsVegetablesCardProps> = ({

    location,
    loading

}) => {
    const dispatch = useDispatch();

    const { show, toggleModal } = useModals([false]);
    const handleViewLocation = () => {
        dispatch(setValue(
            {
                value: location.name || 'All',
                id: location.id.toString()
            }))
        dispatch(setToolbar(
            {
                toolbar: 'date-and-hour',
                title: location.name || 'All'
            }))
        dispatch(setBakeryHourFilter(
            {
                hour: '9',
                toHour: '21'
            }))
    };
    return (

        <>
            <Link className={`d-flex flex-column col-12 col-md-4 my-3 h-min-300px animated zoomIn cursor-pointer`} onClick={handleViewLocation} to={`${process.env.REACT_APP_HOMEPAGE}/fruits-and-vegetables/${location.id}`}>
                <div className='card animated hover-grow'>
                    <div className='card-header border-0 pt-5'>
                        <div className='d-flex row '>
                            <div className='d-flex col-sm-2'>
                                <i className='bi bi-shop-window bg-muted text-hover-primary flex-center d-flex mb-2 opacity-25' style={{ fontSize: '4.5rem' }}>
                                    <span className='bg-dark text-inverse-dark rounded-2 p-2 position-absolute fs-7'
                                        style={{ top: '3%' }}>
                                        {location.id}
                                    </span>
                                </i>
                            </div>
                            <div className='d-flex col-sm-10'>
                                <p className='m-auto'>
                                    <Link onClick={handleViewLocation} to={`${process.env.REACT_APP_HOMEPAGE}/fruits-and-vegetables/${location.id}`} className='fs-6 fw-bold text-dark text-hover-primary'> {location.name}</Link>
                                </p>
                            </div>
                        </div>
                    </div>


                </div >
            </Link >
        </>
    );
}

export default FruitsVegetablesCard