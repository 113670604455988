import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface dayWeekMonthYearState {
  filter: string
}
const initialState: dayWeekMonthYearState = {
  filter: localStorage.getItem('dayWeekMonthYear')!?.toString() != null ? localStorage.getItem('dayWeekMonthYear')!?.toString() : 'week',
  };

export const dayWeekMonthYear = createSlice({
  name: 'dayWeekMonthYear',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<dayWeekMonthYearState>) => {
      state.filter = action.payload.filter
      localStorage.setItem("dayWeekMonthYear", action.payload.filter)
    },
  },
});

export const { setValue } = dayWeekMonthYear.actions;

export const dayWeekMonthYearReducer = dayWeekMonthYear.reducer;
