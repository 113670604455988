import callApiAxiosClient from "../../../axios/axios_client";
import { polygonObjectList } from "../../../models";

async function addDetectionRegion(id: number | string, polygons: polygonObjectList): Promise<polygonObjectList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient({
        method: "post",
        url: "settings/add-detection-regions",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            detection_setting_id: id,
            polygons: polygons
        }
    })
}
export default addDetectionRegion;