import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import { ToolbarWrapper } from './ToolbarWrapper'


export default function MasterLayout() {
    const navigate = useNavigate()
    const token = JSON.parse(localStorage.getItem('user')!)

    useEffect(() => {
        if (new Date() > new Date(token.expiration_date)) {
            localStorage.clear()
            navigate(process.env.REACT_APP_HOMEPAGE + '/login')
            window.location.reload()
        }
    }, [])

    return (
        <>
            <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                    <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper' style={{ height: '100vh' }}>
                        <Sidebar />
                        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                            <div className='d-flex flex-column flex-column-fluid'>
                                <ToolbarWrapper />
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
