import React, { useEffect } from "react";
import clsx from "clsx";
import { WithChildren } from "../../_metronic/helpers/react18MigrationHelpers";
import { useSidebarMenuItemWithSub } from "./Hooks/useSidebarMenuItemWithSub";

type Props = {
  childrenUrlList?: string[];
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  active: string;
  handleChangeActiveState: (title: string) => void;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  childrenUrlList,
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  active,
  handleChangeActiveState
}) => {
  // hooks
  const {
    // states
    app,
    activeChildList,
    // methods
  } = useSidebarMenuItemWithSub({ to, childrenUrlList });

  const isActive = title === active;

  return (
    <div
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion")}
      data-kt-menu-trigger="click"
    >
      <div
        onClick={() => handleChangeActiveState(title)}
        className={clsx(`menu-link`, {
          isActive,
        })}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}

        <i className={clsx("fs-6 menu-icon", fontIcon)}></i>
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <></>
        )}
        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </div>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
