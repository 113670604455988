import { Link } from 'react-router-dom'
import penny from '../assets/images/Penny-Logo.png'
import logo from '../assets/images/logo_c2i.svg'

import { useLayout } from './core'

import { setValue as setToolbar } from "../redux/toolbarReducer"
import { useDispatch } from 'react-redux';

export default function SidebarLogo() {
  const { config } = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  const dispatch = useDispatch();

  const closeMobileSidebar = () => {
    const sidebar = document.getElementById('kt_app_sidebar');
    sidebar?.classList.remove('drawer', 'drawer-start', 'drawer-on');
  };

  function handleChangeTab() {
    dispatch(setToolbar(
      {
        toolbar: 'people-counting',
        // title: activeLocation.value + ' - ' + ((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00')
        title: 'People counting'
      }))
  }
  return (
    <>
 
      <div className='app-sidebar-logo p-5 px-lg-6 d-flex align-items-center' id='kt_app_sidebar_logo' onClick={handleChangeTab}>
        <Link className='mx-auto' to={process.env.REACT_APP_HOMEPAGE + '/home'}>
          <img
            alt='Logo'
            src={logo}
            className='h-100'
          />
          {/* <img
          alt='Logo'
          src={penny}
          className='h-50px mx-2'
        /> */}
        </Link>
     <div className='d-lg-none'>
        <i className="bi bi-x-lg" style={{ fontSize: '1.5rem' }} onClick={closeMobileSidebar} />
      </div>
        {/* {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <i className='svg-icon-2 rotate-180 bi bi-chevron-double-left' ></i>
        </div>
      )} */}
      </div>
    </>


  )
}
