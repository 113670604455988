import { useEffect, useState } from "react";
import setup2fa from "../../web_api/methods/account/2FA/setup-2fa";
import { setup2FA } from "../../web_api/models";
import { CommonModal } from "../Common/CommonModal";
import QRCode from "react-qr-code";
import LoadingPage from "../../layout/LoadingPage";
import verify2fa from "../../web_api/methods/account/2FA/verify-2fa";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";


type TwoFactorAuthSetupModalProps = {
    show: boolean,
    toggleModal: () => void
};

const TwoFactorAuthSetupModal: React.FC<TwoFactorAuthSetupModalProps> = ({
    show,
    toggleModal
}) => {

    const mail = useSelector((state: RootState) => state.user.user_name);

    const [code, setCode] = useState({
        isGenerating: false,
        isVerifying: false,
        url: '',
        otp: ''
    });


    const loadBarcodeUrl = async () => {
        try {
            setCode({ ...code, isGenerating: true });
            let barcodeUrl: setup2FA = await setup2fa();
            setCode({ ...code, isGenerating: false, url: barcodeUrl.barcode_url });
        }
        catch (e) {
            console.log(e);
            setCode({ ...code, isGenerating: false });
        };
    };

    const handleToggleModal = () => {
        toggleModal();
        setTimeout(() => {
            setCode({
                isVerifying: false,
                isGenerating: true,
                url: '',
                otp: ''
            });
        }, show ? 200 : 0);
    };

    const verifyOtp = async () => {
        try {
            setCode({ ...code, isVerifying: true });
            let verifiedOtp = await verify2fa(code.otp, mail)
            handleToggleModal();
            toast.success(`Two factor enabled!`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
            if (verifiedOtp.is_valid) {
                localStorage.setItem("two_factor_enabled", JSON.stringify(verifiedOtp.two_factor_enabled));
            }
            setCode({ ...code, isVerifying: false });
        } catch (e) {
            console.log(e);
            toast.error(`${e}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
            setCode({ ...code, isVerifying: false });
        };
    };

    const handleInputChange = (otp: string) => {
        const sanitizedInput = otp.replace(/[^0-9]/g, '');
        const otpValue = sanitizedInput.slice(0, 6);
        setCode({ ...code, otp: otpValue });
    };

    useEffect(() => {
        if (show) {
            loadBarcodeUrl();
        };
    }, [show]);
    

    return (
        <CommonModal
            size='lg'
            title='Two-factor Authentification'
            show={show}
            disabled={code.isGenerating || code.isVerifying}
            showModalCallback={handleToggleModal}
            closeButton={true}
            confirmButton={true}
            bodyClass="flex-column w-100"
            closeButtonOptions={{
                icon: 'bi bi-x',
                variant: 'secondary',
                class: "",
            }}
            confirmButtonOptions={{
                icon: "bi bi-plus",
                variant: 'custom',
                class: "bg-primary text-white",
                text: 'Send code',
                onClick: code.otp.length === 6 ? verifyOtp : undefined
            }}
        >
            <div className="px-4 py-1 px-sm-12 py-sm-2">
                <div className="text-gray-400 fs-5 fw-semibold">
                    Click on the button in the bottom right to generate a QR code that you can scan with your authentificator app:
                </div>

                <div className={`d-flex justify-content-center align-items-center gap-6 flex-column mt-6 ${code.isGenerating ? 'opacity-50' : ''}`}>
                    
                    {code.isGenerating ?
                        <div>
                            <LoadingPage />
                        </div>
                        :
                        <>
                            <QRCode value={code.url} className={`mw-300px ${code.isGenerating ? 'invisible' : ''}`} />
                            <div className="d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6 w-100">
                                <i className="ki-duotone ki-information bi bi-exclamation fs-2tx text-warning me-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                                <div>
                                    <div className="fw-semibold">
                                        <div className="fs-6 text-gray-700 text-break">
                                            If you're having trouble using the QR code, select manual entry on your app, and enter your username and the code:
                                            <div className="fw-bold text-dark pt-2">{code.url.slice(code.url.indexOf('=') + 1, code.url.indexOf('&'))}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <input
                                    type="tel"
                                    autoFocus
                                    value={code.otp}
                                    onChange={(e) => handleInputChange(e.target.value)}
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Enter authentication code"
                                    name="code"
                                    maxLength={6}
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                />
                            </div>
                        </>
                    }
                </div>
            </div>


        </CommonModal>
    )
}

export default TwoFactorAuthSetupModal;