import callApiAxiosClient from "../../axios/axios_client";
import moment from "moment";
import { shelfStatusList } from "../../models";


async function bakeryShelfCurrentStatus(locationId: number, date: Date | undefined): Promise<shelfStatusList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const dateParam = moment(date).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<shelfStatusList>({
        method: "get",
        url: "programmability/shelf-current-status",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            locationId: locationId,
            date: dateParam
        }
    })
}
export default bakeryShelfCurrentStatus;