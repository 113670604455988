import React, { useState } from 'react'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub/SidebarMenuItemWithSub'
import useGet from '../hooks/Common/useGet'
import getDetectionTypes from '../web_api/methods/alerts/getDetectionTypes'
import { setDetectionTypes } from '../redux/detectionTypesReducer'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'


export default function SidebarMenuMain() {
    const seeWorkInProgress = process.env.REACT_APP_SEE_WORK_IN_PROGRESS
    const { loading, data } = useGet([], getDetectionTypes, setDetectionTypes,)
    const cachedDetectionTypes = useSelector((state: RootState) => state.detectionTypes.list);

    const [activeSub, setActiveSub] = useState('');

    const handleToggleSub = (title: string) => {
        if (activeSub === title) {
            setActiveSub('');
        } else {
            setActiveSub(title);
        };
    };

    return (
        <>
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/queue-management'}
                icon=''
                title='Queue management'
                tab='queue-management'
                fontIcon='bi-people'
                handleToggleSub={handleToggleSub}

            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/home'}
                icon=''
                title='People counting'
                tab='people-counting'
                fontIcon='bi-person-lines-fill'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/face-recognition'}
                icon=''
                title='Face Recognition'
                tab='face-recognition'
                fontIcon='bi-person-bounding-box'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/hsse'}
                icon=''
                title='HSSE'
                tab='hsse'
                fontIcon='bi-cone-striped'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/comparison'}
                icon=''
                title='Comparison (BETA)'
                tab='comparison'
                fontIcon='bi-bar-chart'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItemWithSub
                active={activeSub}
                handleChangeActiveState={handleToggleSub}
                to={process.env.REACT_APP_HOMEPAGE + '/bakery'}
                title='Bakery'
                hasBullet={false}
                fontIcon='bi-pie-chart'>
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/bakery/analytics'}
                    icon=''
                    title='Analytics'
                    tab='bakery'
                    fontIcon='bi-bar-chart'
                />
                {/* <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/bakery/reports'}
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='Reports'
                    tab='bakery'
                    fontIcon='bi-clipboard-data'
                /> */}
            </SidebarMenuItemWithSub>
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/fruits-and-vegetables'}
                icon=''
                title='Fruits & Vegetables'
                tab='fruits-and-vegetables'
                fontIcon='bi bi-sun'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItemWithSub active={activeSub} handleChangeActiveState={handleToggleSub} to={process.env.REACT_APP_HOMEPAGE + '/alerts'} title='Alerts' hasBullet={false} fontIcon='bi-exclamation-octagon'>
                {cachedDetectionTypes.map((detection) => {
                    return (
                        <SidebarMenuItem
                            key={detection.detection_type}
                            to={`${process.env.REACT_APP_HOMEPAGE}/alerts/${detection.detection_type}&${detection.detection_type_name}`}
                            icon=''
                            title={`Alerts ${detection.detection_type_name}`}
                            tab='alerts'
                            fontIcon='bi-bell'
                        />
                    )
                })}
            </SidebarMenuItemWithSub>

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
                </div>
            </div>
            <SidebarMenuItemWithSub active={activeSub} handleChangeActiveState={handleToggleSub} to={process.env.REACT_APP_HOMEPAGE + '/locations'} title='Locations' hasBullet={false} fontIcon='bi-houses'>
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/locations'}
                    icon=''
                    title='List'
                    tab='locations'
                    fontIcon='bi-list'
                />
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/map-locations'}
                    icon=''
                    title='Map'
                    tab='locations'
                    fontIcon='bi-map'
                />
                {/* <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/bakery/reports'}
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='Reports'
                    tab='bakery'
                    fontIcon='bi-clipboard-data'
                /> */}
            </SidebarMenuItemWithSub>


            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/camera-models'}
                icon=''
                title='Camera Models'
                tab='camera-models'
                fontIcon='bi-collection'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/detection-servers'}
                icon=''
                title='Detection Servers'
                tab='detection-servers'
                fontIcon='bi-hdd-network'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItemWithSub active={activeSub} handleChangeActiveState={handleToggleSub} to={process.env.REACT_APP_HOMEPAGE + '/cameras'} title='Cameras' hasBullet={false} fontIcon='bi-camera'>
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/cameras'}
                    icon=''
                    title='List'
                    tab='cameras'
                    fontIcon='bi-list'
                />
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/map-cameras'}
                    icon=''
                    title='Map'
                    tab='cameras'
                    fontIcon='bi-map'
                />
            </SidebarMenuItemWithSub>


            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/detections'}
                icon=''
                title='Detections'
                tab='detections'
                fontIcon='bi-eye'
                handleToggleSub={handleToggleSub}
            />

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Account</span>
                </div>
            </div>
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/account/users'}
                icon=''
                title='Users'
                tab='users'
                fontIcon='bi-person'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/account/2fa'}
                icon=''
                title='Two Factor Auth (2FA)'
                tab='2fa'
                fontIcon='bi-shield'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'}
                icon=''
                title='Reset password'
                tab='reset-password'
                fontIcon='bi-gear'
                handleToggleSub={handleToggleSub}
            />

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Support</span>
                </div>
            </div>
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/support/report-a-problem'}
                icon=''
                title='Report a problem'
                tab='report-a-problem'
                fontIcon='bi-flag'
                handleToggleSub={handleToggleSub}
            />

            {/* <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
                </div>
            </div>
            <SidebarMenuItemWithSub active={activeSub} handleChangeActiveState={handleToggleSub} to={process.env.REACT_APP_HOMEPAGE + '/locations'} title='Locations' hasBullet={false} fontIcon='bi-houses'>
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/locations'}
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='List'
                    tab='locations'
                    fontIcon='bi-list'
                />
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/map-locations'}
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='Map'
                    tab='locations'
                    fontIcon='bi-map'
                />
            </SidebarMenuItemWithSub>


            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/camera-models'}
                icon='/media/icons/duotune/general/gen019.svg'
                title='Camera Models'
                tab='camera-models'
                fontIcon='bi-collection'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/detection-servers'}
                icon='/media/icons/duotune/general/gen019.svg'
                title='Detection Servers'
                tab='detection-servers'
                fontIcon='bi-hdd-network'
                handleToggleSub={handleToggleSub}
            />
            <SidebarMenuItemWithSub active={activeSub} handleChangeActiveState={handleToggleSub} to={process.env.REACT_APP_HOMEPAGE + '/cameras'} title='Cameras' hasBullet={false} fontIcon='bi-camera'>
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/cameras'}
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='List'
                    tab='cameras'
                    fontIcon='bi-list'
                />
                <SidebarMenuItem
                    to={process.env.REACT_APP_HOMEPAGE + '/map-cameras'}
                    icon='/media/icons/duotune/general/gen019.svg'
                    title='Map'
                    tab='cameras'
                    fontIcon='bi-map'
                />
            </SidebarMenuItemWithSub>


            <SidebarMenuItem
                to={process.env.REACT_APP_HOMEPAGE + '/detections'}
                icon='/media/icons/duotune/general/gen019.svg'
                title='Detections'
                tab='detections'
                fontIcon='bi-eye'
                handleToggleSub={handleToggleSub}
            /> */}

            {seeWorkInProgress === 'true' &&
                (<>
                    <SidebarMenuItemWithSub active={activeSub} handleChangeActiveState={handleToggleSub} to={process.env.REACT_APP_HOMEPAGE + '/wop'} title='Work in progress' hasBullet={false} fontIcon='bi-clock'>

                    </SidebarMenuItemWithSub>
                    {/* 
                    <SidebarMenuItem
                        to={process.env.REACT_APP_HOMEPAGE + '/alerts'}
                        icon='/media/icons/duotune/art/art002.svg'
                        title='Alerts Dashboard'
                        tab='alerts-dashboard'
                        fontIcon='bi-clipboard-data'
                    /> */}


                </>
                )
            }

            {/* <SidebarMenuItem
                to='/builder'
                icon='/media/icons/duotune/general/gen019.svg'
                title='To Login'
                fontIcon='bi-layers'
            /> */}
        </>
    )
}
