import { useState, useEffect } from "react"
import { camerasList } from "../web_api/models"
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from "../redux/activeLocationReducer";
import { RootState } from "../redux/store";
import { setValue as setSelectedCamera } from "../redux/selectedCameraReducer"
import { setValue as setSelectedPerson } from "../redux/selectedPersonReducer"
import { setValue as setDistinctPersonList } from "../redux/distinctPersonListReducer"
import getCameras from "../web_api/methods/settings/cameras/getCameras";
import { setCachedCameraList } from "../redux/camerasListReducer";
import getDistinctPersons from "../web_api/methods/faceRecognitionDash/getDistinctPersons";




const ToolbarFaceRecognition = () => {
    const [cameras, setCameras] = useState<camerasList>([])
    const [distinctPersons, setDistinctPersons] = useState<string[]>([])
    const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
    const selectedPerson = useSelector((state: RootState) => state.selectedPerson);
    const cachedPersonList = useSelector((state: RootState) => state.personList.list);
    const distinctPersonList = useSelector((state: RootState) => state.distinctPersonList.list);

    const dispatch = useDispatch();


    useEffect(() => {
        document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
        loadData();
    }, [])

    // useEffect(() => {
    //     console.log(distinctPersonList)
    // }, [cachedPersonList])

    const loadData = async () => {
        try {
            let loadedCameras: camerasList = await getCameras();
            let distinctPersons: string[] = await getDistinctPersons();
            setDistinctPersons(distinctPersons)
            setCameras(loadedCameras)
            dispatch(setCachedCameraList({
                list: loadedCameras
            }));
            dispatch(setDistinctPersonList(
                {
                    list: distinctPersons
                }))
        }
        catch (e) {
            console.log(e)
        }
    };
    return (
        <div className='d-flex align-items-center overflow-auto'>
            {/* begin::Wrapper */}
            <div className='d-flex align-items-center flex-shrink-0'>
                {/* begin::Label */}
                <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
                    Camera:
                </span>
                {/* end::Label */}

                <select
                    className='form-select form-select-sm w-md-125px form-select-solid'
                    data-control='select2'
                    data-placeholder='Latest'
                    data-hide-search='true'
                    value={selectedCamera.value}
                    onChange={
                        (e) => {
                            dispatch(setSelectedCamera(
                                {
                                    value: e.target.value,
                                    id: e.target.options[e.target.selectedIndex].dataset.id!
                                }))

                            dispatch(setValue(
                                {
                                    value: 'All',
                                    id: '0'
                                }))
                        }
                    }
                >
                    <option key={0} value={'All'} data-id={0}>All</option>
                    {cameras.map((item) => (
                        <option key={item.id} value={item.name} data-id={item.id}>{item.name}</option>
                    ))}
                </select>
            </div>

            <div className='d-flex align-items-center flex-shrink-0 mx-2'>
                {/* begin::Label */}
                <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
                    Person:
                </span>
                {/* end::Label */}

                <select
                    className='form-select form-select-sm w-md-125px form-select-solid'
                    data-control='select2'
                    data-placeholder='Latest'
                    data-hide-search='true'
                    value={selectedPerson.value}
                    onChange={
                        (e) => {
                            dispatch(setSelectedPerson(
                                {
                                    value: e.target.value,
                                    id: e.target.options[e.target.selectedIndex].dataset.id!
                                }))
                        }
                    }
                >
                    <option key={0} value={'all'} data-id={0}>all</option>
                    {distinctPersons.map((item, index) => (
                        <option key={index} value={item} data-id={index}>{item}</option>
                    ))}

                </select>
            </div>
            {/* end::Wrapper */}

        </div>
    )
}

export { ToolbarFaceRecognition }
