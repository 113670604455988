/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { } from 'react'
import 'react-day-picker/dist/style.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
type Props = {
    location: { value: string, id: string }
    user: string
}

const LocationData: React.FC<Props> = ({ location, user }) => {
    const dateFilter = useSelector((state: RootState) => state.dateFilter);

    return (
        <>
            <div className='col-sm-2 me-7 mb-3'>
                <div className="fs-3qx fw-bold bg-light text-inverse-primary rounded-2 h-100 d-flex">
                    <p className='text-inverse-light m-auto'>
                        {location.id}
                    </p>
                </div>
            </div>
            <div className='flex-grow-1 col-sm-10'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-2'>
                            <a href='#' className='text-gray-800 fs-5 fw-bolder me-1'>
                                {location.value}
                            </a>
                            <a href='#'>
                            </a>
                        </div>
                        <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>{dateFilter.from === dateFilter.to ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
                                :
                                new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
                                + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}
                            </span>
                        </div>

                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                                {user}
                            </span>
                        </div>
                    </div>
                </div>

                {/* <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                        <div className='d-flex flex-wrap'>
                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-2 fw-bolder'>56.324</div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>Entries</div>
                            </div>

                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-2 fw-bolder'>175</div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>Shops</div>
                            </div>

                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-2 fw-bolder'>65%</div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>Other stats</div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export { LocationData }
