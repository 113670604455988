import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface mostQueueAlertsItemInList {
  id:number
  value: number
  name: string
  day: string
}
export interface mostQueueAlertsListState {
  list: mostQueueAlertsItemInList[]
}
const initialState: mostQueueAlertsListState = {
  list: []
};

export const mostQueueAlerts = createSlice({
  name: 'mostQueueAlerts',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<mostQueueAlertsListState>) => {
      state.list = action.payload.list
    },
  },
});

export const { setValue } = mostQueueAlerts.actions;

export const mostQueueAlertsReducer = mostQueueAlerts.reducer;
