import useGet from "../../hooks/Common/useGet"
import LoadingPage from "../../layout/LoadingPage"
import { setcachedCameraModelList } from "../../redux/cameraModelListReducer"
import getCameraModels from "../../web_api/methods/settings/cameraModels/getCameraModels"
import CameraModelsTable from "./CameraModelsTable"


const CameraModels = () => {

  const { loading } = useGet([], getCameraModels, setcachedCameraModelList)

  return (
    <>
      {
        loading ?
        <LoadingPage /> :

          <CameraModelsTable />

      }
    </>
  )
}

export default CameraModels;