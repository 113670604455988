import ApexCharts, { ApexOptions } from "apexcharts"
import { title } from "process"
import { useRef, useEffect, useState, useMemo } from "react"
import peopleCountingEntriesLocations from "../../../web_api/methods/peopleCountingDash/entriesLocations"
import entriesLocations from "../../../web_api/methods/peopleCountingDash/entriesLocations"
import { entriesLocationsList, GraphOrListEnum, ValueDateLocation } from "../../../web_api/models"
import { exportCSV, numberFormat } from "../../../../src/web_api/methods/helpers/index"
import { getCSS, getCSSVariableValue } from "../../assets/ts/_utils"
import Dropdown1 from "../../dropdown/Dropdown1"
import { KTSVG } from "../../helpers"
import { Chart1WidgetWithImage } from "../charts/Chart1WidgetWithImage"
import * as ReactBootstrap from 'react-bootstrap'
import { format } from "date-fns"
import { DateRange, DayPicker } from "react-day-picker"
import LoadingPage from "../../../layout/LoadingPage"

type Props = {
  className: string
  color: string
  img?: string
}

const DataWidgetWithImage: React.FC<Props> = ({ className, color, img }) => {
  const [GraphOrList, SetGraphOrList] = useState<GraphOrListEnum>(GraphOrListEnum.LIST)
  const [activeLocations, setActiveLocations] = useState<string[]>([])
  const [activeValues, setActiveValues] = useState<number[]>([])
  const [entries, setEntries] = useState<ValueDateLocation[]>([])
  const [days, setDays] = useState<string[]>([])
  const [sum, setSum] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [activeDay, setActiveDay] = useState<string>(new Date().toDateString())
  const [index, setIndex] = useState<number>(0)
  const [show, setShow] = useState(false);
  const defaultSelected: DateRange = {
    from: new Date(),
    to: new Date()
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [firstRange, setFirstRange] = useState<DateRange | undefined>(defaultSelected);
  const handleCloseModal = () => {
    setShow(false);
    setFirstRange(range);
  }
  const handleShowModal = () => setShow(true);

  let selectedRange = <>Date picker</>;
  if (range?.from) {
    if (!range.to) {
      // selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
      selectedRange = <>{range.from.toLocaleString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }).replace(/\//g, '-')}</>;

    } else if (range.to) {
      selectedRange = (
        (range.to.toString() === range.from.toString()) ? (
          <>
            {range.from.toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            }).replace(/\//g, '-')}
          </>)
          :
          (<>
            {/* {format(range.from, 'PPP')} - {format(range.to, 'PPP')} */}
            {range.from.toLocaleString('ro-RO', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).replace(/\//g, '-')} - {range.to.toLocaleString('ro-RO', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }).replace(/\//g, '-')}
          </>)
      );
    }
  }
  //preluare date
  useEffect(() => {
    loadEntriesLocations();
  }, [firstRange]);

  const loadEntriesLocations = async () => {
    try {
      const to = firstRange?.to === undefined ? firstRange?.from : firstRange.to
      const from = firstRange?.from
      //initializari
      let forEntries: ValueDateLocation[] = []
      let forDays: string[] = []
      let curr = 0
      let locations = new Array()
      let values = new Array()
      let uniqueDays: string[] = []
      //apel
      let loadEntriesWithLocations: entriesLocationsList = await peopleCountingEntriesLocations(from!, to!);
      //atribuiri valori
      loadEntriesWithLocations.forEach((item) => {
        forEntries.push({ value: item.entries, day: new Date(item.day).toDateString(), loc: item.loc })
        forDays.push(new Date(item.day).toDateString())
      })
      forDays.forEach((item) => {
        if (!(uniqueDays.includes(item)))
          uniqueDays.push(item)
      })
      setEntries(forEntries)
      setDays(uniqueDays)
      //activeValues and activeDay
      setIndex(uniqueDays.length - 1)
      setActiveDay(uniqueDays[uniqueDays.length - 1])
      forEntries.filter(x => x.day == uniqueDays[uniqueDays.length - 1]).forEach((item) => {
        locations.push(item.loc)
        values.push(item.value)
        curr = curr + item.value
      })
      setSum(curr)
      setActiveLocations(locations)
      setActiveValues(values)
      setLoading(true)
    }
    catch (e) {
      console.log(e)
    }
  };

  //actualizare activ
  useEffect(() => {
    let locations = new Array()
    let values = new Array()
    let curr = 0
    entries.filter(x => x.day == activeDay).forEach((item) => {
      locations.push(item.loc)
      values.push(item.value)
      curr = curr + item.value
    })
    setSum(curr)
    setActiveLocations(locations)
    setActiveValues(values)
  }, [activeDay])

  //download files
  const handleDownload = () => {
    days.forEach((day) => {
      let locations = new Array()
      let values = new Array()
      entries.filter(x => x.day == day).forEach((item) => {
        locations.push(item.loc)
        values.push(item.value)
      })
      exportCSV([['Camera', 'Entries']], locations, values, day)
    })

  };
  const changingComponent = useMemo(() => {

    return (GraphOrList == GraphOrListEnum.GRAPH) ?
      (
        <Chart1WidgetWithImage xAxis={activeLocations} yAxis={activeValues} />
      )
      :
      (
        <div className='card-body d-flex flex-column pt-0 pb-0 overflow-auto h-150px'>
          {entries.filter(x => x.day == activeDay).map((item, index) => (
            <div key={`${item.loc}-${item.value}`} className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>{item.loc}</span>
                <span>{((item.value / sum * 100) != null && (item.value / sum * 100) != undefined) && (item.value / sum * 100).toFixed(2) + '% - ' + numberFormat(item.value) + ' entries'}</span>
              </div>

              <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                <div
                  className='bg-white rounded h-8px'
                  role='progressbar'
                  style={{ width: (item.value / entries.filter(x => x.day == activeDay)[0].value) * 100 + '%' }}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )
  }, [GraphOrList, activeLocations, entries, activeDay, activeValues])


  return (
    <>
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
        style={{
          backgroundColor: color,
          backgroundImage: `url('${img}')`
        }}
      >
        <div className='card-header pt-5'>
          <div className='card-title d-flex flex-column'>
            <div className="d-flex flex-row">
              <span className='fs-1 fw-bold d-flex justify-content-start text-white me-2 lh-1 ls-n2'>Entries - {numberFormat(sum)} entries</span>
              {/* <span className='fs-2x fw-bold d-flex justify-content-start text-white me-2 lh-1 ls-n2'>{mostEntries}</span> */}
            </div>
            <span className="text-white opacity-75 pt-1 fw-semibold fs-6"> {new Date(activeDay).toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })} </span>
          </div>
          <div className='flex-shrink-0 my-auto'>
            <ul className='nav'>
              <li className='nav-item m-auto' onClick={() => { handleShowModal() }}>
                <div className={'nav-link btn btn-sm btn-color-white btn-active-color-primary btn-active-light fw-semibold fs-7 px-2 me-1'}>
                  <i className='bi bi-calendar mb-1' />
                  {selectedRange}
                </div>
              </li>
              <li className='nav-item m-auto'>
                <div className={'nav-link btn btn-sm btn-color-white btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Download'>
                  <i className="bi bi-save p-0 mb-1" onClick={handleDownload} />
                </div>
              </li>
            </ul>
          </div>
        </div>

        {
          loading ?
            (changingComponent)
            :
            (<LoadingPage />)
        }
        <div className='d-flex align-items-center flex-column py-0 w-100 card-footer'>
          {entries.length !== 0 &&
            (
              <div className="d-flex flex-row w-100">
                <div className="btn m-auto px-0" onClick={() => {
                  if (index > 0) {
                    setIndex((index) => {
                      return index - 1
                    });
                    setActiveDay(days[index - 1])
                  }
                }}>
                  <p className="text-white m-auto">Prev</p>
                  {/* <i className="bi bi-arrow-left text-white" /> */}
                </div>
                <p className='text-white opacity-75 fw-semibold fs-6 m-auto col-4 mx-1 text-center'>{activeDay}</p>
                <div className="btn m-auto px-0" onClick={() => {
                  if (index < days.length - 1) {
                    setIndex((index) => {
                      return index + 1
                    });
                    setActiveDay(days[index + 1])
                  }
                }}>
                  <p className="text-white m-auto">Next</p>
                  {/* <i className="bi bi-arrow-right text-white" /> */}
                </div>

                <div className='d-flex justify-content-end fw-bold fs-6 text-white opacity-75 w-100' >
                  <p className="fw-bold fs-7 text-white opacity-75 my-auto cursor-pointer" onClick={() => SetGraphOrList(GraphOrList === GraphOrListEnum.LIST ? GraphOrListEnum.GRAPH : GraphOrListEnum.LIST)}>View as {GraphOrList === GraphOrListEnum.LIST ? 'graph' : 'list'}</p>
                </div>
              </div>)}



        </div>
      </div>
      <ReactBootstrap.Modal size='sm' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-row'>
            <DayPicker id='1'
              fromYear={2020}
              toYear={2030}
              defaultMonth={range?.to === undefined ? range?.from : range.to}
              mode="range"
              selected={range}
              onSelect={setRange}
              footer={selectedRange}
            />
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant="secondary" onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </>
  )
}

export { DataWidgetWithImage }

