import { Icon } from 'leaflet';
import "leaflet/dist/leaflet.css";
import React, { useState, useCallback, useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
type Props = {
    className: string;
    allowMultipleMarkers?: boolean;
    allowDelete?: boolean;
    allowAdd?: boolean;
    currentMarker?: { id: number, position: { lat: number, long: number }, name: string }[]
    markers?: { id: number, position: { lat: number, long: number }, name: string }[]
    data?: { name: string, lat: number, long: number }
    onMarkerAddedOrChanged?: (name: string, lat: number, long: number) => void;
};

const LeafMap: React.FC<Props> = ({ className, allowMultipleMarkers, allowDelete, allowAdd, data, currentMarker, markers, onMarkerAddedOrChanged }) => {
    const icon = new Icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
        iconSize: [38, 38],
        iconAnchor: [19, 45]
    })
    const [addedMarkersCount, setAddedMarkersCount] = useState(0);

    const [markersState, setMarkers] = useState(markers!.filter(x => x.position.lat !== 0 && x.position.long !== 0));

    const handleDragEnd = useCallback((e: any, id: any) => {
        const { lat, lng } = e.target.getLatLng();
        const name = markersState.find(x => x.id === id)?.name
        setMarkers(prevMarkers => prevMarkers.map(marker => marker.id === id ? { ...marker, position: { lat: lat, long: lng } } : marker));

        if (onMarkerAddedOrChanged)
            onMarkerAddedOrChanged(name!, lat, lng)
    }, [markersState]);

    const handleDeleteMarker = (markerId: number, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        setMarkers((prevMarkers) => prevMarkers.filter(marker => marker.id !== markerId));
        if (!allowMultipleMarkers) {
            setAddedMarkersCount(prevCount => prevCount - 1);
        }
    };
    useEffect(() => {
        setMarkers(markers!.filter(x => x.position.lat !== 0 && x.position.long !== 0))
    }, [markers])

    const AddMarkerComponent = () => {
        const map = useMapEvents({
            click: (e) => {

                if ((!allowMultipleMarkers && addedMarkersCount >= 1) || (markersState.length >= 1 && !allowMultipleMarkers) || !allowAdd) {
                    return;
                }

                const newMarker = {
                    id: Math.random(),
                    position: { lat: e.latlng.lat, long: e.latlng.lng },
                    name: data?.name || prompt("Enter marker name:") || "Unnamed"
                };

                setMarkers((currentMarkers) => [...currentMarkers, newMarker]);
                setAddedMarkersCount(prevCount => prevCount + 1);
                if (onMarkerAddedOrChanged)
                    onMarkerAddedOrChanged(newMarker.name, newMarker.position.lat, newMarker.position.long)
            }
        });
        return null;
    };

    useEffect(() => {
        // Check if there are any markers
        if (data === undefined) return;
        if (data.lat === undefined || data.long === undefined) return;
        if (markersState.length > 0) {
            // Update the position of the existing marker
            const updatedMarker = {
                ...markersState[0],
                position: { lat: data.lat!, long: data.long! }
            };
            setMarkers([updatedMarker]);
        } else if (data.lat && data.long && data.lat !== 0 && data.long !== 0) {
            // If no markers and both lat and long are provided, add a new marker
            const newMarker = {
                id: Math.random(),
                position: { lat: data.lat, long: data.long },
                name: data?.name || "Unnamed"
            };
            setMarkers([newMarker]);
        }
    }, [data]);



    return (
        <MapContainer center={[46, 23]} zoom={5} className={`${className}`}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MarkerClusterGroup>
                {markersState.map((marker) => {
                    return (!isNaN(marker.position.lat) && !isNaN(marker.position.long)) &&
                        (
                            <Marker
                                key={marker.id}
                                icon={icon}
                                position={[marker.position.lat, marker.position.long]}
                                draggable={true}
                                eventHandlers={{
                                    dragend: (e) => handleDragEnd(e, marker.id)
                                }}
                            >
                                <Popup>
                                    <span className='d-flex flex-center'>
                                        {marker.name}
                                        {allowDelete && (
                                            <button
                                                type='button'
                                                className={`btn btn-sm btn-icon btn-color-primary btn-active-white border-0 me-n3`}
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                                onClick={(event) => handleDeleteMarker(marker.id, event)}
                                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                            >
                                                <i className='svg-icon-2 bi bi-trash fa-3x' />
                                            </button>
                                        )}

                                    </span>
                                </Popup>
                            </Marker>
                        )


                })}
            </MarkerClusterGroup>
            <AddMarkerComponent />
        </MapContainer>
    );
}
export { LeafMap }