/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import { DayWeekMonthYearEnum } from '../../web_api/models'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { numberFormat } from '../../web_api/methods/helpers'
import LoadingPage from '../../layout/LoadingPage'



type Props = {
  className: string
  chartHeight: string
  chartColor: string
  chartSecondaryColor: string
  type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap'
  filter: DayWeekMonthYearEnum,
  dates: string[],
  values: number[],
  valuesPrev: number[]
  loaded: boolean
}

const AlertsNumberChart: React.FC<Props> = ({ className, chartHeight, chartColor, chartSecondaryColor, type, filter, dates, values, valuesPrev, loaded }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)




  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor, chartSecondaryColor, dates, values, valuesPrev, type, filter))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, values, dates, valuesPrev, type])


  return (
    loaded ?

      (
        < div className={`card ${className}`}>
          <div className='card-body p-5 d-flex flex-column'>
            <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom' data-kt-chart-color={chartColor} style={{ height: chartHeight }}></div>
          </div>
        </div >
      )
      :
      (<LoadingPage />)

  )
}

const chartOptions = (chartHeight: string, chartColor: string, chartSecondaryColor: string, xAxis: string[], yAxis: number[], yAxisPrev: number[], type: 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap', filter: DayWeekMonthYearEnum): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor)
  const secondColor = getCSSVariableValue('--kt-' + chartSecondaryColor)
  let title = ''
  if (filter === DayWeekMonthYearEnum.DAY) {
    const date = new Date();
    title = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  }
  else if (filter === DayWeekMonthYearEnum.WEEK) {
    const date = new Date();
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const weekNum = Math.ceil(((date.valueOf() - firstDayOfYear.valueOf()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
    title = `Week ${weekNum} ${date.toLocaleDateString('en-US', { month: 'long' })}`;
  }
  else if (filter === DayWeekMonthYearEnum.MONTH) {
    const date = new Date();
    title = date.toLocaleDateString('en-US', { month: 'long' });
  }
  else {
    const date = new Date();
    title = date.getFullYear().toString();
  }
  return {
    series: [
      {
        name: `Current ${filter}`,
        data: yAxis,
      },
      {
        name: `Last ${filter}`,
        data: yAxisPrev,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: 'Total detections - ' + title
          }
        }
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return numberFormat(val)
        },
      },
    },
    colors: [lightColor, secondColor],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
export { AlertsNumberChart }