import React, { useEffect, useState } from 'react'
import CameraModelsFormModal from './CameraModelsFormModal'
import CameraModelsDeleteModal from './CameraModelsDeleteModal'
import CommonTable from '../Common/CommonTable/CommonTable'
import CommonTableColumn from '../Common/CommonTable/CommonTableColumn'
import CommonTableRow from '../Common/CommonTable/CommonTableRow'
import useModals from '../../hooks/Common/useModals'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { ToastContainer } from 'react-toastify'
import CommonNewUserCard from '../Common/CommonNewUserCard'

type CameraModel = {
    id: number;
    name: string;
    command: string;
    command_local: string;
};

type CameraModelData = {
    checked: number[];
    current: CameraModel;
};


const CameraModelsTable = () => {

    const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);

    const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);

    const { show, toggleModal } = useModals([false, false]); // 0 = CameraModelsFormModal, 1 = CameraModelsDeleteModal


    const [modelData, setModelData] = useState<CameraModelData>({
        checked: [],
        current: {
            id: 0,
            name: '',
            command: '',
            command_local: ''
        },
    });

    const onCheckboxChange = (modelId: number) => {
        const { checked } = modelData;
        if (checked.includes(modelId)) {
            setModelData((prevState) => ({
                ...prevState,
                checked: prevState.checked.filter((id) => id !== modelId),
            }));
        } else {
            setModelData((prevState) => ({
                ...prevState,
                checked: [...prevState.checked, modelId],
            }));
        }
    };

    const onCheckAll = () => {
        const allModelIds = cachedCameraModelList.map(model => model.id);

        if (modelData.checked.length > 0 && isCheckAllChecked) {
            setModelData(prev => ({
                ...prev,
                checked: []
            }))
        } else {
            setModelData(prev => ({
                ...prev,
                checked: allModelIds
            }))
        };
    };

    const onDelete = () => {
        setIsCheckAllChecked(false);
    };

    const onModalToggle = (index: number, modelId?: number) => {
        const modalIndex = index;
        if (!show[modalIndex]) {
            const cameraModel = modelId
                ? cachedCameraModelList.find((loc) => loc.id === modelId)
                : undefined;
            setModelData((prevState) => ({
                ...prevState,
                current: {
                    id: modelId ?? 0,
                    name: cameraModel?.name || '',
                    command: cameraModel?.command || '', 
                    command_local: cameraModel?.command_local || '',
                },
            }));
            toggleModal(modalIndex);
        } else {
            toggleModal(modalIndex);
        }
    };

    useEffect(() => {
        setModelData((prevState) => ({
            ...prevState,
            checked: prevState.checked.filter((id) => cachedCameraModelList.some((model) => model.id === id)),
        }));
    }, [cachedCameraModelList.length]);


    return (
        <>
            <ToastContainer />
            {cachedCameraModelList.length > 0 ? 
                <div className='container py-3 py-lg-6 px-8 mb-12 mb-md-0'>
                    <CommonTable
                        title="Models"
                        subtitle={`${cachedCameraModelList.length} models`}
                        addButtonText="New model"
                        hasCheckedRows={modelData.checked.length}
                        tableClassName={`${window.innerWidth > 1400 ? "table-row-dashed" : ""}`}
                        onClickAdd={() => onModalToggle(0)}
                        onClickDelete={() => onModalToggle(1)}
                        columns={
                            <>
                                <th className="col-sm-1">
                                    <div className="form-check form-check-sm form-check-custom form-check-solid my-8 my-sm-0">
                                            <input
                                                id='check-all'
                                                onClick={() => onCheckAll()}
                                                onChange={() => setIsCheckAllChecked(prev => !prev)}
                                                checked={isCheckAllChecked}
                                                className="form-check-input widget-9-check"
                                                type="checkbox"
                                                value="1"
                                            />
                                            <span className='d-sm-none ms-5 text-dark'>Check all servers</span>
                                    </div>
                                </th>
                                <CommonTableColumn className="col-xl-3 d-none d-xl-table-cell" name="Name" />
                                <CommonTableColumn className="col-xl-4 d-none d-xl-table-cell" name="Command" />
                                <CommonTableColumn className="col-xl-4 d-none d-xl-table-cell" name="Command Local" />
                                <CommonTableColumn className="col-xl-2 cursor-pointer text-center d-none d-xl-table-cell" name="Actions" />
                            </>
                        }
                        rows={
                            <>
                                {cachedCameraModelList.map((model) => (
                                    <CommonTableRow
                                        className='d-flex flex-column text-break text-xl-start d-xl-table-row'
                                        key={model.id}
                                        inputId={model.id.toString()}
                                        cellOne={model.name}
                                        cellOneTag={model.id}
                                        onCheckboxChange={() => onCheckboxChange(model.id)}
                                        checked={modelData.checked.includes(model.id)}
                                    >
                                        <td className='pb-0 d-xl-none'>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
                                                        Command
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='d-flex d-xl-table-cell'>
                                            <span className="text-muted fw-semibold text-muted fs-8">{model.command}</span>
                                        </td>
                                        <td className='pb-0 d-xl-none'>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                    <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
                                                        Command Local
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='d-flex d-xl-table-cell'>
                                            <span className="text-muted fw-semibold text-muted d-block fs-8">{model.command_local}</span>
                                        </td>
                                        <td>
                                            <div>
                                                <div className="d-flex gap-1 justify-content-end justify-content-xl-center">
                                                    <a className="btn btn-icon btn-light-warning btn-xl me-1" onClick={() => onModalToggle(0, model.id)}>
                                                        <i className="bi bi-gear" />
                                                    </a>
                                                    <a href="#" className="btn btn-icon btn-danger btn-xl" onClick={() => onModalToggle(1, model.id)}>
                                                        <i className="bi bi-trash" />
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='d-block d-xl-none'>
                                            <hr />
                                        </td>
                                    </CommonTableRow>
                                ))}
                            </>
                        }
                    />
                    <CameraModelsFormModal
                        show={show[0]}
                        onModalToggle={() => onModalToggle(0)}
                        currentModel={modelData.current}
                    />
                    <CameraModelsDeleteModal
                        show={show[1]}
                        onModalToggle={() => onModalToggle(1)}
                        modelData={modelData}
                        onDelete={onDelete}
                    />
                </div> 
                : 
                (
                    <div className="container py-3 py-lg-6 px-6 px-md-20 mb-6 flex-column flex-xl-row d-flex justify-content-center w-100">
                        <CommonNewUserCard
                            title="Add your first camera model!"
                            content={`Click on this card to add your first model.\n
                            Fill out the fields and press the "Confirm" button to register a model.`}
                            className="w-xl-50"
                            icon="bi bi-collection"
                            buttonText="Add camera model"
                            onClick={() => onModalToggle(0)}
                        />
                        <CameraModelsFormModal
                            onModalToggle={() => onModalToggle(0)}
                            show={show[0]}
                            currentModel={modelData.current}
                        />
                    </div>
                ) }

        </>

    )
}

export default CameraModelsTable