import Dropdown1 from '../../../_metronic/dropdown/Dropdown1'
import { v4 as uuidv4 } from 'uuid';
type Props = {
    buttons: React.ReactNode,
    items: React.ReactNode,
    background?: string,
    headingSmall?: string,
    headingLarge?: string,
    containerClass?: string,
    handleCheckClick?: () => void,
    handleTrashIconClick?: () => void
    style?: React.CSSProperties
}

const CommonCard: React.FC<Props> = ({
    buttons,
    items,
    background,
    headingSmall,
    headingLarge,
    containerClass,
    handleCheckClick,
    handleTrashIconClick,
    style
}) => {

    const uniqueId = uuidv4();

    return (
        <div className={`card ${containerClass}`} style={style}>
            <div className='card-body p-0'>
                {/* begin::Header */}
                <div className={`px-9 pt-7 card-rounded h-275px w-100`} style={{
                    backgroundImage: background
                }}>
                    {/* begin::Heading */}

                    <div className='d-flex flex-stack'>
                        <div className={`form-check form-check-sm form-check-custom form-check-solid ${handleCheckClick ? "" : "invisible"}`}>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                data-kt-check='true'
                                data-kt-check-target='.widget-9-check'
                                id={uniqueId}
                                onClick={handleCheckClick}
                            />
                        </div>

                        <div className={`ms-1 ${handleTrashIconClick ? "" : "invisible"}`}>
                            {/* begin::Menu */}
                            <button
                                type='button'
                                className={`btn btn-sm btn-icon btn-color-white btn-active-white border-0 me-n3`}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                onClick={handleTrashIconClick}
                            >
                                <i className='svg-icon-2 bi bi-trash fa-3x' />
                            </button>
                            {/* <Dropdown1 /> */}
                            {/* end::Menu */}
                        </div>

                    </div>
                    {/* end::Heading */}
                    {/* begin::Balance */}
                    <div className='d-flex text-center flex-column text-white pt-8'>
                        <span className='fw-semibold fs-7'>{headingSmall}</span>
                        <span className='fw-bold fs-2x pt-1'>{headingLarge}</span>
                    </div>
                    {/* end::Balance */}
                </div>
                {/* end::Header */}
                {/* begin::Items */}
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
                    style={{ marginTop: '-50px' }}
                >
                    <div>
                        {items}
                    </div>
                    {buttons}
                </div>
            </div>
            {/* begin::Body */}

            {/* end::Body */}
        </div>
    )
}

export { CommonCard }
