import { loginResBodySuccess } from "common/api/services/authService/models";
import callApiAxiosClient from "../../../axios/axios_client";

function verify2fa(otp: string, mail: string): Promise<loginResBodySuccess> {
    return callApiAxiosClient<loginResBodySuccess>({
        method: "post",
        url: "account/verify-2fa",
        headers: {
            "Content-Type": "Application/json"
        },
        data: {
            otp: otp,
            mail: mail
        }
    })
}
export default verify2fa;