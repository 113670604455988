import { CommonTooltip } from "../CommonTooltip"

type CommonCardItemProps = {
    value?: number | string,
    textLarge?: string,
    textSmall?: string,
    textLargeOnClick?: () => void
}

const CommonCardItem: React.FC<CommonCardItemProps> = ({
    value,
    textLarge,
    textSmall,
    textLargeOnClick
}) => {

    let longValue;

    if (value && value.toString().length > 12) {
        longValue = value.toString();
        value = value.toString().slice(0, 12) + "..."
    }

    return (
        <div className='d-flex align-items-center mb-6'>
            {/* <div className='symbol symbol-45px w-40px me-5 d-none'>
                <span className='symbol-label bg-lighten'>
                    <i className='svg-icon-2 bi bi-three-dots-vertical fa-3x' />
                </span>
            </div> */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1' onClick={textLargeOnClick}>
                    <a className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                        {textLarge}
                    </a>
                    <div className='text-gray-400 fw-semibold fs-7'>{textSmall}</div>
                </div>
                <div className='d-flex align-items-center'>
                    <CommonTooltip text={longValue ? longValue : value?.toString() || "Item value"} bg="" textColor="">
                        <div className='fw-bold fs-5 text-gray-800 pe-1'>{value}</div>
                    </CommonTooltip>
                    <i className='svg-icon-2 bi bi-three-dots-vertical fa-3x' />
                </div>
            </div>
        </div>
    )
}

export default CommonCardItem