/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import Dropdown1 from '../../dropdown/Dropdown1'
import { KTSVG } from '../../helpers'
import { numberFormat } from '../../../web_api/methods/helpers'
import * as ApexChartsLib from 'apexcharts';

type Props = {
    xAxis: string[]
    yAxis: number[]
}

const Treemap: React.FC<Props> = ({ xAxis, yAxis }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const [chartReady, setChartReady] = useState(false);

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, yAxis, xAxis])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, getChartOptions(250, xAxis, yAxis))

        if (chart) {
            chart.render().then(() => {
                setChartReady(true)
            }
            )
        }

        return chart
    }




    return (
        <div className='card-body p-0 d-flex flex-column overflow-hidden'>
            <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom' style={{ height: '200px' }}></div>
        </div>
    )
}

function getChartOptions(height: number, xAxis: string[], yAxis: number[]): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = '#f0416c'
    const secondaryColor = getCSSVariableValue('--kt-gray-300')
    const data = xAxis.map((item, index) => ({
        x: item,
        y: yAxis[index]
    }))

    return {
        series: [
            {
                data: data
            },
        ],
        chart: {
            type: "treemap",
            height: height,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "20px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: "bold",
                colors: ["#ffffff"],
            },
            formatter: function (val,opt) {
                return opt.value;
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',

            },
            y: {
                formatter: function (val) {
                    return numberFormat(val)
                },
            },
        },
        colors: [baseColor, secondaryColor],
    };

}

export { Treemap }