import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface camerasInListState {
    id: number
    name: string
    ip_address: string
    port: string
    user: string
    password: string
    model_id: number
    location: string
    location_id: number
    lat: number
    long: number
    has_next_page: boolean
}

export interface camerasListState {
    list: camerasInListState[]
}

const initialState: camerasListState = {
    list: localStorage.getItem("cachedCameraList") != null ? JSON.parse(localStorage.getItem("cachedCameraList") ?? '') : []
}

export const camerasList = createSlice({
    name: 'camerasList',
    initialState,
    reducers: {
        setCachedCameraList: (state, action: PayloadAction<camerasListState>) => {
            state.list = action.payload.list
            localStorage.setItem("cachedCameraList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setCachedCameraList } = camerasList.actions;

export const camerasListReducer = camerasList.reducer;
