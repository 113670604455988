import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useGet from "../../hooks/Common/useGet";
import useModals from "../../hooks/Common/useModals";

import { RootState } from "../../redux/store";
import { setCachedCameraList } from "../../redux/camerasListReducer";
import { setCachedLocationList } from "../../redux/cachedLocationsReducer";
import { setcachedCameraModelList } from "../../redux/cameraModelListReducer";
import { setCachedDetectionServerList } from "../../redux/detectionServerListReducer";

import LoadingPage from "../../layout/LoadingPage";
import CommonPagination from "../Common/CommonPagination";

import DetectionAdd from "./DetectionAdd";
import DetectionCard from "./DetectionCard";
import DetectionMassDelete from "./DetectionMassDelete";
import DetectionCardAdd from "./DetectionCardAdd";
import DetectionNewUserCards from "./DetectionNewUserCards";

import getDetections from "../../web_api/methods/settings/detections/getDetections";
import { cameraDetectionInList } from "../../web_api/models";
import getLocations from "../../web_api/methods/settings/locations/getLocations";
import getCameraModels from "../../web_api/methods/settings/cameraModels/getCameraModels";
import getCameras from "../../web_api/methods/settings/cameras/getCameras";
import getDetectionServers from "../../web_api/methods/settings/detectionServers/getDetectionServers";


const Detections = () => {

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
    const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);
    const cachedDetectionServerList = useSelector((state: RootState) => state.detectionServerList.list);

    const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
    const selectedDetectionType = useSelector((state: RootState) => state.selectedDetectionType);
    const activeLocation = useSelector((state: RootState) => state.activeLocation);

    const [checkedDetections, setCheckedDetections] = useState<number[]>([]);
    const [checking, setChecking] = useState(true);

    const [page, setPage] = useState({
        index: 0,
        size: 29,
    });

    const { data, loading, setData } = useGet(
        [selectedCamera.id, selectedDetectionType.id, activeLocation.id, page.index],
        () => getDetections(
            {
                page_index: page.index,
                page_size: page.size,
                camera_id: Number(selectedCamera.id),
                location_id: Number(activeLocation.id),
                detection_id: Number(selectedDetectionType.id)
            }
        )
    );

    const hasDetections = cachedCameraModelList.length > 0 &&
        cachedDetectionServerList.length > 0 &&
        cachedCameraList.length > 0 &&
        cachedLocationsList.length > 0 &&
        data && data.length > 0

    const { show, toggleModal } = useModals([false]);

    const dispatch = useDispatch();

    const checkExistingSettings = async () => {

        try {
            if (cachedCameraList.length === 0) {
                const cameraList = await getCameras();
                dispatch(setCachedCameraList({ list: cameraList }));
            };

            if (cachedLocationsList.length === 0) {
                const locationList = await getLocations();
                dispatch(setCachedLocationList({ list: locationList }));
            };

            if (cachedCameraModelList.length === 0) {
                const cameraModelList = await getCameraModels();
                dispatch(setcachedCameraModelList({ list: cameraModelList }));
            };

            if (cachedDetectionServerList.length === 0) {
                const detectionServerList = await getDetectionServers();
                dispatch(setCachedDetectionServerList({ list: detectionServerList }));
            };

        } catch (error) {
            console.log(error);
        } finally {
            setChecking(false);
        }
    };

    const onDetectionCheck = (id: number) => {
        setCheckedDetections((prevState) => {
            const isChecked = !prevState.includes(id);
            if (isChecked) {
                return [...prevState, id];
            } else {
                return prevState.filter((cameraId) => cameraId !== id);
            }
        });
    };

    const onDetectionDelete = (id: number) => {
        if (data) {
            const updatedList = data.filter(detection => detection.id !== id);
            setData(updatedList);
        };
    };

    const onDetectionMassDelete = () => {
        if (data) {
            const updatedList = data.filter(
                (cameraItem) => !checkedDetections.includes(cameraItem.id)
            );
            setData(updatedList);
            setCheckedDetections([]);
        }
    };

    const onDetectionAdd = (detection: cameraDetectionInList) => {
        if (data) {
            const updatedList = [detection, ...data];
            setData(updatedList);
            if (page.index !== 0) {
                setPage({ ...page, index: 0 });
            };
        };
    };

    const onShowMassDeleteModal = (detectionIds: number[]) => {
        const names: { [key: string]: string } = {};

        const filteredCameras = data?.filter(camera => detectionIds.includes(camera.id));

        filteredCameras?.forEach(camera => {
            const name = `${camera.camera_name} - ${camera.detection_name}`;
            names[camera.id.toString()] = name;
        });

        return names;
    };

    const handleIndexChange = (action: string) => {
        if (!loading) {
            if (action === 'next') {
                setPage({ ...page, index: page.index + 1 })
            } else if (action === 'previous') {
                setPage({ ...page, index: Math.max(page.index - 1, 0) })
            };
            setCheckedDetections([]);
        };
    };

    useEffect(() => {
        checkExistingSettings();
    }, []);

    useEffect(() => {
        setPage({ ...page, index: 0 });
    }, [selectedCamera.id, selectedDetectionType.id, activeLocation.id]);

    return (
        <>
            <ToastContainer />
            {checking || hasDetections === undefined || (page.index === 0 && loading) ?
                <LoadingPage />
                :
                <>
                    {hasDetections ? (
                        <>
                            <div className="container pt-3 px-4 d-flex flex-wrap justify-content-between">
                                <div className="d-flex">
                                    <DetectionAdd
                                        onDetectionAdd={onDetectionAdd}
                                        dataLength={data?.length}
                                        show={show[0]}
                                        toggleModal={() => toggleModal(0)}
                                    />
                                    <DetectionMassDelete
                                        checkedDetections={checkedDetections}
                                        onShowMassDeleteModal={onShowMassDeleteModal}
                                        onDetectionMassDelete={onDetectionMassDelete}
                                    />
                                </div>
                                <CommonPagination
                                    disablePrevious={page.index === 0 || loading}
                                    disableNext={loading || (data?.[0].has_next_page === false)}
                                    startIndex={page.index + 1}
                                    handlePrevChange={() => handleIndexChange('previous')}
                                    handleNextChange={() => handleIndexChange('next')}
                                />
                            </div>
                            {loading && !checking ?
                                <LoadingPage />
                                :
                                <>
                                    <div className="container px-sm-5">
                                        <div className="row">
                                            {data?.map((item) => (
                                                <DetectionCard
                                                    detectionItem={item}
                                                    key={item.id}
                                                    onDetectionCheck={onDetectionCheck}
                                                    onDetectionDelete={onDetectionDelete}
                                                    onDetectionAdd={onDetectionAdd}
                                                />
                                            ))}
                                            <DetectionCardAdd toggleModal={() => toggleModal(0)} />
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    ) : (
                        <>
                            <DetectionNewUserCards
                                show={show[0]}
                                toggleModal={() => toggleModal(0)}
                                dataLength={data?.length || 0}
                                onDetectionAdd={onDetectionAdd}
                            />
                        </>
                    )}
                </>
            }


        </>
    );
};

export default Detections;