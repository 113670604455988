import React, { useEffect, useState } from 'react'
import { locationsList } from '../../web_api/models'
import { ChartsWidget13 } from '../../_metronic/widgets/charts/ChartWidget13'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import queueManagementAudioAlerts from '../../web_api/methods/queueManagementDash/audioAlerts'
import getLocations from '../../web_api/methods/settings/locations/getLocations'


type Props = {
    className: string
}

const LocationsAudioAlertQueueManagement: React.FC<Props> = ({ className }) => {

    const [values, setValues] = useState<number[]>([])
    const [names, setNames] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState<string[]>([])
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const hourParameters = useSelector((state: RootState) => state.hourParameters);
    const audioAlerts = useSelector((state: RootState) => state.audioAlerts.value);
    const selectedUser = useSelector((state: RootState) => state.selectedUser);
    const dateFilter = useSelector((state: RootState) => state.dateFilter);
    const userList = useSelector((state: RootState) => state.userList);

    useEffect(() => {
        loadDaysList()
    }, [dateFilter])

    const loadDaysList = () => {
        const fromDate = new Date(dateFilter.from);
        const toDate = new Date(dateFilter.to);

        const daysList = [];
        let currentDate = fromDate;
        while (currentDate <= toDate) {
            const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00`;
            daysList.push(dateString);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setDays(daysList)
    }

    const loadAudioAlerts = async () => {
        try {
            let loadedLocations;
            let locations: locationsList = await getLocations();
            if (selectedUser.value === 'All')
                loadedLocations = locations
            else
                loadedLocations = userList.list.find(x => x.id === selectedUser.id)?.locations

            let forValues = new Array()
            let forNames = new Array()
            let forPDF = new Array()
            let sum = 0
            let audioAlerts = await queueManagementAudioAlerts(parseInt(activeLocation.id), new Date(dateFilter.from), new Date(dateFilter.to), parseInt(hourParameters.from), parseInt(hourParameters.to), selectedUser.id);
            if (activeLocation.id === "0") { // toate locatiile
                if (days.length > 1) // mai multe zile
                    loadedLocations?.forEach((location) => {
                        let pushToPDF = new Array()

                        sum = 0
                        audioAlerts.filter(x => x.location_id === location.id).forEach((item) => {
                            sum += item.total
                        })
                        forValues.push(sum)
                        forNames.push(location.id)
                        pushToPDF.push(location.id)
                        pushToPDF.push(sum)
                        forPDF.push(pushToPDF)
                    })
                else { // o singura zi
                    audioAlerts.forEach((item) => {
                        let pushToPDF = new Array()

                        forValues.push(item.total)
                        forNames.push(item.location_id)
                        pushToPDF.push(item.location_id)
                        pushToPDF.push(item.total)
                        forPDF.push(pushToPDF)
                    })
                }
            }
            else { // o locatie
                if (days.length > 1) // mai multe zile
                    audioAlerts.forEach((item) => {
                        let pushToPDF = new Array()

                        forValues.push(item.total)
                        const date = new Date(item.time)
                        const day = date.getDate();
                        const month = date.toLocaleString('default', { month: 'short' });

                        const formattedDate = `${day} ${month}`;
                        pushToPDF.push(formattedDate)
                        pushToPDF.push(item.total)
                        forPDF.push(pushToPDF)
                        forNames.push(formattedDate)
                    })
                else { // o singura zi
                    audioAlerts.forEach((item) => {
                        let pushToPDF = new Array()
                        pushToPDF.push(item.time.toString() + ':00')
                        pushToPDF.push(item.total)
                        forPDF.push(pushToPDF)
                        forValues.push(item.total)
                        forNames.push(item.time.toString() + ':00')
                    })
                }
            }
            setLoading(true)
            setValues(forValues)
            setNames(forNames)
        }
        catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        setLoading(false)
        loadAudioAlerts();
    }, [hourParameters, activeLocation, days, selectedUser])

    return (
        <ChartsWidget13
            className='animated zoomIn h-400px h-sm-100'
            loading={loading}
            xAxis={names}
            yAxis={values}
        />
    )
}


export { LocationsAudioAlertQueueManagement }