import callApiAxiosClient from "../../../axios/axios_client";

function deleteCameras(ids: number[] | null): Promise<void> {
    if (ids === null) {
        throw new Error("Invalid camera id");
    }
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "delete",
        url: "settings/cameras",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            ids: ids
        }
    })
}
export default deleteCameras;