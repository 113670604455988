import { useEffect, useState } from 'react'
import getLocations from '../../../web_api/methods/settings/locations/getLocations';
import { locationsList } from '../../../web_api/models';
import { ChartsWidget16 } from '../../../_metronic/widgets/charts/ChartsWidget16';
import { TablesWidget1 } from '../../../_metronic/widgets/tables/TablesWidget1'
import { useDispatch } from 'react-redux'
import { setValue } from '../../../redux/activeLocationReducer'
export default function Users() {
    const dispatch = useDispatch();
    const [locationsOnUser, setLocationsOnUser] = useState<locationsList>([])
    useEffect(() => {
        loadLocations()
    }, [])
    const loadLocations = async () => {
        try {
            let loadedLocations: locationsList = await getLocations();
            setLocationsOnUser(loadedLocations)
            if (loadedLocations.length == 1)
                dispatch(setValue(
                    {
                        value: loadedLocations[0].name,
                        id: loadedLocations[0].id.toString()
                    }))

        }
        catch (e) {
            console.log(e)
        }
    };
    return (
        <div className="container">
            <div className="d-flex flex-column">
                {/* Header */}
                <div className="d-flex flex-column col-sm-12 p-0">
                    <div className="d-flex flex-column w-sm-100 h-100 p-5">
                        <ChartsWidget16 className={''} locationsOnUser={locationsOnUser} />
                    </div>
                </div>
                <div className="d-flex flex-row col-sm-12 p-5">
                    <TablesWidget1 className={'flex-fill'} locationsOnUser={locationsOnUser} />
                </div>
            </div>

        </div>
    )
}
