import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { camerasInList } from "../../web_api/models";
import { CommonModal } from "../Common/CommonModal";

import deleteCamera from "../../web_api/methods/settings/cameras/deleteCameras";
import useModals from "../../hooks/Common/useModals";
import CameraDetectionAddModal from "./CameraDetectionAddModal";
import { CommonCard } from "../Common/CommonCard/CommonCard";
import CommonCardButton from "../Common/CommonCard/CommonCardButton";
import CommonCardItem from "../Common/CommonCard/CommonCardItem";
import useDelete from "../../hooks/Common/useDelete";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
    onCameraCheck: (id: number) => void,
    onCameraDelete: (id: number) => void,
    cameraItem: camerasInList,
};

const CameraCard: React.FC<Props> = ({ cameraItem, onCameraCheck, onCameraDelete }) => {

    const { show, toggleModal } = useModals([false, false]);    
    const cachedDetectionServerList = useSelector((state: RootState) => state.detectionServerList.list);

    const { deleteItems: handleDeleteCamera, errors, isDeleting } = useDelete(
        () => deleteCamera([cameraItem.id]),
        () => {
            onCameraDelete(cameraItem.id);
            toast.success('Camera deleted successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
    );
    
    useEffect(() => {
        if (errors) {
            toast.error(`${errors}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        };
    }, [errors?.length])



    return (
            <div className="animated zoomIn col-12 col-md-6 col-xxl-4" >
            <CommonCard
                key={cameraItem.id}
                containerClass={`my-3 h-min-300px animated ${window.innerWidth > 576 ? 'hover-grow' : ''} w-100`}
                background='url("https://images.unsplash.com/photo-1601682633973-a76aa17a7844?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2200&q=80")'
                headingLarge={cameraItem.name}
                headingSmall={cameraItem.ip_address}
                handleCheckClick={() => onCameraCheck(cameraItem.id)}
                handleTrashIconClick={() => toggleModal(0)}
                items={
                    <>
                        <CommonCardItem
                            textLarge="Port"
                            textSmall=""
                            value={cameraItem.port}
                        />
                        <CommonCardItem
                            textLarge="Camera ID"
                            textSmall=""
                            value={cameraItem.id}
                        />
                        <CommonCardItem
                            textLarge="User"
                            textSmall=""
                            value={cameraItem.user}
                        />
                    </>
                }
                buttons={
                    <>
                        <CommonCardButton
                            handleClick={() => cachedDetectionServerList.length > 0 ? toggleModal(1) : null}
                            text="Add detection"
                            icon="bi bi-clipboard2"
                            disabledButton={cachedDetectionServerList.length === 0}
                            disabledButtonTooltip="Please add a detection server first."
                        />
                        <CommonCardButton
                            text="Edit camera settings"
                            link={`${process.env.REACT_APP_HOMEPAGE}/cameras/${cameraItem.id}`}
                            icon="bi bi-clipboard2"
                        />
                    </>
                }
            />
                <CommonModal
                    title={"Confirm"}
                    show={show[0]}
                    disabled={isDeleting}
                    showModalCallback={() => toggleModal(0)}
                    closeButton={true}
                    confirmButton={true}
                    bodyClass="flex-column w-100"
                    closeButtonOptions={{
                        icon: 'bi bi-x',
                        variant: 'secondary',
                        class: "",
                    }}
                    confirmButtonOptions={{
                        icon: "bi bi-trash3 text-white",
                        variant: 'custom',
                        class: "bg-danger text-white",
                        onClick: handleDeleteCamera
                    }}
                >
                    <div className="text-justify">Are you sure you want to delete the following camera?</div>
                    <div className="fw-bolder mt-2">{cameraItem.name}</div>
                </CommonModal>
                <CameraDetectionAddModal
                    show={show[1]}
                    toggleModal={() => toggleModal(1)}
                    camera={{
                        name: cameraItem.name,
                        id: cameraItem.id
                    }}
                />
            </div>
    )
}

export default CameraCard