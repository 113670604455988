import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface selectedPersonState {
  value: string
  id: string
}

const initialState: selectedPersonState = {
  id: localStorage.getItem('selectedPersonId')!?.toString() != null ? localStorage.getItem('selectedPersonId')!?.toString() : '0',
  value: localStorage.getItem('selectedPersonName')!?.toString() != null ? localStorage.getItem('selectedPersonName')!?.toString() : 'all'
};

export const selectedPerson = createSlice({
  name: 'selectedPerson',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<selectedPersonState>) => {
      state.id = action.payload.id
      state.value = action.payload.value
      localStorage.setItem("selectedPersonId", action.payload.id)
      localStorage.setItem("selectedPersonName", action.payload.value)

    },
  },
});

export const { setValue } = selectedPerson.actions;

export const selectedPersonReducer = selectedPerson.reducer;
