import { loginResBodySuccess } from "common/api/services/authService/models";
import callApiAxiosClient from "../../axios/axios_client";


function loginWeb(user_name:string,password:string): Promise<loginResBodySuccess> {
    return callApiAxiosClient<loginResBodySuccess>({
        method: "post",
        url: "account/login",
        headers: {
            "Content-Type": "Application/json",
        },
        data:{
            user_name:user_name,
            password:password
        }
    })
}
export default loginWeb;