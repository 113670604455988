import React, { useEffect, useState } from 'react'
import { AlertTypesChart } from './AlertTypesChart';
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
type Props = {
    className?: string
    filter: string
};

const AlertTypes: React.FC<Props> = ({ className, filter }) => {
    const dayWeekMonthYear = convertStringToEnumValue(filter)//dependency
    const activeLocation = useSelector((state: RootState) => state.activeLocation)
    const selectedUser = useSelector((state: RootState) => state.selectedUser)
    //dependencies
    const [values, setValues] = useState<number[]>([])
    const [labels, setLabels] = useState<string[]>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadGenderReports(new Date(), new Date())
        // console.log(filter)
    }, [dayWeekMonthYear, activeLocation, selectedUser])
    let loadGenderReports = async (from: Date, to: Date) => {
        try {
            setLoading(false)
            // let loadGender: genderResponse = await peopleCountingGender(from, to);
            //call here
            setValues([230, 400, 100, 500])
            setLabels(['Smoking in proximity to pump', 'Pump unattended', 'Blocked exit detected', 'Unsafe driving speed'])
            setTotal(1230)
            setLoading(true)


        }
        catch (e) {
            console.log(e)
        }
    };

    return (
        <div className={`card h-100 ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Alert Types</span>
                    <span className='text-muted fw-semibold fs-7'>Alert types percentage</span>
                </h3>
            </div>
            <div className='card-body p-0'>
                <div className="d-flex flex-row w-sm-100 h-100 overflow-hidden">
                    <div className="d-flex flex-column m-auto">
                        <AlertTypesChart
                            className='h-md-100 mb-xl-5 ' values={values} labels={labels} total={total} loading={loading} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export { AlertTypes }