import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TotalQueueAlertsState {
  value: string
}
const initialState: TotalQueueAlertsState = {
  value: localStorage.getItem('totalQueueAlerts')!?.toString()!=null?localStorage.getItem('totalQueueAlerts')!?.toString():'undefined'
};

export const totalQueueAlerts = createSlice({
  name: 'totalQueueAlerts',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<TotalQueueAlertsState>) => {
      state.value = action.payload.value
      localStorage.setItem("totalQueueAlerts", action.payload.value)

    },
  },
});

export const { setValue } = totalQueueAlerts.actions;

export const totalQueueAlertsReducer = totalQueueAlerts.reducer;
