import callApiAxiosClient from "../../../axios/axios_client";
import { detectionRegion } from "../../../models";


async function getDetectionRegions(id?: string): Promise<detectionRegion> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<detectionRegion>({
        method: "get",
        url: "settings/get-detection-regions",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params: {
            detection_setting_id: id
        }
    })
}
export default getDetectionRegions;