import { useState } from "react";
import { toast } from "react-toastify";

import { personItemInList } from "../../web_api/models";
import { CommonModal } from "../Common/CommonModal";
import FaceRecognitionCardInfo from "./FaceRecognitionCardInfo";
import CanvasComponent from "./CanvasComponent";

type Props = {
    onPersonCheck: (id: string) => void
    onPersonDelete: (id: string) => void
    personItem: personItemInList
    filename: string
    isNew: boolean
}

const FaceRecognitionCard: React.FC<Props> = ({ personItem, onPersonCheck, onPersonDelete }) => {

    const [showDelete, setShowDelete] = useState(false);
    const [showPicture, setShowPicture] = useState(false);

    const handleToggleDeleteModal = () => {
        setShowDelete((prevShowDelete) => !prevShowDelete);
    };
    const handleSeePictureModal = () => {
        setShowPicture((prevShowPicture) => !prevShowPicture);
    };

    const handleDeleteAlert = async () => {
        try {
            onPersonDelete(personItem.detection_id);
            toast.success('Alert deleted successfully!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: 2000
            });
        }
        catch (e) {
            console.log(e)
            toast.error(`${e}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
            });
        }
    };

    // useEffect(() => {
    //     if (pictureRef.current && canvasRef.current) {
    //         const width = pictureRef.current.offsetWidth;
    //         const height = pictureRef.current.offsetHeight;

    //         const actualWidth = 3840; // original image width
    //         const actualHeight = 2160; // original image height


    //         let x1 = personItem.bbox1 * width / actualWidth;
    //         let y1 = personItem.bbox2 * height / actualHeight;
    //         let x2 = personItem.bbox3 * width / actualWidth;
    //         let y2 = personItem.bbox4 * height / actualHeight;

    //         // Calculate width and height of the rectangle
    //         var rectWidth = x2 - x1;
    //         var rectHeight = y2 - y1;

    //         const ctx = canvasRef.current.getContext('2d');
    //         if (ctx) {
    //             ctx.beginPath();
    //             ctx.rect(x1, y1, rectWidth, rectHeight);
    //             ctx.strokeStyle = 'yellow';
    //             ctx.lineWidth = 3;
    //             ctx.stroke();
    //         }
    //     }
    //     const img = imgRef.current;
    //     if (!img) return;
    //     img.onload = function () {
    //         const canvas = canvasRef.current;
    //         if (!canvas) return;  // Add this line

    //         const ctx = canvas.getContext('2d');
    //         if (!ctx) return;
    //         // Make the canvas the same dimensions as the image
    //         canvas.width = img.width;
    //         canvas.height = img.height;

    //         // Draw the image onto the canvas
    //         ctx.drawImage(img, 0, 0, img.width, img.height);

    //         // Then you can draw more things on the canvas here
    //         // For example, draw a rectangle
    //         ctx.rect(50, 50, 100, 100);
    //         ctx.strokeStyle = 'red';
    //         ctx.stroke();
    //     };

    // }, []);

    return (
        <>
            <div className="d-flex flex-column col-12 col-md-4 my-3 h-min-300px animated zoomIn" >
                <div
                    className="card  p-0 animated hover-grow w-xxl-370px"
                    style={{
                        margin: '1rem',
                        boxShadow:
                            '0 -1px 1px 0 rgba(0,0,0,.05), 0 1px 2px 0 rgba(0,0,0,.2)',
                    }}
                >
                    <div style={{ height: "3em" }}>
                        <div className="form-check position-relative" style={{ top: "25%", left: '1.5%' }}>
                            <input className="form-check-input flexCheckDefault" type="checkbox" value="" name="check" onClick={() => onPersonCheck(personItem.detection_id)} />
                        </div>
                        <div className="position-absolute" style={{ top: "1.8%", right: "1%" }}>
                            <i role="button" className="bi bi-trash-fill text-danger" style={{ fontSize: "1.5em" }} onClick={handleToggleDeleteModal} />
                        </div>
                    </div>

                    <div
                        className="position-absolute cursor-pointer"
                        onClick={() => handleSeePictureModal()}
                        style={{
                            height: '350px',
                            width: '100%',
                            top: "39px",
                            backgroundColor: 'transparent',
                            zIndex: '',
                            opacity: '0',
                            transition: 'opacity 0.2s',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                            e.currentTarget.style.opacity = '0.8';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.opacity = '0';
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                            <button
                                type="button"
                                className="btn rounded btn-outline-primary border text-white"
                                style={{ opacity: '1', transition: 'opacity 0.4s' }}
                            >
                                View Image
                            </button>
                        </div>
                    </div>

                    <div>
                        <CanvasComponent

                            // imageUrl={`/alerts-media/2b04e972-e0a2-4592-a9c5-bd3d03424cfd/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                            imageUrl={personItem.filename}
                            actualHeight={personItem.picture_height}
                            actualWidth={personItem.picture_width}
                            bboxes={[{ x1: personItem.bbox1, y1: personItem.bbox2, x2: personItem.bbox3, y2: personItem.bbox4 }]}
                            canvasHeight={"350px"}
                            canvasWidth={"100%"} />
                    </div>
                    <FaceRecognitionCardInfo backgroundColor="secondary" textColor="inverse-secondary" personItem={personItem} />
                </div>
                <CommonModal
                    title={"Confirm"}
                    show={showDelete}
                    showModalCallback={handleToggleDeleteModal}
                    closeButton={true}
                    confirmButton={true}
                    bodyClass="flex-column w-100"
                    closeButtonOptions={{
                        icon: 'bi bi-x',
                        variant: 'secondary',
                        class: "",
                    }}
                    confirmButtonOptions={{
                        icon: "bi bi-trash3",
                        variant: 'custom',
                        class: "bg-danger text-white",
                        onClick: handleDeleteAlert
                    }}
                >
                    <div className="text-justify">Are you sure you want to delete the following alert?</div>
                    <div className="fw-bolder mt-2">{personItem.detection_id}</div>
                </CommonModal>

                <CommonModal
                    size='xl'
                    title={personItem.person}
                    show={showPicture}
                    showModalCallback={handleSeePictureModal}
                    closeButton={true}
                    confirmButton={false}
                    bodyClass="flex-column w-100"
                    closeButtonOptions={{
                        icon: 'bi bi-x',
                        variant: 'secondary',
                        class: "",
                    }}
                    confirmButtonOptions={{
                        icon: "bi bi-trash3",
                        variant: 'custom',
                        class: "bg-danger text-white",
                        onClick: handleDeleteAlert
                    }}
                >
                    <CanvasComponent

                        // imageUrl={`/alerts-media/2b04e972-e0a2-4592-a9c5-bd3d03424cfd/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                        imageUrl={personItem.filename}
                        actualHeight={personItem.picture_height}
                        actualWidth={personItem.picture_width}
                        bboxes={[{ x1: personItem.bbox1, y1: personItem.bbox2, x2: personItem.bbox3, y2: personItem.bbox4 }]}
                        canvasHeight={"500px"}
                        canvasWidth={"100%"} />
                    {/* <div>
                    <img
                        ref={imgRef}
                        className='w-100'
                        src={`/alerts-media/2b04e972-e0a2-4592-a9c5-bd3d03424cfd/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${filename}`}
                        alt="img"
                        // Hide the image
                    />
                    <canvas ref={cnvRef} />
                </div>
                {isNew && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'green',
                        color: 'white',
                        padding: '5px 10px',
                        borderBottomLeftRadius: '5px',
                        fontSize: '16px',
                        zIndex: 2,  // make sure it's above all other elements
                    }}>
                        New
                    </div>
                )} */}
                </CommonModal >

            </div >
        </>

    )
}

export default FaceRecognitionCard