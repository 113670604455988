import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface locationsInListState {
    id: number,
    location_type: number | null,
    city_id: number,
    name: string | null, 
    type: string | null,
    lat: number | null,
    long: number | null,
    tenant_id: number | null
}

export interface locationsState {
    list: locationsInListState[]
}

const initialState: locationsState = {
    list: localStorage.getItem("cachedLocationList") != null ? JSON.parse(localStorage.getItem("cachedLocationList") ?? '') : []
}

export const cachedLocations = createSlice({
    name: 'cachedLocations',
    initialState,
    reducers: {
        setCachedLocationList: (state, action: PayloadAction<locationsState>) => {
            state.list = action.payload.list
            localStorage.setItem("cachedLocationList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setCachedLocationList } = cachedLocations.actions;

export const cachedLocationsReducer = cachedLocations.reducer;
