/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils'
import { DayWeekMonthYearEnum, entriesHoursList } from '../../../web_api/models'
import peopleCountingEntriesHours from '../../../web_api/methods/peopleCountingDash/entriesHours'
import * as ReactBootstrap from 'react-bootstrap'
import { numberFormat } from '../../../web_api/methods/helpers'
import { randomInt } from 'crypto'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import LoadingPage from '../../../layout/LoadingPage'


type Props = {
    className: string,
    xAxis: string[],
    yAxis1: number[],
    yAxis2: number[],
    loading: boolean
    oneLocationSelected: boolean
}

const ChartsWidget15: React.FC<Props> = ({ className, xAxis, yAxis1, yAxis2, loading, oneLocationSelected }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const range = useSelector((state: RootState) => state.dateFilter);
    const hourParameters = useSelector((state: RootState) => state.hourParameters);
    const activeLocation = useSelector((state: RootState) => state.activeLocation);

    let selectedRange = <>Date picker</>;
    if (range?.from) {
        if (!range.to) {
            range.to = range.from
            // selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
            selectedRange = <>{new Date(range.from).toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }).replace(/\//g, '-')}</>;

        } else if (range.to) {
            selectedRange = (
                (range.to.toString() === range.from.toString()) ? (
                    <>
                        {new Date(range.from).toLocaleString('en-US', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        }).replace(/\//g, '-')}
                    </>
                )
                    :
                    (<>
                        {/* {format(range.from, 'PPP')} - {format(range.to, 'PPP')} */}
                        {new Date(range.from).toLocaleString('ro-RO', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }).replace(/\//g, '-')} - {new Date(range.to).toLocaleString('ro-RO', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }).replace(/\//g, '-')}
                    </>
                    )
            );
        }
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, xAxis, yAxis1, yAxis2])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, xAxis, yAxis1, yAxis2))
        if (chart) {
            chart.render()
        }

        return chart
    }

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Average times</span>
                    <span className='text-muted fw-semibold fs-7'>{oneLocationSelected?activeLocation.value:'All locations'}</span>
                </h3>
                <div className='d-flex align-items-center flex-shrink-0'>
                    <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
                        Filtered by:
                    </span>
                    <div className='flex-shrink-0 '>
                        <ul className='nav'>
                            <li className='nav-link bg-light text-muted rounded fw-semibold fs-7 px-2 me-1'>
                                {selectedRange} | {((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00')}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='card-body pt-0'>
                {
                    loading ?
                        (<div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '450px' }} />)
                        :
                        (<LoadingPage />)
                }
            </div>
        </div>
    )
}

export { ChartsWidget15 }

function getChartOptions(height: number, xAxis: string[], yAxis1: number[], yAxis2: number[]): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-primary')
    const secondaryColor = getCSSVariableValue('--kt-gray-300')


    return {
        series: [
            {
                name: 'Response time',
                data: yAxis1,
            },
            {
                name: 'Queue duration',
                data: yAxis2,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '30%',
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: xAxis,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                    fontWeight: 700
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                    fontWeight: 700
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return numberFormat(val) + ' minutes'
                },
            },
        },
        colors: ['#7239EA', '#F1416C'],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: false,
                },
            },
            xaxis: {
                lines: {
                    show: true
                }
            }
        },
    }
}
