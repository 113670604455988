import callApiAxiosClient from "../../axios/axios_client";
import { personList } from "../../models";


async function getPersons(person?: string, cameraId?: number, pageIndex?: number, pageSize?: number): Promise<personList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<personList>({
        method: "get",
        url: "programmability/get-persons",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            person: person,
            cameraId: cameraId,
            pageIndex: pageIndex,
            pageSize: pageSize
        }
    })
}
export default getPersons;