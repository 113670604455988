import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CameraAdd from "./CameraAdd";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";
import CommonNewUserCard from "../Common/CommonNewUserCard";

type CameraNewUserCardsProps = {
    show: boolean,
    toggleModal: () => void,
    onCameraAdd: () => void
}

const CameraNewuserCards: React.FC<CameraNewUserCardsProps> = ({
    show,
    toggleModal,
    onCameraAdd
}) => {

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
    const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);
    
    const { setToolbar } = useToolbarSetter();

    return (
        <div className="container py-3 py-lg-6 px-6 px-md-20 mb-6">
            {cachedCameraList.length === 0 && cachedLocationsList.length > 0 && cachedCameraModelList.length > 0 ?
                <CameraAdd
                    show={show}
                    toggleModal={toggleModal}
                    onCameraAdd={onCameraAdd}
                />
                :
                <div className="row row-cols-xl-2 g-5 justify-content-space-between">
                    {cachedLocationsList.length === 0 ?
                        <div className="mx-auto" style={{ minHeight: '450px' }}>
                            <CommonNewUserCard
                                title="Get started with cameras!"
                                content={`Before you can add cameras, you must add a location.\n
                                    Click on this card and follow the instructions to get started.`}
                                className="h-100"
                                icon="bi bi-houses"
                                link={`${process.env.REACT_APP_HOMEPAGE}/locations`}
                                buttonText="To Locations"
                                onClick={() => setToolbar("locations", "Locations")}
                            />
                        </div>
                        : null}
                    {cachedCameraModelList.length === 0 ?
                        <div className="mx-auto" style={{ minHeight: '450px' }}>
                            <CommonNewUserCard
                                title="Get started with cameras!"
                                content={`Before you can add cameras, you must add a camera model.\n
                                        Click on this card and follow the instructions to get started.`}
                                className="h-100"
                                icon="bi bi-collection"
                                link={`${process.env.REACT_APP_HOMEPAGE}/camera-models`}
                                buttonText="To Camera Models"
                                onClick={() => setToolbar("camera-models", "Camera Models")}
                            />
                        </div>
                        : null}
                </div>

            }

        </div>
    )
}

export default CameraNewuserCards