import useModals from "../../../hooks/Common/useModals";
import { CommonModal } from "../../Common/CommonModal";
import CommonReport from "../../Common/CommonReport";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CommonPlayer from "../../Common/CommonPlayer";
import { useEffect, useState } from "react";
import { lfShelvesStatusList } from "../../../web_api/models";
import lfShelvesStatus from "../../../web_api/methods/fruits-vegetables/lfShelvesStatus";
import * as ReactBootstrap from 'react-bootstrap'
import AlertCanvasComponent from "../../Alerts/AlertCanvasComponent";
import LoadingPage from "../../../layout/LoadingPage";
import getAzureFile from "../../../web_api/methods/files/getAzureFile";


const FruitsVegetablesImages = () => {

    const params = useParams();
    const locationId = Number(params.locationId);
    const { show, toggleModal } = useModals([false]);
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
    const currentLocation = cachedLocationsList.list.find(location => location.id === locationId);
    const [playerProgress, setPLayerProgress] = useState(0);
    const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout>();
    const [loading, setLoading] = useState(false)
    const [imageArray, setImageArray] = useState<string[]>([])
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
    const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter);
    const tenantKey = useSelector((state: RootState) => state.user.tenant_key);
    const [currentImage, setCurrentImage] = useState<string>('');

    const loadShelveStatus = async () => {
        try {
            setLoading(true)
            let forImageArray = new Array()
            let lfShelvesStatusRes: lfShelvesStatusList = await lfShelvesStatus(parseInt(activeLocation.id), new Date(toolbarDateFilter.date), parseInt(toolbarHourFilter.hour), parseInt(toolbarHourFilter.toHour));
            // lfShelvesStatusRes.forEach((item) => {
            //     let newImage = `/alerts-media-lf/${tenantKey}/${new Date(toolbarDateFilter.date).getFullYear()}/${new Date(toolbarDateFilter.date).getMonth() + 1}/${item.filename}`;
            //     if (!forImageArray.includes(newImage)) {
            //         forImageArray.push(newImage);
            //     }
            //     setCurrentImage(`/alerts-media-lf/${tenantKey}/${new Date(toolbarDateFilter.date).getFullYear()}/${new Date(toolbarDateFilter.date).getMonth() + 1}/${lfShelvesStatusRes[0].filename}`)
            // })
            let uniqueFilenames = new Set();

            lfShelvesStatusRes.forEach(async (item) => {
                if (!uniqueFilenames.has(item.filename)) {
                    // Add the unique filename to the set
                    uniqueFilenames.add(item.filename);
            
                    let newImage : string = await getAzureFile(`alerts-media-lf/${tenantKey}/${new Date(toolbarDateFilter.date).getFullYear()}/${new Date(toolbarDateFilter.date).getMonth() + 1}/${item.filename}`);
                    
                    if (!forImageArray.includes(newImage)) {
                        forImageArray.push(newImage);
                    }
                    setCurrentImage(forImageArray[0]);
                }
            });
            
            setImageArray(forImageArray);
            setLoading(false)
        }
        catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        loadShelveStatus()
    }, [activeLocation.id, toolbarDateFilter.date, toolbarHourFilter])

    const handlePlayerClick = () => {

        const runPlayer = () => {

            let currentIndex = imageArray.indexOf(currentImage)

            if (currentIndex === -1) {
                currentIndex = 0
            }

            let timeoutId;

            const setNextImage: () => NodeJS.Timeout | undefined = () => {
                if (currentIndex >= imageArray.length) {
                    setCurrentImage(imageArray[0])
                    setPLayerProgress(0)
                    setTimeoutIds(undefined);
                    return;
                }

                setCurrentImage(imageArray[currentIndex]);
                currentIndex++
                setPLayerProgress((currentIndex / imageArray.length) * 100)

                timeoutId = setTimeout(setNextImage, 1500);

                setTimeoutIds(timeoutId);
                return timeoutId;
            };
            timeoutId = setNextImage();
        };

        if (timeoutIds) {
            clearTimeout(timeoutIds);
            setTimeoutIds(undefined);
        } else {
            runPlayer();
        }
    };

    return (
        <>
            <div className="mb-5 d-flex justify-content-center">
                <div className="w-100 bg-body rounded">
                    <CommonReport
                        leftTitle={`Shelves`}
                    >
                        {
                            !loading ?
                                (
                                    <>
                                        <img
                                            className="w-100 img-fluid rounded"
                                            src={currentImage}
                                        />
                                        {/* <AlertCanvasComponent

                                            // imageUrl={`https://img.freepik.com/free-photo/waist-up-portrait-handsome-serious-unshaven-male-keeps-hands-together-dressed-dark-blue-shirt-has-talk-with-interlocutor-stands-against-white-wall-self-confident-man-freelancer_273609-16320.jpg?size=626&ext=jpg`}
                                            imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${currentImage}`}
                                            actualHeight={3840}
                                            actualWidth={2160}

                                            canvasHeight={"500px"}
                                            canvasWidth={"100%"}
                                            multiple_bboxes={[]} big={true} /> */}
                                        <div className="py-7">
                                            <CommonPlayer
                                                handlePlayerClick={handlePlayerClick}
                                                playerProgress={playerProgress}
                                                timeoutIds={timeoutIds}
                                            />
                                        </div>
                                    </>
                                )
                                :
                                (<LoadingPage />)
                        }

                    </CommonReport>
                </div>
            </div>

            <CommonModal
                title={currentLocation?.name || "last status"}
                size='xl'
                show={show[0]}
                showModalCallback={() => toggleModal(0)}
                closeButton={true}
                confirmButton={true}
                bodyClass="flex-column w-100 ps-15 pe-15 pb-10"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: "bi bi-trash3",
                    variant: '',
                    class: "d-none",
                }}
            >
                <img
                    className='w-100 img-fluid rounded'
                    src={currentImage}
                />
            </CommonModal>
        </>
    );
}

export default FruitsVegetablesImages;