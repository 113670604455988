import React, { useEffect, useState } from 'react'
import { mostQueueAlertsList } from '../../web_api/models';
import { ChartsWidget5 } from '../../_metronic/widgets/charts/ChartsWidget5';
import bgred from '../../assets/images/wave-bg-red.svg'
import queueManagementMostQueueAlerts from '../../web_api/methods/queueManagementDash/mostQueueAlerts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setValue } from '../../redux/mostQueueAlertsReducer';
import { setValue as setTotalQueueAlerts } from '../../redux/totalQueueAlertsReducer';
import { mostQueueAlertsFormat } from '../../web_api/methods/helpers';
import queueManagementAvgCashRegisterOpen from '../../web_api/methods/queueManagementDash/avgCashRegisterOpen';


type Props = {
    className: string
    from: Date
    to: Date
    callbackData: (list: mostQueueAlertsList) => void
}

const MostQueueAlertsQueueManagement: React.FC<Props> = ({ className, from, to, callbackData }) => {
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const dateFilter = useSelector((state: RootState) => state.dateFilter);
    const hourParameters = useSelector((state: RootState) => state.hourParameters);
    const queueDurationAllLocations = useSelector((state: RootState) => state.queueDurationAllLocations.value);

    const [mostQueueAlerts, setMostQueueAlerts] = useState<mostQueueAlertsList>([])
    const mostQueueAlertsList = useSelector((state: RootState) => state.mostQueueAlerts.list);
    const [queueDuration, setQueueDuration] = useState<string>('No data')
    const [loading, setLoading] = useState(false)
    const selectedUser = useSelector((state: RootState) => state.selectedUser);


    const dispatch = useDispatch();

    useEffect(() => {
        const test = mostQueueAlertsList
        const list = mostQueueAlertsFormat(test)
        setMostQueueAlerts(list)
    }, [mostQueueAlertsList])

    useEffect(() => {
        setLoading(false)
        loadMostQueueAlerts();
    }, [activeLocation, dateFilter, hourParameters, selectedUser]);

    const loadMostQueueAlerts = async () => {
        try {
            let mostQueueAlerts: mostQueueAlertsList = await queueManagementMostQueueAlerts(parseInt(activeLocation.id), from, to, parseInt(hourParameters.from), parseInt(hourParameters.to), selectedUser.id);
            callbackData(mostQueueAlerts)
            let total = 0
            dispatch(setValue({
                list: mostQueueAlerts
            }))
            mostQueueAlerts.forEach((item) => {
                total += item.value
            })
            dispatch(setTotalQueueAlerts({
                value: total.toString()
            }))
            let avgTime = await queueManagementAvgCashRegisterOpen(parseInt(activeLocation.id), from, to);
            setQueueDuration(avgTime.queue_duration)
            setLoading(true)

        }
        catch (e) {
            console.log(e)
        }
    };
    return (
        <ChartsWidget5
            className={className}
            description='Active Projects'
            color='#F1416C'
            img={bgred}
            icon='bi bi-bag-check-fill'
            data={{ number: mostQueueAlerts[0]?.value, time: activeLocation.id === "0" ? queueDurationAllLocations : queueDuration }}
            title='Most queue alerts'
            label={mostQueueAlerts[0]?.name}
            subtitle='Queue duration'
            loading={loading}
        />
    )
}
export { MostQueueAlertsQueueManagement }