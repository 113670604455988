import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from "../../redux/store";
import { personItemInList, personItemInListObj, personList } from "../../web_api/models";
import * as ReactBootstrap from 'react-bootstrap'

import FaceRecognitionCard from "./FaceRecognitionCard";
import getPersons from "../../web_api/methods/faceRecognitionDash/getPersons";
import { setCachedPersonList } from "../../redux/personListReducer";
import PersonsMassDelete from "./PersonMassDelete";
import deleteAlertDetail from "../../web_api/methods/alerts/deleteAlertDetail";
import { CommonModal } from "../Common/CommonModal";
import CanvasComponent from "./CanvasComponent";
import useModals from "../../hooks/Common/useModals";
import useGet from "../../hooks/Common/useGet";
import LoadingPage from "../../layout/LoadingPage";

const FaceRecognition = () => {
  const cachedPersonList = useSelector((state: RootState) => state.personList.list);
  const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
  const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
  const selectedPerson = useSelector((state: RootState) => state.selectedPerson);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const [personItem, setPersonItem] = useState<personItemInList>(new personItemInListObj())
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [checkedPersons, setCheckedPersons] = useState<string[]>([]);
  const [showPicture, setShowPicture] = useState(false);
  const [hasLogged, setHasLogged] = useState(false);

  const { show, toggleModal } = useModals([false]);

  const { loading, loadItems } = useGet(
    [pageIndex, hasLogged],
    () =>
      getPersons(
        selectedPerson.value,
        parseInt(selectedCamera.id),
        pageIndex,
        pageSize
      ),
    setCachedPersonList,
  );

  let loadPersons = loadItems;

  const handleSetModalPerson = (personItem: personItemInList) => {
    setPersonItem(personItem)
  };
  const onPersonDelete = (id: string) => {
    const updatedPersonList = cachedPersonList.filter(
      (personItem) => personItem.detection_id !== id
    );
    updatedPersonList.reverse();
    deleteAlertDetail(id)
    dispatch(setCachedPersonList({ list: updatedPersonList }));
  };

  const onPersonMassDelete = () => {
    const updatedPersonList = cachedPersonList.filter(
      (personItem) => !checkedPersons.includes(personItem.detection_id)
    );
    updatedPersonList.reverse();
    dispatch(setCachedPersonList({ list: updatedPersonList }));
    setCheckedPersons([]);
  };

  const onPersonCheck = (id: string) => {
    setCheckedPersons((prevState) => {
      const isChecked = !prevState.includes(id);
      if (isChecked) {
        return [...prevState, id];
      } else {
        return prevState.filter((personId) => personId !== id);
      }
    });
  };

  const onShowMassDeleteModal = (personIds: string[]) => {
    return personIds;
  };

  const handleNext = () => {
    setPageIndex(prevPageIndex => prevPageIndex + 1);
  }

  const handlePrev = () => {
    setPageIndex(prevPageIndex => Math.max(prevPageIndex - 1, 0));
  }

  useEffect(() => {
    setPageIndex(0); // reset page index when selectedUser changes
    loadPersons();
  }, [selectedCamera.id, selectedPerson.value]);


  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        if (pageIndex === 0 && selectedCamera.value === 'All' && selectedPerson.value === 'all') {

          let loadedPersons: personList = await getPersons('all', 0, 0, 1);

          // Get the maximum id in the cachedPersonList
          const maxCachedId = (Math.max(...cachedPersonList.map(person => person.id)));

          if (!hasLogged && loadedPersons[0] && loadedPersons[0].id > maxCachedId) {
            setHasLogged(true);
            toast.success(<div onClick={() => { handleSetModalPerson(loadedPersons[0]); toggleModal(0) }}>
              <img className="w-250px" src={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${loadedPersons[0].filename}`} alt="New alert" />
              <p>{loadedPersons[0].person} on {cachedCameraList.find(x => x.id === loadedPersons[0].camera_id)?.name} right now!</p>
            </div>, {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              autoClose: 5000
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }, 45000); // Call every 20 seconds

    // Clean up function
    return () => clearInterval(intervalId); // Clear the interval when the component is unmounted
  }, [cachedPersonList, hasLogged]);


  return (
    <div className="container">
      <div className="d-flex flex-column flex-md-column col-12 p-5 pb-0">
        <div className="d-inline-flex flex-end">
          <PersonsMassDelete
            onPersonsMassDelete={onPersonMassDelete}
            onShowMassDeleteModal={onShowMassDeleteModal}
            checkedPersons={checkedPersons}
          />
          <div className='d-flex flex-column flex-md-row p-1 flex-end'>
            <button className='btn btn-primary' onClick={handlePrev} disabled={pageIndex === 0}>
              <i className="bi bi-chevron-double-left p-0" /></button>
            <button className='btn btn-secondary' disabled >{pageIndex + 1}</button>
            <button className='btn btn-primary' disabled={cachedPersonList.length < 30} onClick={handleNext}>
              <i className="bi bi-chevron-double-right p-0" />
            </button>
          </div>
        </div>
        <div className="row">
          {(!loading) ?
            cachedPersonList &&
            cachedPersonList.map((personItem) => (
              <>
                <FaceRecognitionCard
                  key={personItem.id}
                  personItem={personItem}
                  onPersonDelete={onPersonDelete}
                  onPersonCheck={onPersonCheck}
                  filename={personItem.filename}
                  isNew={true}
                />
              </>
            ))
            :
            (<LoadingPage />)
          }

        </div>
      </div>
      <CommonModal
        size='xl'
        title={personItem!.person}
        show={showPicture}
        showModalCallback={() => toggleModal(0)}
        closeButton={true}
        confirmButton={false}
        bodyClass="flex-column w-100"
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: "",
        }}
        confirmButtonOptions={{
          icon: "bi bi-trash3",
          variant: 'custom',
          class: "bg-danger text-white",
        }}
      >
        <CanvasComponent

          imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem?.filename}`}
          actualHeight={personItem!.picture_height}
          actualWidth={personItem!.picture_width}
          bboxes={[{ x1: personItem!.bbox1, y1: personItem!.bbox2, x2: personItem!.bbox3, y2: personItem!.bbox4 }]}
          canvasHeight={"500px"}
          canvasWidth={"100%"} />
      </CommonModal >
      <ToastContainer />
    </div>
  )
}

export default FaceRecognition